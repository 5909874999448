import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import authService from 'services/authService';
import { returnOnlyNumbers, toCapitalCase } from 'utils/helpers';
import store from '../index';
import { notification } from 'antd';
import apiConfig from 'auth/FetchInterceptor';
import { rdxResetModuleSlice, rdxSetAppConfigurations } from './moduleSlice';
import appService from '../../services/appService';
import { setFirstLoad } from './themeSlice';
import app from 'configs/ConfigIndex';
import dayjs from 'dayjs';

const loginTheme = app?.APPLICATION_LAYER;

export const initialState = {
  loading: false,
  token: localStorage.getItem(AUTH_TOKEN) || null,
  selectedUser: null,
  selectedSubscription: null,
  selectedCompany: null,
  loginTheme,
  availableRoutes: [],
  openFrom: 'refresh',
  jarvislyPaymentModal: {
    bankId: null,
    showModal: false,
    paymentDescription: null,

    discountPixType: 'percentage', // percentage or amount
    discountPix: 0, // discount value type: percentage or amount
    discountBankSlipType: 'percentage', // percentage or amount
    discountBankSlip: 0, // discount value type: percentage or amount
    discountCreditType: 'percentage', // percentage or amount
    discountCredit: 0, // discount value type: percentage or amount
    discountType: 'percentage', // order discount value: percentage or amount
    discount: 0, // order discount value: percentage or amount
    fullPrice: 0,
    totalToPay: 0,
    discountCumulativePaymentMethod: 0, // number of parcs with discount cumulate order with coupon (-1 = no cumulate, 0 = repeat in all parcs or recurrence, X = number of parcs (ex: 3 = three first parcs)
    discountCumulativeCoupon: -1, // number of parcs with discount cumulate order with coupon (-1 = no cumulate, 0 = repeat in all parcs or recurrence, X = number of parcs (ex: 3 = three first parcs)
    repeatDiscountInInstallment: -1, // -1: not repeat, 0: repeat in all parcs or recurrence, 1: first payment, 2: two first payment, 3...
    installment: 1, // number of installments, 0 = recurrence
    renewCycle: 'monthly', // 'monthly' or number of days
    firstPaymentCycleAt: null, // date of the first payment with pro-rata value
    couponDiscountKey: null,

    result: {
      success: false,
    }
  },
  // showJarvislyPaymentModal: false,
};

export const rdxSetUser = createAsyncThunk(
  'auth/getUserByToken',
  async jwtToken => {
    if (!jwtToken) return null;

    try {
      const result = await authService.getAccountByToken(jwtToken);
      const account = result?.data;

      apiConfig.defaults.headers['x-accountid'] = account?._id || null;
      apiConfig.defaults.headers['x-username'] = account?.name || null;
      apiConfig.defaults.headers['x-useremail'] = account?.email || null;

      authService.account = account;

      if (account?.birthdate) account.birthdate = dayjs(account.birthdate);

      document.body.classList.remove('non-fix-scrollbar');
      document.body.classList.add('fix-scrollbar');

      store.dispatch(
        rdxSetSubscription({
          subscriptionId: null,
          selectedUser: account,
        }),
      );

      // #2 hide the full load screen when logged
      // setTimeout(() => store.dispatch(setFirstLoad(true)), 1000);

      return account;
    } catch (err) {
      console.error(err);
    }
  },
);

export const rdxSetSubscription = createAsyncThunk(
  'auth/getSubscriptionById',
  async data => {
    const rdxUser = store.getState().authSlice?.selectedUser;
    const subscriptionId =
      typeof data === 'string'
        ? data
        : data?._id ||
          data?.subscriptionId ||
          localStorage.getItem('subscriptionId') ||
          null;
    const selectedUser = data?.selectedUser || rdxUser;

    try {
      const availableSubscriptions = selectedUser?.subscriptions?.filter(
        x => x?.status === 2,
      );
      const selectedSubscription =
        availableSubscriptions?.find(x => x?._id === subscriptionId) ||
        availableSubscriptions?.[0] ||
        null;

      apiConfig.defaults.headers['x-subscriptionid'] =
        selectedSubscription?._id || null;
      localStorage.setItem('subscriptionId', selectedSubscription?._id);
      store.dispatch(rdxSetAppConfigurations(selectedSubscription));

      store.dispatch(
        rdxSetCompany({
          companyId: null,
          selectedSubscription: selectedSubscription,
        }),
      );

      authService.subscription = selectedSubscription;
      // await appService.getParameters();

      return selectedSubscription;
    } catch (err) {
      console.error(err);
    }
  },
);
//
// export const rdxSetCompany = createAsyncThunk(
//   'auth/getCompanyById',
//   async (data, { rejectWithValue }) => {
//
//     const rdxSubscription = store.getState().authSlice?.selectedSubscription;
//
//     const companyId =
//       typeof data === 'string'
//         ? data
//         : data?._id ||
//           data?.companyId ||
//           localStorage.getItem('companyId') ||
//           null;
//
//     const selectedSubscription = data?.selectedSubscription || rdxSubscription;
//
//     try {
//       const availableCompanies = selectedSubscription?.__companies || [];
//
//       const selectedCompany =
//         availableCompanies?.find(x => x?._id === companyId) ||
//         selectedSubscription?.__mainCompany ||
//         availableCompanies?.[0] ||
//         null;
//
//       apiConfig.defaults.headers['x-companyid'] = selectedCompany?._id || null;
//       localStorage.setItem('companyId', selectedCompany?._id);
//
//       authService.company = selectedCompany;
//
//       return selectedCompany;
//     } catch (err) {
//       console.error(err);
//     }
//   },
// );

export const signIn = createAsyncThunk(
  'auth/signing',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authService.login(data, loginTheme);
      const account = response?.data;

      if (!response?.success || !account?.token) return;

      localStorage.setItem(AUTH_TOKEN, account?.token);
      return account?.token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const signInWithEmail = createAsyncThunk(
  'auth/signingWithEmail',
  async (data, { rejectWithValue }) => {
    try {
      const firebaseDoc = await FirebaseService.signInEmailRequest(data);
      const user = firebaseDoc?.user;

      if (!user) {
        appService.notification(
          'w',
          'authentication_fail',
          'doSignIn',
          'authentication_fail_description',
        );
        localStorage.removeItem(AUTH_TOKEN);
        store.dispatch(signOutSuccess());
      } else {
        store.dispatch(setFirstLoad(false));
        store.dispatch(rdxSetOpenFrom('login'));

        const decoratedUser = decorateFirebaseUser(user, 'firebase', 'email');
        const response = await authService.login(decoratedUser, loginTheme);
        const account = response?.data;

        if (!response?.success || !account?.token) return;

        localStorage.setItem(AUTH_TOKEN, account?.token);
        notification.destroy('doSignIn');

        return account?.token;
      }
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const signUp = createAsyncThunk(
  'auth/register',
  async (data, { rejectWithValue }) => {
    const { email, password, name, mobile, countryCode = '+55' } = data;

    try {
      const firebaseDoc = await FirebaseService.signUpEmailRequest(
        email,
        password,
      );
      const user = firebaseDoc?.user;

      const decoratedUser = decorateFirebaseUser(
        {
          ...user, // has 'uid' property
          name,
          password,
          phoneNumber: `${countryCode}${returnOnlyNumbers(mobile)}`,
          phoneNumberConfirmed: true,
          emailConfirmed: true,
        },
        'firebase',
        'email',
      );

      appService.notification(
        's',
        'congratulations',
        'doSignup',
        'user_created_success',
      );

      const response = await authService.login(decoratedUser, loginTheme);
      const account = response?.data;

      if (!response?.success || !account?.token) return;

      localStorage.setItem(AUTH_TOKEN, account?.token);
      return account?.token;
    } catch (err) {
      appService.notification(
        'w',
        'user_already_exist',
        'doSignup',
        'this_email_already_in_use',
      );
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const signOut = createAsyncThunk('auth/logout', async () => {
  const response = await FirebaseService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  store.dispatch(rdxResetModuleSlice());

  document.body.classList.remove('fix-scrollbar');
  document.body.classList.add('non-fix-scrollbar');

  return response?.data;
});

export const signInWithGoogle = createAsyncThunk(
  'auth/signInWithGoogle',
  async (_, { rejectWithValue }) => {
    try {
      const response = await authService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const signInWithFacebook = createAsyncThunk(
  'auth/signInWithFacebook',
  async (_, { rejectWithValue }) => {
    try {
      const response = await authService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || 'Error');
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    rdxSetShowPaymentModal: (state, action) => {
      // state.showJarvislyPaymentModal = action.payload;
      state.jarvislyPaymentModal = {
        ...state.jarvislyPaymentModal,
        ...action.payload,
      };
    },
    rdxSetOpenFrom: (state, action) => {
      state.openFrom = action.payload; // 'refresh' (refresh page) or 'login' (initial form)
    },

    rdxSetAvailableRoutes: (state, action) => {
      state.availableRoutes = action.payload;
    },

    authenticated: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },

    showAuthMessage: state => {
      state.loading = false;
    },

    hideAuthMessage: state => {
      state.loading = false;
    },

    signOutSuccess: state => {
      state.loading = false;
      state.token = null;
    },

    showLoading: state => {
      state.loading = true;
    },

    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },

    rdxSetCompany: (state, action) => {
      const data = action.payload;

      const rdxSubscription = state.selectedSubscription;

      const companyId =
        typeof data === 'string'
          ? data
          : data?._id ||
            data?.companyId ||
            localStorage.getItem('companyId') ||
            null;

      const selectedSubscription =
        data?.selectedSubscription || rdxSubscription;

      const availableCompanies = selectedSubscription?.__companies || [];

      const selectedCompany =
        availableCompanies?.find(x => x?._id === companyId) ||
        selectedSubscription?.__mainCompany ||
        availableCompanies?.[0] ||
        null;

      localStorage.setItem('companyId', selectedCompany?._id);

      apiConfig.defaults.headers['x-companyid'] = selectedCompany?._id || null;
      apiConfig.defaults.headers['x-bankaccountcode'] =
        selectedCompany?.__mainBank?.accountCode || null;

      state.selectedCompany = selectedCompany;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signIn.pending, state => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(signIn.rejected, state => {
        state.loading = false;
      })
      .addCase(signOut.fulfilled, state => {
        state.loading = false;
        state.token = null;
        state.selectedUser = null;
        state.selectedSubscription = null;
        state.selectedCompany = null;
      })
      .addCase(signOut.rejected, state => {
        state.loading = false;
        state.token = null;
      })
      .addCase(signUp.pending, state => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(signUp.rejected, state => {
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, state => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, state => {
        state.loading = false;
      })

      // signInWithEmail
      .addCase(signInWithEmail.pending, state => {
        state.loading = true;
      })
      .addCase(signInWithEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(signInWithEmail.rejected, state => {
        state.loading = false;
      })

      // signInWithFacebook
      .addCase(signInWithFacebook.pending, state => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, state => {
        state.loading = false;
      })

      // rdxSetUser
      .addCase(rdxSetUser.pending, state => {
        state.loading = true;
      })
      .addCase(rdxSetUser.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedUser = action.payload;
      })
      .addCase(rdxSetUser.rejected, state => {
        state.loading = false;
        state.selectedUser = null;
      })

      // rdxSetSubscription
      .addCase(rdxSetSubscription.pending, state => {
        state.loading = true;
      })
      .addCase(rdxSetSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedSubscription = action.payload;
      })
      .addCase(rdxSetSubscription.rejected, state => {
        state.loading = false;
        state.selectedSubscription = null;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,

  showLoading,
  signInSuccess,
  rdxSetCompany,

  rdxSetAvailableRoutes,
  rdxSetOpenFrom,
  rdxSetShowPaymentModal,
} = authSlice.actions;

export default authSlice.reducer;

// Google OAuth Decorator
function decorateFirebaseUser(user, provider, service) {
  return {
    ...user,
    provider,
    service,
    name: user?.name || toCapitalCase(user.email.split('@')[0]),
    // email: user.email,
    emailConfirmed: user?.emailConfirmed || false,
    // phoneNumber: `${countryCode}${returnOnlyNumbers(mobile)}`,
    // phoneNumber: user?.phoneNumber,
    phoneNumberConfirmed: user?.phoneNumberConfirmed || false,
    // pictureUrl: user?.photoURL,
    // uid: user?.uid,
  };
}
