import React, { useContext, useEffect } from 'react';
import { translateX } from 'utils/helpers';
import { Badge, Card, Col, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import {
  JarvislyFormContext,
  JarvislyInput,
  JarvislyNumber,
  JarvislySelect,
} from 'components/jarvisly-components/others/FormComponents';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import { useSelector } from 'react-redux';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const ProductRecordIndex = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  useTabChild('record', [], props);
  const valueOrPercentageOptions = buildValueOrPercentageOptions();
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------
  const unitsOptions = buildUnitsOptions();
  const brandsOptions = buildBrandsOptions();

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card title={translateX('product')}>
        <Row gutter={ROW_GUTTER}>
          {/* REFERENCE */}
          <Col xs={24} sm={6} xl={4}>
            <JarvislyInput name="reference" />
          </Col>
          {/* REFERENCE */}

          {/* DESCRIPTION */}
          <Col xs={24} sm={18} xl={10}>
            <JarvislyInput name="description" />
          </Col>
          {/* DESCRIPTION */}

          {/* UNIT */}
          <Col xs={24} sm={6} xl={2}>
            <JarvislySelect name="unitId" label="unit" options={unitsOptions} />
          </Col>
          {/* UNIT */}

          {/* BRAND */}
          <Col xs={24} sm={10} xl={4}>
            <JarvislySelect
              name="brandId"
              label="brand"
              options={brandsOptions}
            />
          </Col>
          {/* BRAND */}

          {/* PRICE */}
          <Col xs={24} sm={8} xl={4}>
            <JarvislyNumber
              name="price"
              min={0}
              max={999999999}
              defaultValue={0}
              addonBefore={null}
              decimalSeparator=","
              suffixDefaultValue="R$"
              addonAfter={valueOrPercentageOptions}
              suffixStyle={{ width: 60 }}

              // formatter={value =>
              //   value
              //     ? `${parseFloat(value)
              //       .toFixed(2)
              //       .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`.replace('.', ',')
              //     : '0,00'
              // } // Formata o valor para o formato "1.000,00"
              // parser={value => value.replace(/\.\s?|(,*)/g, '')} // Remove os pontos e vírgulas ao editar
            />
          </Col>
          {/* PRICE */}
        </Row>
      </Card>

      {/*
      <Card title={translateX('alternative_names')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8}>
            <JarvislyInput
              name="budgetDescription"
              label="budget_description"
            />
          </Col>
          <Col xs={24} sm={8}>
            <JarvislyInput
              name="billingDescription"
              label="billing_description"
            />
          </Col>
          <Col xs={24} sm={8}>
            <JarvislyInput
              name="descriptionCustomer"
              label="description_by_customer"
            />
          </Col>
        </Row>
      </Card>
      <Card title={translateX('classification')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8}>
            <JarvislySelect name="familyId" label="family" />
          </Col>
          <Col xs={24} sm={8}>
            <JarvislySelect name="subFamilyId" label="sub_family" />
          </Col>
          <Col xs={24} sm={8}>
            <JarvislySelect name="categoryId" label="category" />
          </Col>
        </Row>
      </Card>
*/}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildValueOrPercentageOptions() {
    return [
      {
        value: 'value',
        text: translateX('real'),
        label: translateX('real_symbol'),
        disabled: false,
      },
    ];
  }

  function buildUnitsOptions() {
    if (!parameters?.productsParameters?.units?.length) {
      return [];
    }

    return parameters?.productsParameters?.units?.map(item => {
      return {
        value: item._id,
        text: item.label,
        label: (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span key={item._id}>
                <Badge
                  dot
                  style={{
                    marginRight: 8,
                    color: item?.foreColor,
                    backgroundColor: item?.backColor,
                    marginTop: 4,
                  }}
                />
                {item.label}
              </span>

              {/*{mode !== 'view' && (*/}
              {/*  <Tooltip title={translateX('edit')}>*/}
              {/*    <Button*/}
              {/*      size="small"*/}
              {/*      className="m-2"*/}
              {/*      icon={<EditOutlined />}*/}
              {/*      onClick={() => onBusinessAreaOrigin(item)} // Passando o registro completo*/}
              {/*    />*/}
              {/*  </Tooltip>*/}
              {/*)}*/}
            </div>
          </>
        ),
        disabled: false,
      };
    });
  }

  function buildBrandsOptions() {
    if (!parameters?.productsParameters?.brands?.length) {
      return [];
    }

    return parameters?.productsParameters?.brands?.map(item => {
      return {
        value: item._id,
        text: item.label,
        label: (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span key={item._id}>
                {/*<Badge*/}
                {/*  dot*/}
                {/*  style={{*/}
                {/*    marginRight: 8,*/}
                {/*    color: item?.foreColor,*/}
                {/*    backgroundColor: item?.backColor,*/}
                {/*    marginTop: 4,*/}
                {/*  }}*/}
                {/*/>*/}
                {item.label}
              </span>

              {/*{mode !== 'view' && (*/}
              {/*  <Tooltip title={translateX('edit')}>*/}
              {/*    <Button*/}
              {/*      size="small"*/}
              {/*      className="m-2"*/}
              {/*      icon={<EditOutlined />}*/}
              {/*      onClick={() => onBusinessAreaOrigin(item)} // Passando o registro completo*/}
              {/*    />*/}
              {/*  </Tooltip>*/}
              {/*)}*/}
            </div>
          </>
        ),
        disabled: false,
      };
    });
  }
};

export default ProductRecordIndex;
