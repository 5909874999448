import {
  AiOutlineBars,
  AiOutlineFileProtect,
  AiOutlineNodeExpand,
} from 'react-icons/ai';
import { BsCashCoin, BsPersonVideo2 } from 'react-icons/bs';
import { FiShoppingBag } from 'react-icons/fi';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import { IoPricetagsOutline } from 'react-icons/io5';

const APP_SINDICO = {
  ID: 'appsindico',
  NAME: 'Sindico ERP',
  THEME_COLOR: 'BLUE',
  THEME: 'light',
  FINTECH_SERVICES: true,
  DOMAINS: ['sistema.sindico.com', 'sistema.sindico.com.br'],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'multiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: true,
  COPY_RIGHT: 'Sindico ERP',
  '//API_DEV_HOST': ['localhost', 'vps', 'heroku'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8003,
  API_PORT_PRODUCTION: 8003,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'vps.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  API_HOST_DEV_HEROKU: 'sindico.herokuapp.com',
  WHATSAPP_INTEGRATED: true,
  TOKEN: process.env.REACT_APP_APPSINDICO_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/appsindico/logo-app.png',
  LOGO_APP_WHITE: '/apps/appsindico/logo-app-white.png',

  LOGO_APP_ICON: '/apps/appsindico/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/appsindico/logo-app-icon-white.png',

  LOGO: '/apps/appsindico/logo.png',
  LOGO_WHITE: '/apps/appsindico/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/appsindico/logo-app-side.png',

  LOGO_SM_WHITE: '/apps/appsindico/logo-sm-white.png',

  TITLE: 'Sindico ERP',
  '//SLOGAN': 'Condomínios Inteligentes',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: true,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: true,
  },
  ENABLE_MARKETPLACE: false,
  MODULES: ['accounts', 'units', 'vehicles'],
  SHOW_ALL_MENUS: !!process.env?.REACT_APP_APPSINDICO_SHOW_ALL_MENUS,
  MENU_NAVIGATION: [
    // ------------------
    // RECORDS
    // ------------------
    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      className: '',
      // icon: EditOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        // entities
        {
          key: 'records_entities',
          path: '/app/records/appsindico-entities',
          title: 'entities',
          icon: BsPersonVideo2,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'records_suppliers',
              path: '/app/records/suppliers',
              title: 'suppliers',
              // icon: GiSoccerKick,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_collaborator',
              path: '/app/records/collaborator',
              title: 'collaborator',
              // icon: GiSoccerKick,
              subscriptionLevel: [0, 1],
            },
          ],
        },
        {
          key: 'records_condominium',
          path: '/app/records/condominium',
          title: 'condominium',
          icon: HiOutlineBuildingOffice2,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'records_condominium_ambiences',
              path: '/app/records/condominium/ambiences',
              title: 'ambiences',
              // icon: GiSoccerKick,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_condominium_units',
              path: '/app/records/condominium/units',
              title: 'units',
              // icon: GiSoccerKick,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_residents',
              path: '/app/records/residents',
              title: 'residents',
              // icon: GiSoccerKick,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_vehicles',
              path: '/app/records/vehicles',
              title: 'vehicles',
              // icon: GiSoccerKick,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_pets',
              path: '/app/records/pets',
              title: 'pets',
              // icon: GiSoccerKick,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_bikes',
              path: '/app/records/bikes',
              title: 'bikes',
              // icon: GiSoccerKick,
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_procedures',
              path: '/app/records/procedures',
              title: 'procedures',
              // icon: GiSoccerKick,
              subscriptionLevel: [0, 1],
            },
          ],
        },
        {
          key: 'records_products',
          path: '/app/records/appsindico-products',
          title: 'products',
          icon: IoPricetagsOutline,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'records_assets',
              path: '/app/records/assets',
              title: 'assets',
              // icon: GiSoccerKick,
              subscriptionLevel: [0, 1],
            },
          ],
        },
      ],
    },
    {
      key: 'operational',
      path: '/app/operational',
      title: 'operational',
      // icon: TeamOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'operational_occurrences_module',
          path: '/app/operational/appsindico-occurrences_module',
          title: 'occurrences_module',
          icon: AiOutlineFileProtect,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'operational_my_chores',
          path: '/app/operational/my_chores',
          title: 'my_chores',
          icon: AiOutlineBars,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'operational_supervision',
          path: '/app/operational/supervision',
          title: 'supervision',
          icon: FiShoppingBag,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'operational_home_moves',
          path: '/app/operational/home_moves',
          title: 'home_moves',
          icon: AiOutlineNodeExpand,
          subscriptionLevel: [0, 1],
        },
      ],
    },
    {
      key: 'financial',
      path: '/app/financial',
      title: 'financial',
      // icon: LineChartOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'financial_payable',
          path: '/app/financial/payable',
          title: 'payments',
          icon: BsCashCoin,
          subscriptionLevel: [0, 1],
        },
      ],
    },
  ],
  FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_APPSINDICO_FIREBASE_CONFIG),
};

export default APP_SINDICO;
