import { Tabs } from 'antd';
import { returnOnlyNumbers, translateWord } from 'utils/helpers';
import React, { useEffect, useRef, useState } from 'react';
import CompaniesRecordIndex from 'modules/app-views/appmulti/operators/Form/Record/RecordIndex';
import { useSearchParams } from 'react-router-dom';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import { JarvislyFormProvider, JarvislyTabProvider } from 'components/jarvisly-components/others/FormComponents';
import authService from 'services/authService';
import entityService from 'services/entityService';
import CompaniesBanksTab from './BanksTab/BanksTab';
import JarvislyBanksAddModal from 'components/jarvisly-components/forms/Banks/modal/JarvislyBanksAddModal';
import JarvislyBanksEditModal from 'components/jarvisly-components/forms/Banks/modal/JarvislyBanksEditModal';
import app from 'configs/ConfigIndex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const AccountOrganizationsForm = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, _id, setModuleFocus, mode } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab];
  if (!app.HIDE_COMPANY_BANKS_TAB) availableTabs.push('banks');

  const bankAddModalRef = useRef(null);
  const bankEditModalRef = useRef(null);

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);
  const [showPixAddModal, setShowPixAddModal] = useState();
  const [showPixEditModal, setShowPixEditModal] = useState();
  const [showBankEditModal, setShowBankEditModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null); // selected bank
  const [pixArr, setPixArr] = useState([])
  const [selectedPix, setSelectedPix] = useState(null)


  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    _id: document?.__mainCompany?._id,
    document: document?.__mainCompany,
    showBankAddModal: bankAddModalRef?.current?.showModal,
    setShowBankAddModal: bankAddModalRef?.current?.setShowModal,
    selectedAddRecord: bankAddModalRef?.current?.selectedRecord,
    setSelectedAddRecord: bankAddModalRef?.current?.setSelectedRecord,

    showBankEditModal,
    setShowBankEditModal,
    selectedEditRecord: bankEditModalRef?.current?.selectedRecord,
    setSelectedEditRecord: bankEditModalRef?.current?.setSelectedRecord,
    pixArr,
    setPixArr,
    selectedPix,
    setSelectedPix,
    showPixAddModal,
    setShowPixAddModal,
    showPixEditModal,
    setShowPixEditModal,
    selectedRecord,
    setSelectedRecord
  };
  const tabItems = buildTabItens();


  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  const onTabClick = value => {
    if (
      !tabItems.find(t => t.key === value) ||
      !availableTabs.includes(value)
    ) {
      value = initTab;
    }
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;
    body.organization = body.name;
    return body;
  };

  const onAfterSave = async ({ doc, body }) => {
    authService.refreshAccount();

    // save entity main company
    const dataSet = {
      // ...doc,
      ...body,
      dataType: 'company',
      dataProfile: 'company',
      uniqueKey: returnOnlyNumbers(doc.cnpj) || returnOnlyNumbers(doc.cpf),
      name: doc.organization,
      profile: 'main_company',
    };

    const headers = { 'x-subscriptionid': doc._id };

    await entityService.upsertEntity(doc?.__mainCompany?._id, dataSet, headers);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* BANKS ADD MODAL WRAPPER */}
      <JarvislyBanksAddModal {...childProps} ref={bankAddModalRef} />
      {/* BANKS ADD MODAL WRAPPER */}

      {/* BANKS EDIT MODAL WRAPPER */}
      <JarvislyBanksEditModal {...childProps} ref={bankEditModalRef} />
      {/* BANKS EDIT MODAL WRAPPER */}

      <JarvislyFormProvider
        name="company-form"
        document={document}
        autoFocus="name"
        requiredFields={[
          'name',
          'companyType',
          'cnpj',
          'fullName',
          'phone',
          'email',
          'zip',
          'number',
        ]}
        showSubmitButton={[initTab].includes(tab)}
        onSave={onSave}
        onAfterSave={onAfterSave}
        setTab={setTab}
        // onValuesChange={onValuesChange}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateWord('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <CompaniesRecordIndex {...props} showCompanyType activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
      // {
      //   label: translateWord('contacts'),
      //   key: 'contacts',
      //   hide: false,
      //   disabled: mode === 'add',
      //   children: (
      //     <JarvislyTabProvider tab="parameters">
      //       <CompaniesContactsIndex {...props} activeTab={tab} />
      //     </JarvislyTabProvider>
      //   ),
      // },
      {
        label: translateWord('banks'),
        key: 'banks',
        hide: app.HIDE_COMPANY_BANKS_TAB,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="banks">
            <CompaniesBanksTab {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
};

export default AccountOrganizationsForm;
