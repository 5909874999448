import { translateX } from 'utils/helpers';
import { Card, List, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosService from 'services/axiosService';
import { JarvislyFormContext } from 'components/jarvisly-components/others/FormComponents';

const ProductsParametersRequiredFields = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------'
  const {
    requiredFields,
    setRequiredFields,
    setIsUpdating,
    showParametersModal,
    moduleName = 'products',
  } = props;

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const moduleParameters = parameters?.[`${moduleName}Parameters`] || {};
  const initialState = buildInitialState();

  // component states ----------------------------------------------------------
  const [listRequiredFields, setListRequiredFields] = useState(initialState);
  const [parameterId, setParameterId] = useState(moduleParameters?._id);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (showParametersModal && parameterId) {
      form.setFieldsValue(moduleParameters);
      // } else {
      //   setIsUpdating && setIsUpdating(true);
    }
  }, [showParametersModal, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onChangeRequiredField = async (item, checked) => {
    const idx = listRequiredFields.findIndex(
      i => i.fieldName === item.fieldName,
    );
    listRequiredFields[idx].checked = checked;
    setListRequiredFields([...listRequiredFields]);

    const updatedRequiredFields = checked
      ? [...requiredFields, item.fieldName]
      : requiredFields.filter(f => f !== item.fieldName);

    setRequiredFields(updatedRequiredFields);
    setIsUpdating(true);
    await $save(requiredFields, item, checked);

    async function $save(requiredFields, item, checked) {
      setTimeout(async () => {
        if (!parameterId) {
          const axiosOptions = {
            url: `v1/${moduleName}-parameters`,
            data: { entityRequiredFields: requiredFields },
          };
          const result = await axiosService.post(axiosOptions);
          setParameterId(result[0]._id);
        } else {
          if (checked) {
            const url = `v1/${moduleName}-parameters/add-to-set/${parameterId}/$push/requiredFields`;
            await axiosService.put({
              url,
              data: item.fieldName,
            });
          } else {
            const url = `v1/${moduleName}-parameters/add-to-set/${parameterId}/$pull/requiredFields`;
            await axiosService.put({
              url,
              data: { _id: item.fieldName },
            });
          }
        }

        setIsUpdating(false);
      }, 400);
    }
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Card
        title={
          <div className="text-muted">{translateX('required_fields')}</div>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={listRequiredFields}
          renderItem={item => (
            <List.Item key={item.fieldName}>
              <div>
                <h4 className="mb-0">{item.label}</h4>
                {/*<div>{item.description}</div>*/}
              </div>

              <div>
                <Switch
                  checked={item.checked}
                  disabled={item.disabled}
                  onChange={checked => onChangeRequiredField(item, checked)}
                />
              </div>
            </List.Item>
          )}
        />
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildInitialState() {
    const erf = moduleParameters?.requiredFields;

    return [
      {
        fieldName: 'reference',
        label: translateX('reference'),
        checked: true,
        disabled: true,
      },
      {
        fieldName: 'description',
        label: translateX('description'),
        checked: true,
        disabled: true,
      },
      {
        fieldName: 'unitId',
        label: translateX('unit'),
        checked: true,
        disabled: true,
        // description: translateX('name_required_description'),
        // checked: erf?.includes('fullName'),
        // disabled: false,
      },
      {
        fieldName: 'brandId',
        label: translateX('brand'),
        // description: translateX('name_required_description'),
        checked: erf?.includes('brandId'),
        disabled: false,
      },
      {
        fieldName: 'price',
        label: translateX('price'),
        checked: true,
        disabled: true,
      },
    ];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default ProductsParametersRequiredFields;
