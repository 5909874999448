import { Tabs } from 'antd';
import { translateX } from 'utils/helpers';
import React from 'react';
import useTab from 'components/jarvisly-components/hooks/useTabParentHook';
import DataSearchConsultationTab from './Consultation/DataSearchConsultationTab';

const DataSearchList = props => {
  // props deconstruction ------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const initTab = 'consultation';
  const availableTabs = [initTab, 'historic'];
  const [tab, setTab] = useTab(availableTabs, 'search', initTab);
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------

  // local variables II --------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Tabs
        type="line"
        tabPosition="top"
        activeKey={tab}
        rootClassName="tab-in-form"
        items={tabItems.filter(x => !x.hide)}
        onTabClick={onTabClick}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItens() {
    return [
      {
        label: translateX('consultation'),
        key: initTab,
        hide: false,
        children: <DataSearchConsultationTab {...props} activeTab={tab} />,
      },

      {
        label: translateX('historic'),
        key: 'historic',
        hide: false,
        children: <div>HISTÓRICO</div>,
      },
    ];
  }
};

export default DataSearchList;
