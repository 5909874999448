import React from 'react';
import { translateX } from 'utils/helpers';
import { Card, Col, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { JarvislyInput } from 'components/jarvisly-components/others/FormComponents';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const ProductCostIndex = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  useTabChild('cost', [], props);

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card title={translateX('provider')}>
        <Row gutter={ROW_GUTTER}>
          {/* PROVIDER */}
          <Col xs={24}>
            <JarvislyInput name="provider" />
          </Col>
          {/* PROVIDER */}
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default ProductCostIndex;
