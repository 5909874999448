import React from 'react';
import { Tooltip } from 'antd';
import { translateX } from 'utils/helpers';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
export const ProductsFormHeaderTitle = props => {
  const { document } = props;

  return (
    <Tooltip title={translateX('billing_code')}>
      <span className="text-uppercase">
        <span>{document?.reference} </span>
        <span>{document?.description}</span>
        {/*{document?._metadata?.__documentCode}*/}
        {/*
        <span>
          {translateX('product')} Nº {document?._metadata?.counter}
        </span>
        */}
      </span>
    </Tooltip>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
// export const ProductsFormHeaderSubtitle = props => {
//   const { document } = props;

//   return (
//     <Tooltip title={translateX('customer')}>
//       <span>{document?.customer?.name}</span>
//     </Tooltip>
//   );
// };

// COMPONENT *******************************************************************
// *****************************************************************************
// export const ProductsFormHeaderTags = props => {
//   return <ProductsStatusTag {...props} />;
// };

// COMPONENT *******************************************************************
// *****************************************************************************
// export const ProductsFormHeaderColumn1 = props => {
//   const { document } = props;

//   return (
//     <Col xs={24} sm={8}>
//       <Row style={{ display: document?.reference ? 'initial' : 'none' }}>
//         <Col>
//           {document?.reference && (
//             <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
//               <Tooltip placement="right" title={translateX('reference')}>
//                 <Flex
//                   flexDirection="row"
//                   justifyContent="start"
//                   alignItems="center"
//                   className="text-truncate"
//                 >
//                   <Icon
//                     type={AiOutlineNumber}
//                     className="text-primary font-size-md"
//                     style={{ flexShrink: 0 }}
//                   />
//                   <div
//                     className="ml-2 font-weight-semibold"
//                     style={{ flex: 1 }}
//                   >
//                     {document?.reference}
//                   </div>
//                 </Flex>
//               </Tooltip>
//             </Row>
//           )}
//         </Col>
//       </Row>
//       <Row style={{ display: document?.description ? 'initial' : 'none' }}>
//         <Col>
//           {document?.description && (
//             <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
//               <Tooltip placement="right" title={translateX('description')}>
//                 <Flex
//                   flexDirection="row"
//                   justifyContent="start"
//                   alignItems="center"
//                   className="text-truncate"
//                 >
//                   <Icon
//                     type={LiaFileSignatureSolid}
//                     className="text-primary font-size-md"
//                     style={{ flexShrink: 0 }}
//                   />
//                   <div
//                     className="ml-2 font-weight-semibold"
//                     style={{ flex: 1 }}
//                   >
//                     {document?.description}
//                   </div>
//                 </Flex>
//               </Tooltip>
//             </Row>
//           )}
//         </Col>
//       </Row>
//     </Col>
//   );
// };
