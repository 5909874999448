import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import { Col, Row } from 'antd';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  AppstoreAddOutlined,
  DatabaseOutlined,
  DollarOutlined,
  InfoOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import DataDisplayWidget from 'components/shared-components/DataDisplayWidget';
import { translateX } from 'utils/helpers';
import JarvislyInfoModal from './JarvisInfoModal';
import JarvislyAddModal from './JarvisAddModal';

// COMPONENT *******************************************************************
// *****************************************************************************
const ResourcesTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  useTabChild('team', null, props);
  const { selectedSubscription } = useSelector(s => s?.authSlice);

  // local variables I --------------------------------------------------------
  const items = buildJarvisMenu();
  const jarvislyInfoModalRef = useRef(null);
  const jarvislyAddModalRef = useRef(null);

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // local variables II --------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onJarvisMenuClick = menu => {
    switch (menu.key) {
      case 'add_credit':
        jarvislyAddModalRef?.current?.setShowModal(true);
        break;

      case 'info':
        jarvislyInfoModalRef?.current?.setShowModal(true);
        break;

      default:
        console.log('no menu configured');
    }
  };

  // local variables III -------------------------------------------------------
  const childProps = {
    ...props,
    selectedSubscription,
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Row gutter={16}>
      {/* JARVISLY ADD MODAL WRAPPER */}
      <JarvislyAddModal {...childProps} ref={jarvislyAddModalRef} />
      {/* JARVISLY ADD MODAL WRAPPER */}

      {/* JARVISLY INFO MODAL WRAPPER */}
      <JarvislyInfoModal {...childProps} ref={jarvislyInfoModalRef} />
      {/* JARVISLY INFO MODAL WRAPPER */}

      {/* STORAGE */}
      <Col xs={24} sm={6}>
        <DataDisplayWidget
          icon={<DatabaseOutlined />}
          value="100%"
          title={translateX('disc_space', ['%GB%'], ['1GB'])}
          color="orange"
          vertical={true}
          avatarSize={55}
        />
      </Col>
      {/* STORAGE */}

      {/* USERS */}
      <Col xs={24} sm={6}>
        <DataDisplayWidget
          icon={<TeamOutlined />}
          value={selectedSubscription?.team?.length - 1}
          title={translateX('users')}
          color="volcano"
          vertical={true}
          avatarSize={55}
        />
      </Col>
      {/* USERS */}

      {/* MODULES */}
      <Col xs={24} sm={6}>
        <DataDisplayWidget
          icon={<AppstoreAddOutlined />}
          value={selectedSubscription?.contractedModules?.length || 0}
          title={translateX('contracted_modules')}
          color="blue"
          vertical={true}
          avatarSize={55}
        />
      </Col>
      {/* MODULES */}

      {/* JARVIS */}
      <Col xs={24} sm={6}>
        <DataDisplayWidget
          icon={<DollarOutlined />}
          value={selectedSubscription?.creditsJarvis?.balance}
          title={translateX('jarvis_credits')}
          color="green"
          vertical={true}
          avatarSize={55}
          menu={items}
          onMenuClick={onJarvisMenuClick}
        />
      </Col>
      {/* JARVIS */}
    </Row>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildJarvisMenu() {
    return [
      {
        key: 'add_credit',
        label: translateX('add_credit'),
        icon: <ShoppingCartOutlined className="font-size-md" />,
      },
      {
        type: 'divider',
      },
      {
        key: 'info',
        label: translateX('info'),
        icon: <InfoOutlined className="font-size-md" />,
      },
    ];
  }
};

export default ResourcesTab;
