import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row, Tag, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import {
  ModuleContext,
  useModuleContext,
} from 'components/jarvisly-module/ModuleContext';
import appService from 'services/appService';
import { useSelector } from 'react-redux';
import app from 'configs/ConfigIndex';
import TileItem from 'components/jarvisly-components/others/TileItem';
import apiConfig from 'auth/FetchInterceptor';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import Loading from '../../../../../components/jarvisly-components/others-old/Loading';
import Meta from 'antd/es/card/Meta';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
let tabName = 'organizations';

// COMPONENT *******************************************************************
// *****************************************************************************
const FunnelIndex = props => {
  // providers context ---------------------------------------------------------
  const { isWorking } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const { activeTab } = props;

  // local variables -----------------------------------------------------------
  useTabChild('organizations', null, props);

  const { selectedModule } = useSelector(s => s.moduleSlice);
  const { selectedUser } = useSelector(s => s.authSlice);
  const navigate = useNavigate();
  const location = useLocation();
  const { refreshList, dataList } = useModuleContext();

  console.log('dataList =>', dataList);

  // component states ----------------------------------------------------------
  const [list, setList] = useState(dataList);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setList(dataList);
  }, [dataList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    appService.initRoute({
      moduleType: 'list',
      showPageHeader: false,
      showBreadcrumb: false,
      disableSubscriptionCombo: false,
    });

    if (selectedUser?._id) {
      apiConfig.defaults.headers['x-accountid'] = selectedUser._id;
      apiConfig.defaults.headers['x-username'] = selectedUser?.name || null;
      apiConfig.defaults.headers['x-useremail'] = selectedUser?.email || null;
    }

    if (selectedModule?.url === location?.pathname && activeTab === tabName) {
      (async () => await refreshList({ tab: tabName }, tabName))();
      if (location?.state?.qsObj) delete location.state.qsObj;
    }
  }, [selectedModule?.url, activeTab, selectedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onClickNewOrganization = () => {
    navigate(`/app/account/settings/organization/add`);
  };

  const onClickEditFunnel = funnel => {
    console.log('edit the funnel', funnel);
  };

  const onClickAddFunnel = () => {
    console.log('add a funnel');
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Suspense
      fallback={
        <Row justify="center">
          <Col>
            <h1>Fallback Error!</h1>
          </Col>
        </Row>
      }
    >
      <Loading
        isFetching={isWorking}
        data={list}
        marginTop={-200}
        // fullscreen={fullscreen}
        // ignoreNoData={ignoreNoData}
      />

      <div className={`my-4 container-fluid`}>
        <Row gutter={ROW_GUTTER}>
          {/*{list?.map(record => (*/}
          {/*  <Col xs={24} md={12} xl={8} key={record._id}>*/}
          {/*    <TileItem record={record} hoverable strongBorder />*/}
          {/*  </Col>*/}
          {/*))}*/}

          {list?.map(i => (
            <div className="agenda-settings-container" key={i._id}>
              <Row>
                <h3>{translateX(i.title)}</h3>
              </Row>

              <Row>
                {i.funnels?.map(f => (
                  <div key={f._id}>
                    <Tooltip placement="top" title={translateX('edit_funnel')}>
                      <Card
                        className="agenda-settings-card card-clickable-effect"
                        onClick={() => onClickEditFunnel(f)}
                      >
                        <Row justify="space-between">
                          <Meta
                            title={`${f.title}`}
                            description={`${10} ${translateX('seats')}`}
                          />
                          <span className="font-size-sm text-muted">HH:mm</span>
                        </Row>

                        <div>
                          {f.tags.map(tag => (
                            <Tag
                              key={tag}
                              // color={getTypeTagColor(tag)}
                              className="tag text-uppercase"
                            >
                              {translateX(tag)}
                            </Tag>
                          ))}
                        </div>
                      </Card>
                    </Tooltip>
                  </div>
                ))}

                <Tooltip placement="top" title={translateX('new_funnel')}>
                  <Button
                    type="default"
                    size="large"
                    onClick={() => onClickAddFunnel(i)}
                    className="agenda-settings-card card-clickable-effect add-agenda"
                  >
                    +
                  </Button>
                </Tooltip>
              </Row>
            </div>
          ))}
        </Row>
      </div>
    </Suspense>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT **********************************************************************
// *****************************************************************************

export default FunnelIndex;
