import { message, Modal, notification } from 'antd';
import { divider, onProduction, sort, translateX } from '../utils/helpers';
import axiosService from './axiosService';
import dayjs from 'dayjs';
import store from '../store';
import {
  rdxSetDisableSubscriptionCombo,
  rdxSetModuleType,
  rdxSetShowBreadcrumb,
  rdxSetShowPageHeader,
} from '../store/slices/moduleSlice';
import app from '../configs/ConfigIndex';
import utils from '../utils';
import breakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { storage } from 'auth/FirebaseAuth';
import { deleteObject, ref } from 'firebase/storage';
import timezones from 'assets/data/timezones.json';

const TOOLS_API_URL = '/v1/tool';
const appService = {};

appService.getGpsByAddress = async address => {
  const url = `${TOOLS_API_URL}/get-gps-by-address/${encodeURIComponent(
    address,
  )}`;
  const [doc] = await axiosService.get({ url });
  return doc?.[0] && doc?.[1] ? doc : null; // null (coordinates not exist according address and number
};

appService.isMobile = (grid = 'md') =>
  !utils.getBreakPoint(breakpoint()).includes(grid);

appService.getQuote = async () => {
  const url = `${TOOLS_API_URL}/get-quote`;
  const [doc] = await axiosService.get({ url, returnAsObject: true });

  return doc;
};

appService.modal = (flag, title, options) => {
  const fnNotImplemented = () =>
    appService.console('e', 'Function not implemented!');

  const {
    Content,
    okText = 'ok',
    cancelText = 'cancel',
    CustomButtons,
    onOk = fnNotImplemented,
    onCancel = fnNotImplemented,
  } = options;

  const icon = typeof flag !== 'string' && flag !== null ? flag : null;

  const content =
    typeof flag !== 'string' && flag !== null ? Content : translateX(Content);

  const method = icon ? 'info' : $method();

  const opt = {
    title: translateX(title),
    content,
    okText: translateX(okText),
    cancelText: translateX(cancelText),
    onOk,
    onCancel,
    footer: (_, { OkBtn, CancelBtn }) => (
      <>
        {CustomButtons}
        <CancelBtn />
        <OkBtn />
      </>
    ),
  };

  if (icon) opt.icon = flag;

  Modal[method](opt);

  // level detect internal function
  function $method() {
    if (!flag) return 'open';
    if (['start', 'i'].includes(flag)) return 'info';
    if (['s', 'ok'].includes(flag)) return 'success';
    if (['e', 'no'].includes(flag)) return 'error';
    if (['w'].includes(flag)) return 'warning';
    if (['q', 'c'].includes(flag)) return 'confirm'; // q:question, c:confirm
  }
};

appService.notification = (flag, title, key, description, duration) => {
  const icon = typeof flag !== 'string' && flag !== null ? flag : null;
  const method = icon ? 'info' : $method();
  const options = {
    message: translateX(title),
    key,
    description: translateX(description),
    duration,
  };

  if (icon) options.icon = flag;

  notification[method](options);

  // level detect internal function
  function $method() {
    if (!flag) return 'open';
    if (['start', 'i'].includes(flag)) return 'info'; // "info";
    if (['s', 'ok'].includes(flag)) return 'success';
    if (['e', 'no'].includes(flag)) return 'error';
    if (['w'].includes(flag)) return 'warning';
  }
};

appService.notificationDestroy = key => notification.destroy(key);

appService.message = (flag, text, key, duration) => {
  if (!flag)
    return console.error("'flag' is missing in 'appService.message()'");

  // eslint-disable-next-line default-case
  switch (flag.toLowerCase()) {
    case 's':
      flag = 'success';
      break;

    case 'e':
    case 'f':
      flag = 'error';
      break;

    case 'w':
      flag = 'warning';
      break;

    case 'i':
      flag = 'info';
      break;

    case 'l':
      flag = 'loading';
      break;
  }

  if (!['success', 'error', 'warning', 'info', 'loading'].includes(flag)) {
    console.error(`unknowing type '${flag}' in msg() helper function!`);
    return;
  }

  message[flag]({
    duration: duration || 2,
    key: key,
    content: translateX(text),
    className: 'message-info',
  }).then(r => r);
};

appService.messageDestroy = key => message.destroy(key);

/*
appService.getParameters = async () => {
  const url = `${API_PARAMETERS}/get-by-application/${app.ID}`;

  try {
    const [docs] = await axiosService.get({
      method: 'get',
      url,
      returnAsObject: true,
    });

    const doc = clone(docs);

    store.dispatch(rdxSetParameters(doc || null));
  } catch (error) {
    throw error;
  }
};
*/

appService.console = (
  flag,
  message,
  details,
  emoji,
  dividerTopChar,
  dividerBottomChar,
) => {
  if (onProduction()) return;

  const icon = $icon();
  const method = $method();
  const color = $color();

  let text = icon ? `${icon}${message ? ' ' : ''}${message}` : message;

  if (dividerTopChar && typeof dividerBottomChar === 'undefined') {
    dividerBottomChar = dividerTopChar;
  }

  // show log
  if (dividerTopChar) console[method](color, divider(dividerTopChar));
  if (message) {
    console[method](color, `${dayjs().format('HH:mm:ss.SSS')} ${text}`);
    // console[logLevel](color, text);
    // console[logLevel](color, dayjs().format("HH:mm:ss.SSS"), text );
    // console[logLevel](color, text);
  }
  if (details) {
    console[method](details);
    // console[method](color, details);
    // console[method](color, dayjs().format("HH:mm:ss.SSS"), details);
    // console[logLevel](color, "👉", dayjs().format("HH:mm:ss.SSS"), details);
    // console[logLevel](details);
  }
  // if (details) console[logLevel](details);
  if (dividerBottomChar) console[method](color, divider(dividerBottomChar));

  // color by level
  function $color() {
    const red = '\x1b[31m%s\x1b[0m'; // Vermelho
    const green = '\x1b[32m%s\x1b[0m'; // Verde
    const yellow = '\x1b[33m%s\x1b[0m'; // Amarelo
    const blue = '\x1b[34m%s\x1b[0m'; // Azul
    const cyan = '\x1b[36m%s\x1b[0m'; // Ciano
    // const magenta = '\x1b[35m%s\x1b[0m' // Magenta
    // const white = '\x1b[37m%s\x1b[0m' // Branco

    if (!flag || ['start', 'i'].includes(flag)) return blue; // "info";
    if (['s', 'ok'].includes(flag)) return green;
    if (['e', 'no'].includes(flag)) return red;
    if (['w'].includes(flag)) return yellow;
    if (['t'].includes(flag)) return cyan;
  }

  // level detect internal function
  function $method() {
    if (!flag || ['start', 'i'].includes(flag)) return 'log'; // "info";
    if (['s', 'ok'].includes(flag)) return 'log';
    if (['e', 'no'].includes(flag)) return 'error';
    if (['w'].includes(flag)) return 'warn';
    if (['t'].includes(flag)) return 'trace';
  }

  // icon build internal function
  function $icon() {
    if (emoji) return emoji;

    switch (flag) {
      case 'start':
        return '🚀';

      case 's':
        return '✅';

      case 'e':
        return '❌';

      case 'ok':
        return '👍';

      case 'no':
        return '👎';

      case 'w':
        return '⚠️';

      case 'i':
        return '️🕑';

      case 'debug':
      default:
        return '';
    }
  }
};

appService.log = (text, show) => {
  if (show) console.log(text);
};

appService.getLogo = (isDarkTheme = false, logoType = null, small = false) => {
  const logoAppIcon = app.LOGO_APP_ICON; // logotipo menu fechado do sistema
  const logoAppIconWhite = app.LOGO_APP_ICON_WHITE; //logotipo menu fechado do sistema para dark-screen

  const logoApp = app.LOGO_APP; // logotipo menu aberto do sistema
  const logoAppWhite = app.LOGO_APP_WHITE; // logotipo padrãologotipo menu aberto do sistema para dark-screen

  const logoLoginSide = app.LOGO_LOGIN_SIDE; // logotipo lateral na tela de login

  const logo = app.LOGO; // logotipo padrão usado em emails, cabeçalhos, etc.
  const logoWhite = app.LOGO_WHITE; // logotipo padrão para dark-screen

  if (isDarkTheme) {
    if (logoType === 'menu') {
      return small ? logoAppIconWhite : logoAppWhite;
    } else if (logoType === 'login-side') {
      return logoLoginSide;
    } else {
      return logoWhite;
    }
  } else {
    if (logoType === 'menu') {
      return small ? logoAppIcon : logoApp;
    } else if (logoType === 'login-side') {
      return logoLoginSide;
    } else {
      return logo;
    }
  }
};

appService.initRoute = options => {
  store.dispatch(rdxSetModuleType(options.moduleType));
  store.dispatch(rdxSetShowPageHeader(options.showPageHeader));
  store.dispatch(rdxSetShowBreadcrumb(options.showBreadcrumb));
  store.dispatch(
    rdxSetDisableSubscriptionCombo(options.disableSubscriptionCombo),
  );
};

appService.buildAttachmentIds = async (attachmentsArray, document) => {
  if (!attachmentsArray || attachmentsArray?.length === 0)
    return document?.attachmentIds;

  const attachments = Array.isArray(attachmentsArray)
    ? attachmentsArray
    : [attachmentsArray];

  return await appService.persistAttachments(
    attachments,
    document?.attachmentIds,
  );
};
appService.persistAttachments = async (attachments, attachmentIds = []) => {
  for (const a of attachments) {
    if (a.action === 'add') {
      const axiosOptions = {
        url: '/v1/attachments',
        _id: a._id,
        data: { persisted: true },
      };
      await axiosService.put(axiosOptions);

      attachmentIds.push(a._id);
    } else if (a.action === 'remove') {
      const axiosOptions = {
        url: '/v1/attachments',
        _id: a._id,
        headers: { 'x-forcepermanentlydeletation': true },
      };
      await axiosService.delete(axiosOptions);

      const storageRef = ref(storage, a?.uri);

      try {
        await deleteObject(storageRef);
      } catch (e) {}

      attachmentIds = attachmentIds.filter(attachId => attachId !== a._id);
    }
  }

  return attachmentIds;
};

appService.parseAttachmentsState = (attachments, attachmentIds = []) => {
  const toRemove = attachments
    ?.filter(a => a?.action === 'remove')
    ?.map(a => a._id);
  const toAdd = attachments
    ?.filter(a => a?.action !== 'remove')
    ?.map(a => a._id);
  const filteredAttachments = attachmentIds?.filter(a => !toRemove.includes(a));

  return [...new Set([...filteredAttachments, ...toAdd])];
};

appService.getAttachmentUrlByTag = (tag, attachments) => {
  const found = attachments?.find(
    x => x?.tags?.includes(tag) && x?.action === 'add',
  );

  if (found) {
    return found.url;
  } else {
    const found = attachments?.find(
      x => x?.tags?.includes(tag) && x?.action === 'remove',
    );
    if (found) return '';
  }

  return false;
};

appService.copyToClipboard = (url = window.location.href, copyType = 'url') => {
  if (!navigator.clipboard) {
    appService.notification(
      'w',
      'incompatible_browser',
      'copyUrl',
      copyType === 'url' ? 'url_copy_not_supported' : 'link_copy_not_supported',
      10,
    );
    return;
  }

  navigator.clipboard
    .writeText(url)
    .then(() => {
      appService.message(
        's',
        copyType === 'url' ? 'url_copied' : 'link_copied',
      );
    })
    .catch(() => {
      appService.message(
        'f',
        copyType === 'url' ? 'url_not_copied' : 'link_not_copied',
      );
    });
};

appService.getWebConfig = async () => {
  const axiosOptions = {
    url: '/v1/webconfig/get-list',
    returnAsObject: true,
  };
  const [doc] = await axiosService.get(axiosOptions);
  return doc;
};

appService.timeZones = timezones.sort((a, b) => sort(a, b, 'offset'));

appService.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
appService.locationTimezone =
  timezones.find(tz => tz.utc.includes(appService.timeZone)) || {};

export default appService;
