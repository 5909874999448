import React from 'react';
import { Avatar, Button, Card, Dropdown, Flex, Spin } from 'antd';
import CustomStatistic from '../CustomStatistic';
import { MoreOutlined } from '@ant-design/icons';

const DataDisplayWidget = props => {
  const {
    size,
    value,
    title,
    icon,
    color,
    avatarSize = 50,
    vertical = false,
    loading = false,
    menu = null,
    onMenuClick = null,
  } = props;

  const customStatisticProps = { size, value, title };

  return (
    <Card>
      {menu ? (
        <Flex
          justify="right"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '10px', // Adiciona algum padding para manter o espaçamento
          }}
        >
          <Dropdown
            menu={{ items: menu, onClick: onMenuClick }}
            placement="bottomRight"
          >
            <Button type="link">
              <MoreOutlined className="font-size-md" />
            </Button>
          </Dropdown>
        </Flex>
      ) : null}

      <Flex justify="center" align="center">
        <Spin spinning={loading}>
          <Flex justify="center" align="center" vertical={vertical}>
            <Avatar
              size={avatarSize}
              shape="square"
              icon={icon}
              className={`mt-2 ant-avatar-${color}`}
            />
            <div className={vertical ? 'mt-3 text-center' : 'ml-3'}>
              <CustomStatistic {...customStatisticProps} />
            </div>
          </Flex>
        </Spin>
      </Flex>
    </Card>
  );
};

/*


const items = [
  {
    key: '1',
    label: 'Editar',
    icon: <EditOutlined />,
  },
  {
    key: '2',
    label: 'Configurações',
    icon: <SettingOutlined />,
  },
  {
    key: '3',
    label: 'Excluir',
    icon: <DeleteOutlined />,
  }
];
*/

/*return (
  <Card>
    <Flex justify="right">
      <Dropdown menu={{ items }} placement="bottomRight">
        <Button>MENU</Button> {/!* Botão que abre o menu suspenso *!/}
      </Dropdown>
    </Flex>

    <Flex justify="center" align="center">
      <Spin spinning={loading}>
        <Flex justify="center" align="center" vertical={vertical}>
          <Avatar
            size={avatarSize}
            shape="square"
            icon={icon}
            className={`mt-2 ant-avatar-${color}`}
          />
          <div className={vertical ? 'mt-3 text-center' : 'ml-3'}>
            <CustomStatistic {...customStatisticProps} />
          </div>
        </Flex>
      </Spin>
    </Flex>
  </Card>
);*/

export default DataDisplayWidget;
