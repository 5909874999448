import { Tabs } from 'antd';
import { translateWord } from 'utils/helpers';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import MultiPlansRecordIndex from './Record/RecordIndex';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import JarvislyDocumentStatusModal from 'components/jarvisly-components/forms/modals/JarvislyDocumentStatusModal';
import MultiPlansCoveragesIndex from './Coverages/CoveragesIndex';
import appmultiService from 'services/appmultiService';
import MultiPlansParametersIndex from './Parameters/ParametersIndex';
import appService from 'services/appService';
import PricesTableModal from './Record/PricesTableModal';

// COMPONENT *******************************************************************
// *****************************************************************************

const MultiPlansForm = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, _id, setModuleFocus, mode } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'coverages', 'parameters'];
  const documentStatusModalRef = useRef(null);
  const pricesTableModalRef = useRef(null);
  const recordsTabRef = useRef(null);
  const coveragesTabRef = useRef(null);
  const initialCoverageOptions = appmultiService.getCoverages();

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedPlanoId, setSelectedPlanoId] = useState('');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedOperator,
    setSelectedOperator,
    selectedPlanoId,
    setSelectedPlanoId,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    documentStatusModalRef,
    pricesTableModalRef,
  }));

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    // decorate plan
    body.priceType = body.pricePrefix;
    body.currency = body?.priceSuffix || 'brl';
    body.operatorId = body.selectedOperator?.key;

    if (body?.__destinations) {
      body.destinations = [];
      Object.keys(body.__destinations).map(key => {
        if (body.__destinations?.[key]) body.destinations.push(key);
        return key;
      });
    }

    if (body?.__travelProfiles) {
      body.travelProfiles = [];
      Object.keys(body.__travelProfiles).map(key => {
        if (body.__travelProfiles?.[key]) body.travelProfiles.push(key);
        return key;
      });
    }

    // COVERAGES
    const selectedCurrency = coveragesTabRef?.current?.selectedCurrency;

    if (selectedCurrency && body?.coverages) {
      body.coverages = body.coverages?.map((c, idx) => {
        c.type = getType(c.coverageKey);
        c.highlight = getHighlight(c.coverageKey);

        if (getType(c.coverageKey) === 'money') {
          c.currency = selectedCurrency[idx];
          c.included = false;
          c.text = null;
        } else if (getType(c.coverageKey) === 'switch') {
          c.currency = null;
          c.amount = 0;
          c.text = null;
        } else if (getType(c.coverageKey) === 'text') {
          c.currency = null;
          c.included = false;
          c.amount = 0;
        }

        return c;
      });
    }

    // ATTACHMENTS
    const attachments = recordsTabRef?.current?.attachments || [];
    const showInWebsite = recordsTabRef?.current?.showInWebsite || false;

    body.attachmentIds = appService.parseAttachmentsState(attachments);

    // discovery coveragesFileUrl
    let attach = appService.getAttachmentUrlByTag('coverages', attachments);
    if (attach !== false) body.coveragesFileUrl = attach;

    body.status = document?.status;
    body.showInWebsite = showInWebsite;

    if (body?.priceType === 'table') {
      body.price = null;
      body.currency = null;
    }

    // parameters
    if (selectedPlanoId) {
      const plans = selectedOperator?._integrations?.operatorPlans || [];
      const apiPlan = plans.find(p => p?.planoId === selectedPlanoId);

      if (apiPlan) {
        body._integrations.planoId = selectedPlanoId;
        body._integrations.urlCobertura = apiPlan?.urlCobertura;
      } else {
        setSelectedPlanoId(null);
        body._integrations.planoId = null;
        body._integrations.urlCobertura = null;
      }
    }

    return body;
  };

  const onAfterSave = async () => {
    const attachments = recordsTabRef?.current?.attachments || [];
    await appService.persistAttachments(attachments);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* TABLE PRICES MODAL WRAPPER */}
      <PricesTableModal {...childProps} ref={pricesTableModalRef} />
      {/* TABLE PRICES MODAL WRAPPER */}

      {/* DOCUMENT STATUS MODAL WRAPPER */}
      <JarvislyDocumentStatusModal
        {...childProps}
        ref={documentStatusModalRef}
      />
      {/* DOCUMENT STATUS MODAL WRAPPER */}

      <JarvislyFormProvider
        name="form-plan"
        document={document}
        autoFocus="name"
        requiredFields={[
          'name',
          'selectedOperator',
          'price',
          'minDays',
          'maxDays',
          'minAge',
          'maxAge',
          'grievanceLevel',

          'grievanceMinAge1',
          'grievanceMaxAge1',
          'grievanceAdditionAge1',

          'grievanceMinAge2',
          'grievanceMaxAge2',
          'grievanceAdditionAge2',
        ]}
        setTab={setTab}
        onSave={onSave}
        onAfterSave={onAfterSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItens() {
    return [
      {
        label: translateWord('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <MultiPlansRecordIndex
              {...childProps}
              pricesTableModalRef={pricesTableModalRef}
              activeTab={tab}
              ref={recordsTabRef}
            />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('coverages'),
        key: 'coverages',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="coverages">
            <MultiPlansCoveragesIndex
              {...childProps}
              activeTab={tab}
              ref={coveragesTabRef}
            />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('parameters'),
        key: 'parameters',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="parameters">
            <MultiPlansParametersIndex {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
    ];
  }

  function getType(coverageKey) {
    return initialCoverageOptions.find(c => c.value === coverageKey)?.type;
  }

  function getHighlight(coverageKey) {
    return !!initialCoverageOptions.find(c => c.value === coverageKey)
      ?.highlight;
  }
});

export default MultiPlansForm;
