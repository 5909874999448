import { createContext, useState } from 'react';

export const ParametersContext = createContext({});

const ParametersProvider = ({ children }) => {
  // component states ----------------------------------------------------------
  const [selectedRecord, setSelectedRecord] = useState();
  const [showBusinessAreaModal, setShowBusinessAreaModal] = useState();
  const [showOriginModal, setShowOriginModal] = useState(false);
  const [showBoardModal, setShowBoardModal] = useState(false);
  const [showPipelineModal, setShowPipelineModal] = useState(false);
  const [showTemperatureModal, setShowTemperatureModal] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);
  const [showModal, setShowModal] = useState({});

  const setShowModalLevel = (modalName, value) => {
    setShowModal(p => ({ ...p, [modalName]: value }));
  };

  const showModalLevel = modalName => showModal[modalName];

  // PRECISA MUDAR TODOS OS SHOWS E SETSHOWS PARA MODAL LEVEL FUNCTION
  const exposedValues = {
    selectedRecord,
    setSelectedRecord,

    // alterar todos esses estados (removendo-os)...
    showBusinessAreaModal,
    setShowBusinessAreaModal,
    showOriginModal,
    setShowOriginModal,
    showPipelineModal,
    setShowPipelineModal,
    showBoardModal,
    setShowBoardModal,
    showTemperatureModal,
    setShowTemperatureModal,

    // ... para essas funções de exibição de estado
    showModalLevel,
    setShowModalLevel,

    isUpdated,
    setIsUpdated,
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <ParametersContext.Provider value={exposedValues}>
      {children}
    </ParametersContext.Provider>
  );
};

export default ParametersProvider;
