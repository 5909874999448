import React, { useState } from 'react';
import { Avatar, Col, Row, Tooltip } from 'antd';
import utils from 'utils';
import {
  LockTwoTone,
  SafetyCertificateTwoTone,
  UnlockTwoTone,
} from '@ant-design/icons';
import IntlMessage from '../../util-components/IntlMessage';
import dayjs from 'dayjs';
import Highlighter from 'react-highlight-words';

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const RoundedAvatar = ({
  user,
  size,
  showEmail = false,
  showName = false,
  showAge = false,
  showTooltip = false,
  showIcon = false,
  cursorPointer = false,
  showCpfCnpj = false,
  upperCase = false,
  className,
  highlighterText = null,
  shape = 'circle',
  profile = null,
}) => {
  // const rdxSubscription = useSelector(state => state.auth.rdxSubscription);

  // USER FIELDS
  // if (!profile) profile = rdxSubscription?.profile;

  const foreColor = user?.foreColor || user?.__foreColor;
  const backColor = user?.backColor || user?.__backColor;
  const pictureUrl = user?.pictureUrl || user?.__pictureUrl;
  const name = user?.name || user?.__name;
  const cpf = user?.cpf || user?.__cpf;
  const cnpj = user?.cnpj || user?.__cnpj;
  const uniqueKey = cpf || cnpj;
  const email = user?.email || user?.__email || user?.mainContact?.email;
  const birthdate = user?.birthdate || user?.__birthdate;
  const age = birthdate && dayjs().diff(birthdate, 'years');

  const [highlighter] = useState(highlighterText);

  // useEffect(() => {

  // if (highlighterText) {
  //
  //   const text = Array.isArray(highlighterText)
  //       ? highlighterText[0]
  //       : highlighterText;
  //
  //   if (isValidCpf(text)) {
  //     highlighterText = numberMask(text, '999.999.999-99');
  //
  //   } else if (isValidCnpj(text)) {
  //     highlighterText = numberMask(text, '99.999.999/9999-99');
  //   }
  // }
  //
  // setHighlighter(highlighterText);

  // }, [highlighterText]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row align="middle" className={`${className}`} wrap={false}>
      <Col>
        <Tooltip title={name} placement="top" open={showTooltip}>
          {/* IS OWNER */}
          {showIcon && profile === 'owner' ? (
            <Tooltip title={setLocale(true, 'owner')}>
              <SafetyCertificateTwoTone className="rounded-avatar-profile-icon" />
            </Tooltip>
          ) : null}

          {/* IS KEY USER */}
          {showIcon && profile === 'keyUser' ? (
            <Tooltip title={setLocale(true, 'key_user_title')}>
              <UnlockTwoTone className="rounded-avatar-profile-icon" />
            </Tooltip>
          ) : null}

          {/* IS COMMON USER */}
          {showIcon && profile === 'guest' ? (
            <Tooltip title={setLocale(true, 'common_user_title')}>
              <LockTwoTone
                twoToneColor="red"
                className="rounded-avatar-profile-icon"
              />
            </Tooltip>
          ) : null}

          <Avatar
            size={size}
            shape={shape}
            className={cursorPointer ? 'cursor-pointer' : 'cursor-normal'}
            style={{
              color: foreColor || '#000',
              backgroundColor: backColor || '#c0c0c0',
            }}
            src={pictureUrl}
          >
            <span
              className={`font-weight-semibold font-size-${
                size === 'small' ? 'sm' : ''
              }`}
            >
              {name && utils.getNameInitial(name, profile)}
            </span>
          </Avatar>
        </Tooltip>
      </Col>

      {/*<Col className="text-truncate"> // truncate not work in table */}

      <Col>
        {showName || showEmail ? (
          <Col>
            {showName && (
              <>
                <div
                  className={`ml-2 font-weight-bold ${
                    upperCase && 'text-uppercase'
                  }`}
                >
                  {highlighter ? (
                    <Highlighter
                      highlightStyle={{
                        backgroundColor: '#fedf17',
                        padding: 0,
                      }}
                      searchWords={highlighter}
                      autoEscape
                      textToHighlight={name ? name.toString() : ''}
                    />
                  ) : (
                    name
                  )}

                  {birthdate && showAge && <span>, {age}</span>}
                </div>
              </>
            )}

            {!showCpfCnpj && showEmail && (
              <span className="ml-2 text-muted">
                {highlighter ? (
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: '#fedf17',
                      padding: 0,
                    }}
                    searchWords={highlighter}
                    autoEscape
                    textToHighlight={email ? email.toString() : ''}
                  />
                ) : (
                  email
                )}
              </span>
            )}

            {showCpfCnpj && (
              <span className="ml-2 text-muted">
                {highlighter ? (
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: '#fedf17',
                      padding: 0,
                    }}
                    searchWords={highlighter}
                    autoEscape
                    textToHighlight={uniqueKey ? uniqueKey.toString() : ''}
                  />
                ) : (
                  uniqueKey
                )}
              </span>
            )}
          </Col>
        ) : null}
      </Col>

      {/*<Col>*/}

      {/*</Col>*/}
    </Row>
  );
};

export default RoundedAvatar;
