import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { translateX } from 'utils/helpers';
import { Alert, Descriptions, Modal, Spin } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { setShowModal, showModal, updating, selectedSubscription } = props;

  // local variables I ----------------------------------------------------------
  const creditsJarvis = selectedSubscription?.creditsJarvis || {};

  const {
    ncmp,
    cmp,
    pp,
    balance,
    contractedPlan,
    contractedPackage,
    contractedAt,
    contractedNextRenewal,
    contractCancelledAt,
  } = creditsJarvis;
  const creditsInfo = buildCreditsInfo();

  const daysToExpiry =
    contractedNextRenewal &&
    dayjs(contractedNextRenewal)
      .endOf('day')
      .diff(dayjs().startOf('day'), 'days');

  const expired = !contractedNextRenewal || daysToExpiry < 0;

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowModal(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18"
        title={
          <>
            <InfoOutlined />
            <span className="ml-2">{translateX('info')}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        getContainer={false}
        onCancel={onClose}
        cancelText={translateX('close')}
        cancelButtonType="default"
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Spin spinning={updating} size="large">
          {!expired ? (
            <Descriptions
              title="User Info"
              items={creditsInfo}
              bordered
              column={1}
            />
          ) : (
            <Alert
              message={translateX('jarvis_credits')}
              description={translateX('no_credit_or_plan_contracted')}
              type="info"
              showIcon
            />
          )}
        </Spin>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildCreditsInfo() {
    const nextRenewal = {
      key: 'nextRenewal',
      label: translateX('next_renewal'),
      children: dayjs(contractedNextRenewal).format('L'), // end of day
    };
    const endOfValidity = {
      key: 'endOfValidity',
      label: translateX('end_of_validity'),
      children: dayjs(contractedNextRenewal).format('L'), // end of day
    };
    const ncmpBalance = {
      key: 'ncmpBalance',
      label: translateX('ncmp_balance'),
      children: ncmp,
    };
    const cmpBalance = {
      key: 'cmpBalance',
      label: translateX('cmp_balance'),
      children: cmp,
    };
    const ppBalance = {
      key: 'ppBalance',
      label: translateX('pp_balance'),
      children: pp,
    };

    let plan, warning;
    switch (contractedPlan) {
      case 'ncmp':
        plan = 'ncmp_plan';
        warning = 'ncmp_plan_ended_warning';
        break;

      case 'cmp':
        plan = 'cmp_plan';
        warning = 'cmp_plan_ended_warning';
        break;

      case 'pp':
        plan = 'pp_plan';
        warning = 'pp_plan_ended_warning';
        break;
    }

    let pkg;
    switch (contractedPackage) {
      case '100':
        pkg = 'package_100_credits';
        break;
      case '500':
        pkg = 'package_500_credits';
        break;
      case '1000':
        pkg = 'package_1000_credits';
        break;
      case '5000':
        pkg = 'package_5000_credits';
        break;
      case '10000':
        pkg = 'package_10000_credits';
        break;
    }

    const items = [
      {
        key: 'contractedPlan',
        label: translateX('contracted_plan'),
        children: translateX(plan),
      },
      {
        key: 'package',
        label: translateX('package'),
        children: translateX(pkg),
      },
      {
        key: 'contractedDate',
        label: translateX('contracted_date'),
        children: dayjs(contractedAt).format('L'), // end of day
      },
      {
        key: 'currentBalance',
        label: translateX('current_balance'),
        children: balance,
      },
      {
        key: 'warning',
        label: translateX('warning'),
        children: translateX(warning),
      },
    ];

    if (contractCancelledAt) {
      items.splice(3, 0, endOfValidity);
    } else {
      items.splice(3, 0, nextRenewal);
    }

    if ((ncmp && cmp) || (ncmp && pp)) {
      items.splice(items.length - 2, 0, ncmpBalance);
    }

    if ((cmp && ncmp) || (cmp && pp)) {
      items.splice(items.length - 2, 0, cmpBalance);
    }

    if ((pp && ncmp) || (pp && cmp)) {
      items.splice(items.length - 2, 0, ppBalance);
    }

    return items;
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyInfoModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [showModal, setShowModal] = useState(false);
  const [updating, setUpdating] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
  }));

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------
  const modalProps = {
    ...props,
    showModal,
    setShowModal,
    updating,
  };

  // UI COMPONENT --------------------------------------------------------------
  return <ModalComponent {...modalProps} />;
});

export default JarvislyInfoModal;
