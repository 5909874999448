import React, { useContext, useEffect, useState } from 'react';
import { toCapitalCase, translateX } from 'utils/helpers';
import { Button, Card, Col, Row, Table, Tooltip } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import {
  goFocus,
  JarvislyCnpj,
  JarvislyCpf,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislySelect,
  onFinishFailedFn,
} from 'components/jarvisly-components/others/FormComponents';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import { CheckOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import axiosService from 'services/axiosService';
import DATA_SEARCH_MODULE from '../../dataSearch';
import dayjs from 'dayjs';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const SearchForm = props => {
  // providers context ---------------------------------------------------------
  const { form, elRefs } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    onConfirm,
    selectedEngine,
    setSelectedEngine,
    engine,
    dataType,
    loading,
  } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [dataTypeOptions, setDataTypeOptions] = useState([]);
  const [selectedDataType, setSelectedDataType] = useState();

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const focus = !engine ? 'engine' : !dataType ? 'dataType' : 'dataSearch';
    form.setFieldsValue({ engine, dataType });
    goFocus(elRefs, focus);

    setDataTypeOptions(buildDataTypeOptions());
    setSelectedDataType(dataType);
  }, [elRefs, selectedEngine]);

  // methods -------------------------------------------------------------------
  const onClickSearch = () => {
    form
      .validateFields(['engine', 'dataType', 'dataSearch'])
      .then(values => {
        onConfirm && onConfirm(values);
      })
      .catch(err => {
        onFinishFailedFn(err, elRefs, null, null, null, true);
      });
  };

  const onChangeSelect = (field, value) => {
    localStorage.setItem(`dataSearch.${field}`, value);

    if (field === 'engine') {
      setSelectedEngine(value);
      setDataTypeOptions(buildDataTypeOptions());
    } else {
      setSelectedDataType(value);
    }
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Row gutter={ROW_GUTTER} justify="center">
      <Card
        title={translateX('research_base')}
        style={{ width: '100%', maxWidth: '800px' }}
      >
        <Row gutter={ROW_GUTTER} justify="center">
          <Col xs={24} sm={7}>
            <JarvislySelect
              name="engine"
              label="search_engine"
              onChange={value => onChangeSelect('engine', value)}
              disabled={loading}
              options={[
                {
                  value: 'vdata',
                  label: 'vData',
                  text: 'vData',
                },
                {
                  value: 'iweb',
                  label: 'iwebSearch',
                  text: 'iwebSearch',
                },
                // {
                //   value: 'bzapi',
                //   label: 'bzPowerSearch',
                //   text: 'bzPowerSearch',
                // },
              ]}
              showSearch
              allowClear
              optionLabelProp="text"
              className="mr-3"
            />
          </Col>

          <Col xs={24} sm={7}>
            <JarvislySelect
              name="dataType"
              label="data_type"
              disabled={loading}
              onChange={value => onChangeSelect('dataType', value)}
              options={dataTypeOptions}
              showSearch
              allowClear
              optionLabelProp="text"
              // className="mr-3"
            />
          </Col>

          {!['cpf', 'cnpj', 'phone'].includes(selectedDataType) ? (
            <Col
              xs={24}
              sm={10}
              // style={{
              //   display: !['cpf', 'cnpj', 'phone'].includes(selectedDataType)
              //     ? 'flex'
              //     : 'none',
              // }}
            >
              <JarvislyInput
                name="dataSearch"
                label="data"
                disabled={loading}
                // x-style-form-item={{
                //   display: !['cpf', 'cnpj', 'phone'].includes(selectedDataType)
                //     ? 'flex'
                //     : 'none',
                // }}
                style={{ width: '100%' }}
              />
            </Col>
          ) : null}

          {selectedDataType === 'cpf' ? (
            <Col
              xs={24}
              sm={10}
              // style={{ display: selectedDataType === 'cpf' ? 'flex' : 'none' }}
            >
              <JarvislyCpf
                name="dataSearch"
                label="cpf"
                style={{ width: '100%' }}
              />
            </Col>
          ) : null}

          {selectedDataType === 'cnpj' ? (
            <Col
              xs={24}
              sm={10}
              // style={{ display: selectedDataType === 'cnpj' ? 'flex' : 'none' }}
            >
              <JarvislyCnpj
                name="dataSearch"
                label="cnpj"
                style={{ width: '100%' }}
              />
            </Col>
          ) : null}

          <Col xs={24} className="text-right">
            <Button
              type="primary"
              onClick={onClickSearch}
              block
              loading={loading}
              style={{ width: '120px' }}
              icon={<CheckOutlined />}
            >
              &nbsp;{translateX('search')}
            </Button>
          </Col>
        </Row>
      </Card>
    </Row>
  );
  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDataTypeOptions() {
    const typesByEngines = {
      vdata: [
        {
          value: 'name',
          label: translateX('name'),
          text: translateX('name'),
        },
        {
          value: 'cpf',
          label: translateX('cpf'),
          text: translateX('cpf'),
        },
        // {
        //   value: 'cnpj',
        //   label: translateX('cnpj'),
        //   text: translateX('cnpj'),
        // },
      ],
      iweb: [
        {
          value: 'cpf',
          label: translateX('cpf'),
          text: translateX('cpf'),
        },
        {
          value: 'cnpj',
          label: translateX('cnpj'),
          text: translateX('cnpj'),
        },
      ],
    };

    const result = typesByEngines?.[selectedEngine] || [];
    const dt = form.getFieldValue('dataType');

    if (result.findIndex(r => r.value === dt) === -1) {
      form.setFieldValue('dataType', '');
    }

    return result;
  }
};

// COMPONENT *******************************************************************
// *****************************************************************************
const SearchResult = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { loading, data } = props;

  // local variables I ---------------------------------------------------------
  const columns = buildColumns();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div className="mt-3">
      <Table
        // key={tableKey}
        rowKey="key"
        size="small"
        // columns={tableColumns.filter(x => !x.hidden)}
        columns={columns}
        dataSource={data || []}
        loading={loading}
        pagination={false}
        // onChange={onTableChanges}
        // rowSelection={buildRowSelection}
        // className="with-checkbox"
        // resetTableSearch={resetTableSearch}
        // loading={isWorking}
        // expandable={{
        //   expandedRowRender: buildTableItemsColumns,
        //   defaultExpandedRowKeys: ['0'],
        // }}
      />
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildColumns() {
    return [
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('cpf_cnpj')}
          </span>
        ),
        dataIndex: 'cpfCnpj',
        key: 'cover.cpfCnpj',
        render: (_, record) => (
          <div>
            <Tooltip
              placement="left"
              title={
                record.type === 'person'
                  ? translateX('individual_person')
                  : translateX('legal_person')
              }
            >
              <span className="mr-2">
                {record.type === 'person' ? <UserOutlined /> : <ShopOutlined />}
              </span>
            </Tooltip>

            <a>{record?.cover?.cpfCnpj}</a>
          </div>
        ),
      },
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('name_trade_name')}
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        render: text => <span>{toCapitalCase(text)}</span>,
      },
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('mother_company_name')}
          </span>
        ),
        dataIndex: 'cover.parent',
        key: 'parent',
        render: (_, record) => (
          <span>{toCapitalCase(record?.cover?.parent)}</span>
        ),
      },
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('age_since')}
          </span>
        ),
        dataIndex: 'age',
        key: 'age',
        render: text => <span>{text} ano(s)</span>,
      },
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('location')}
          </span>
        ),
        dataIndex: 'cover.location',
        key: 'location',
        render: (_, record) => (
          <span>{toCapitalCase(record?.cover?.location)}</span>
        ),
      },
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('last_consultation')}
          </span>
        ),
        dataIndex: 'consultedAt',
        key: 'consultedAt',
        align: 'left',
        render: (_, record) =>
          record?.consultedAt ? (
            <span>{dayjs(record.consultedAt).fromNow()}</span>
          ) : (
            'n/a'
          ),
      },
    ];
  }
};

// COMPONENT *******************************************************************
// *****************************************************************************
const DataSearchConsultationTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  useTabChild('consultant', [], props);
  const engine = localStorage.getItem('dataSearch.engine');
  const dataType = localStorage.getItem('dataSearch.dataType');
  const fakeData = buildFakeData();

  // component states ----------------------------------------------------------
  const [selectedEngine, setSelectedEngine] = useState(engine);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onConfirm = async body => {
    console.log('ON CONFIRM BODY =>', body);
    setLoading(true);
    const axiosOptions = {
      url: `${DATA_SEARCH_MODULE.api}/${engine}`,
      data: body,
      returnAsObject: false,
    };

    const [docs] = await axiosService.post(axiosOptions);

    setData(docs);
    setLoading(false);

    console.log('RESPONSE =>', docs);
  };

  // local variables III -------------------------------------------------------
  const childProps = {
    ...props,
    onConfirm,
    engine,
    dataType,
    selectedEngine,
    setSelectedEngine,
    loading,
    setLoading,
    data,
    setData,
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <JarvislyFormProvider
      requiredFields={['engine', 'dataType', 'dataSearch']}
      // autoFocus={'engine'}
      // onConfirm={onConfirm}
      abortComponentSave={true}
    >
      <SearchForm {...childProps} />

      {data?.length || loading ? <SearchResult {...childProps} /> : null}
    </JarvislyFormProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildFakeData() {
    return [
      {
        _id: '1',
        type: 'person',
        cpfCnpj: '281.0**.***-06',
        name: 'RICARDO BIRUEL',
        parent: 'LEONILDA BIRUEL',
        age: 45,
        location: 'CAMPINAS - SP',
        consultedAt: '2025-01-10',
      },
      {
        _id: '2',
        type: 'person',
        cpfCnpj: '281.0**.***-06',
        name: 'RICARDO CENTURION BIRUEL',
        parent: 'ISABEL SANCHES',
        age: 67,
        location: 'CAMPINAS - SP',
        consultedAt: '2025-02-05',
      },
      {
        _id: '3',
        type: 'company',
        cpfCnpj: '40.8**.***/0001-02',
        name: 'RICARDO TECNOLOGIA',
        parent: 'RICARDO EMPRESA INC ME',
        age: 4,
        location: 'SÃO PAULO - SP',
        consultedAt: null,
      },
    ];
  }
};

export default DataSearchConsultationTab;
