import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ModuleContext, useModuleContext } from '../ModuleContext';
import PageHeaderInfo from '../../layout-components/PageHeaderInfo';
import { Flex } from 'antd';
import { THEME_BACKGROUND } from 'constants/ThemeConstant';
import FormHeader from './FormHeader';
import appService from 'services/appService';
import app from 'configs/ConfigIndex';
import Icon from '../../util-components/Icon';
import { isMongoObjectId, translateWord } from 'utils/helpers';
import Loading from '../../jarvisly-components/others-old/Loading';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const FormIndex = props => {
  // props deconstruction ------------------------------------------------------
  const {
    // showDataImportModal,
    // setShowDataImportModal,
    showParametersModal,
    setShowParametersModal,
  } = useContext(ModuleContext);

  // local variables -----------------------------------------------------------
  const { _id } = useParams();
  const navigate = useNavigate();

  const elRefs = {};
  // const documentHeaderRef = useRef(null);
  const formRef = useRef(null);

  const { selectedModule, components, documentState } = useSelector(
    s => s.moduleSlice,
  );
  const {
    document,
    refreshDocument,
    refreshDataOfDocument,
    documentContext,
    setDocumentContext,
    resetDocumentContext,
    isWorking,
  } = useModuleContext();
  const { mode, isLoaded } = documentContext;
  const isEmpty = !document?._id;
  const isDisabled = mode === 'view';

  // component states ----------------------------------------------------------
  const [requiredFields, setRequiredFields] = useState([]);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    appService.initRoute({
      moduleType: 'document',
      showPageHeader: true,
      showBreadcrumb: false,
      disableSubscriptionCombo: true,
    });

    let isMounted = true;
    let intervalId;

    // comentado pois não abre a rota '/app/account/settings/organization/add'
    // if (selectedModule?.name && _id !== 'add') {
    if (selectedModule?.id) {
      if (isMongoObjectId(_id)) {
        setDocumentContext({ mode: 'view' });

        (async () => {
          const doc = await refreshDocument(_id);
          const currentToken = localStorage.getItem('auth_token');
          if (_id !== 'add' && !doc && currentToken) {
            appService.message('w', 'document_not_found', 'documentNotFound');
            navigate(selectedModule?.url, { replace: true });
          }
        })();

        // refresh partial data
        const refreshInterval = documentState?.refreshDataOfDocument;

        if (isNaN(refreshInterval) || refreshInterval < 10000) return;

        intervalId = setInterval(() => {
          (async () => {
            if (isMounted) await refreshDataOfDocument(_id);
          })();
        }, refreshInterval); // Atualizando a cada 1 minuto
      } else {
        setDocumentContext({ mode: 'add', isLoaded: true });
      }
    } else {
      // ATENÇÃO! Este navigate acaba cancelando a seleção da TAB do form
      // navigate(selectedModule?.url, { replace: true });
    }

    return () => {
      clearInterval(intervalId);
      isMounted = false;
    };
  }, [selectedModule?.id, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      resetDocumentContext();
    };
  }, []);

  // methods -------------------------------------------------------------------
  const onGoBack = () => {
    const urlQueryString = selectedModule?.defaultUrlQueryString
      ? selectedModule.defaultUrlQueryString
      : '';

    navigate(selectedModule?.url + urlQueryString, {
      state: { qsObj: 'memo' },
      replace: true,
    });
  };

  // UI COMPONENT --------------------------------------------------------------
  const childProps = {
    ...props,
    // documentHeaderRef,
    formRef,
    isEmpty,
    isDisabled,
    isLoaded,
    selectedModule,
    components,
    elRefs,
    mode,
    _id,
    onGoBack,
    ...useModuleContext(),
    requiredFields,
    setRequiredFields,

    showParametersModal,
    setShowParametersModal,
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <div style={{ marginTop: -128 }}>
      <Loading isFetching={isWorking} />

      {components?.formHeader ? (
        components.formHeader(childProps)
      ) : (
        <>
          <PageHeaderInfo
            background={THEME_BACKGROUND[app.THEME_COLOR]}
            cssClass="bg-primary"
            overlap
            className="force-header-height-146"
          >
            {/*<Row justify="end" align="middle" style={{ marginBottom: -28 }}>*/}
            <Flex justify="end" align="end" style={{ marginBottom: -28 }}>
              <h3
                className="mb-0 mr-2 font-weight-semibold text-right text-white"
                style={{ lineHeight: '26px' }}
              >
                {selectedModule?.customIcon ? (
                  <Icon type={selectedModule.customIcon} />
                ) : selectedModule?.icon ? (
                  <Icon type={selectedModule.icon} />
                ) : null}
              </h3>

              <h3 className="mb-0 mr-2 font-weight-semibold text-right text-white">
                {/*{selectedModule?.icon && (*/}
                {/*  <Icon type={selectedModule.icon} className="mr-2" />*/}
                {/*)}*/}
                {translateWord(
                  true,
                  selectedModule?.customTitle || selectedModule?.title,
                )}
              </h3>
            </Flex>
            {/*</Row>*/}

            <div className="container text-center">
              <div className="py-5 py-md-1 my-md-5"></div>
            </div>
          </PageHeaderInfo>

          <FormHeader {...childProps} />
        </>
      )}

      {/* WRAPPER PARAMETERS MODAL */}
      {selectedModule?.components?.parameters
        ? selectedModule.components.parameters(childProps)
        : null}
      {/* WRAPPER PARAMETERS MODAL */}

      {/* WRAPPER DATA IMPORT MODAL */}
      {selectedModule?.components?.dataImport
        ? selectedModule.components.dataImport(childProps)
        : null}
      {/* WRAPPER DATA IMPORT MODAL */}

      <div>{components?.form && components.form(childProps, formRef)}</div>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT **********************************************************************
// *****************************************************************************

export default FormIndex;
