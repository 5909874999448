import { useEffect } from 'react';
import app from 'configs/ConfigIndex';

const addBodyClass = className => document.body.classList.add(className);
const removeBodyClass = className => document.body.classList.remove(className);

// Função para alterar a cor da barra de rolagem
function setScrollbarColor(colorClass) {
  if (!colorClass) return;

  // Remova as classes de cores anteriores
  document.body.classList.remove(
    'BLUE',
    'PURPLE',
    'CYAN',
    'GREEN',
    'MAGENTA',
    'PINK',
    'RED',
    'ORANGE',
    'YELLOW',
    'VOLCANO',
    'GEEK_BLUE',
    'LIME',
    'GOLD',
    'CUSTOM_MULTI_APP',
    'CUSTOM_VEERTICAL_APP',
    'fix-scrollbar',
  );

  const fixScrollbar = localStorage.getItem('auth_token')
    ? 'fix-scrollbar'
    : 'non-fix-scrollbar';

  if (fixScrollbar === 'fix-scrollbar') {
    document.body.classList.remove('non-fix-scrollbar');
  } else {
    document.body.classList.remove('fix-scrollbar');
  }

  document.body.classList.add(colorClass, fixScrollbar);

  // Add dark color theme in body (login page)
  if (app.currentTheme === 'dark') {
    document.body.classList.add('body-dark-theme');
  }
}

export default function useBodyClass(className) {
  useEffect(() => {
    setScrollbarColor(app?.THEME_COLOR); // Altera para azul
  }, [app?.THEME_COLOR]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Set up
    className instanceof Array
      ? className.map(addBodyClass)
      : addBodyClass(className);

    // Clean up
    return () => {
      className instanceof Array
        ? className.map(removeBodyClass)
        : removeBodyClass(className);
    };
  }, [className]);
}
