import {
  getFromLocalStorage,
  normalize,
  toCapitalCase,
  translateWord,
} from 'utils/helpers';
import MultiPlansList from './List/List';
import MultiPlansForm from './Form/Form';
import {
  DefaultFormHeaderColumn1,
  DefaultFormHeaderCustomIcons,
} from 'components/jarvisly-module/Form/FormHeader';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MODULE_ID = 'appmultiPlans';
export const APPMULTI_PLANS_API = '/v1/plan';

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const MULTI_PLANS_MODULE = {
  id: MODULE_ID,
  noBlockScreenWithAppNotConfigured: false,
  api: APPMULTI_PLANS_API,
  urlCustomForm: null,
  defaultUrlQueryString: null,

  components: {
    // list
    dashboard: null,
    list: props => <MultiPlansList {...props} />,
    grid: null,
    kanban: null,
    filter: null,

    // form
    form: (props, ref) => <MultiPlansForm {...props} ref={ref} />,
    formHeaderTitle: null,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: null,
    formHeaderColumn3: null,
    formHeaderTags: null,
    formHeaderCustomIcons: props => (
      <DefaultFormHeaderCustomIcons {...props} showStatusIcon showApiIcon />
    ),

    // module parameters
    parameters: null,

    // module data import
    dataImport: null,

    // data preview
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
    // canEditDocument
    decorateParameters,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],
    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    /*
        search: {},
        textSearch: {},
    */
  },

  listState: {
    override: false,
    hideBreadcrumb: false,

    showParameters: true,
    showDataImport: true,

    initTab: 'record',
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
    fieldsForRegex: [],
    textSearchInDrawer: true, // inform that the textSearch field is into Drawer Filter to mark the filter button as danger (red)
    refreshDataOfList: 0,

    // sort options
    sorterInitialize: {
      '_metadata.audit.updatedAt': -1,
    },
    sorterOrderPrecedence: [
      '_metadata.counter',
      'operatorId',
      'name',
      'status',
      '_metadata.audit.updatedAt',
    ],
  },

  documentState: {
    showParameters: false,

    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    newDocumentTitle: 'new_plan',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: false,
    indexes: [],

    refreshDataOfDocument: 0,
    mockDocument: {},
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default MULTI_PLANS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  doc.pricePrefix = doc?.priceType;
  doc.priceSuffix = doc?.currency;

  doc.__destinations = {};
  doc?.destinations?.map(i => {
    doc.__destinations[i] = true;
    return doc;
  });

  doc.__travelProfiles = {};
  doc?.travelProfiles?.map(i => {
    doc.__travelProfiles[i] = true;
    return doc;
  });

  return doc;
}

function decorateParameters(doc) {
  if (!doc) return doc;

  // coverages
  if (doc?.coverages?.map) {
    doc.coverages = doc.coverages.map(c => {
      return {
        value: c._id,
        text: normalize(c.value, 'lower'),
        label: toCapitalCase(c.value),
        tooltipText: translateWord('coverage'),
        disabled: false,
      };
    });
  }

  // assists
  if (doc?.assists?.map) {
    doc.assists = doc.assists.map(c => {
      return {
        value: c._id,
        text: normalize(c.value, 'lower'),
        label: toCapitalCase(c.value),
        tooltipText: translateWord('assist'),
        disabled: false,
      };
    });
  }

  return doc;
}
