import { Avatar, Card, Col, Flex, Row, Tooltip, ColorPicker } from 'antd';
import { CameraOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { generateHexColor, parsePhoneToDisplay } from 'utils/helpers';
import { translateX } from 'utils/helpers';
import { useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import {
  JarvislyCpf,
  JarvislyFormContext,
  JarvislyFormProvider,
} from 'components/jarvisly-components/others/FormComponents';
import axiosService from 'services/axiosService';
import JarvislyEntityForm from 'components/jarvisly-components/forms/Items/JarvislyEntityForm';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import utils from 'utils';
import authService from 'services/authService';
import Loading from '../../../../../components/jarvisly-components/others-old/Loading';

const AvatarWithActions = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables I ---------------------------------------------------------
  useTabChild('profile', ['cpf'], props);

  // component states ----------------------------------------------------------
  const [backColor, setBackColor] = useState('#b9bcc4');
  const [foreColor, setForeColor] = useState('#ffffff');

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (document?.backColor && document.backColor !== backColor) {
      setBackColor(document.backColor);
    }
    if (document?.foreColor && document.foreColor !== foreColor) {
      setForeColor(document.foreColor);
    }
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onChangeColor = color => {
    const hexBackColor = color.toHexString();
    const hexForeColor = generateHexColor(hexBackColor, true);

    setBackColor(hexBackColor);
    setForeColor(hexForeColor);
  };

  const onSaveColor = async isOpen => {
    try {
      if (!isOpen) {
        const axiosOptionsAuth = {
          url: '/v1/auth/',
          _id: document?._id,
          data: {
            backColor,
            foreColor,
          },
        };
        await axiosService.put(axiosOptionsAuth);
        authService.refreshAccount();
      }
    } catch (error) {
      console.error();
    }
  };

  const handleUpload = () => {
    // Função para fazer o upload da foto
  };

  // const handleView = () => {
  //   Modal.info({
  //     title: 'Visualizar Avatar',
  //     content: (
  //       <img
  //         src="/img/avatars/thumb-15.jpg"
  //         alt="avatar"
  //         style={{ width: '100%' }}
  //       />
  //     ),
  //   });
  // };

  // const handleRemove = () => {
  //   // Função para remover a foto
  // };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  /*
    const AvatarColorPicker = () => {
      return (
        <div
          style={{
            position: 'absolute',
            // top: '50%', // Centraliza verticalmente
            bottom: -4,
            left: '50%', // Centraliza horizontalmente
            transform: 'translate(-50%, -50%)', // Ajusta para ficar bem no centro
            display: 'flex',
            gap: '1rem',
            background: 'rgba(0, 0, 0, 0.4)',
            padding: '0.5rem 1rem',
            borderRadius: '8px',
          }}
        >
          {/!* Ícone de Upload *!/}
          <Tooltip title="Upload Foto">
            <CameraOutlined
              onClick={handleUpload}
              style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}
            />
          </Tooltip>

          {/!* Ícone de Visualizar *!/}
          <Tooltip title="Visualizar Foto">
            <EyeOutlined
              onClick={handleView}
              style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}
            />
          </Tooltip>

          {/!* Ícone de Remover *!/}
          <Tooltip title="Remover Foto">
            <DeleteOutlined
              onClick={handleRemove}
              style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}
            />
          </Tooltip>
        </div>
      );
    };
  */

  // UI COMPONENTS -------------------------------------------------------------
  const AvatarInitialName = () => {
    const title = document?.name;
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%', // Centraliza verticalmente
          left: '50%', // Centraliza horizontalmente
          transform: 'translate(-50%, -50%)', // Ajusta para ficar bem no centro
          display: 'flex',
          gap: '1rem',
          background: backColor,
          color: foreColor,
          width: '90%',
          height: '90%',
          alignItems: 'center',
          borderRadius: '8px',
          fontSize: 68,
          fontWeight: 600,
        }}
      >
        <div style={{ width: '100%' }} className="text-center">
          {title?.match ? utils.getNameInitial(title) : ''}
        </div>
      </div>
    );
  };

  const AvatarActionsTool = (
    <div
      style={{
        position: 'absolute',
        // top: '50%', // Centraliza verticalmente
        bottom: -8,
        left: '50%', // Centraliza horizontalmente
        transform: 'translate(-50%, -50%)', // Ajusta para ficar bem no centro
        display: 'flex',
        gap: '1rem',
        background: 'rgba(0, 0, 0, 0.4)',
        padding: '0.5rem 1rem',
        borderRadius: '8px',
      }}
    >
      {/* Ícone de Upload */}
      <Tooltip title="Upload Foto">
        <CameraOutlined
          onClick={handleUpload}
          style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}
        />
      </Tooltip>

      {/* Ícone de Remover */}
      {/*<Tooltip title="Remover Foto">*/}
      {/*  <CloseCircleOutlined*/}
      {/*    onClick={handleRemove}*/}
      {/*    style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}*/}
      {/*  />*/}
      {/*</Tooltip>*/}

      {/* Ícone color picker */}
      <Tooltip title="Alterar Cor">
        <ColorPicker
          rootClassName="color-picker-small"
          defaultValue={backColor}
          size="small"
          onChange={onChangeColor}
          // open={isPickerOpen}
          // trigger="click"
          value={backColor}
          onOpenChange={onSaveColor}
          // onBlur={() => setIsPickerOpen(false)} // Fecha quando tirar o foco
          // onOpenChange={v => setIsPickerOpen(v)}
        />
        {/*<FormatPainterOutlined*/}
        {/*  onClick={handleRemove}*/}
        {/*  style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}*/}
        {/*/>*/}
      </Tooltip>

      {/* Ícone de Visualizar */}
      {/*<Tooltip title="Visualizar Foto">*/}
      {/*  <EyeOutlined*/}
      {/*    onClick={handleView}*/}
      {/*    style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}*/}
      {/*  />*/}
      {/*</Tooltip>*/}
    </div>
  );

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <div className="text-center">
      <div
        className="rounded p-2 bg-white shadow-sm mx-auto"
        style={{
          marginTop: '-3.5rem',
          maxWidth: `${props.avatarSize + 16}px`,
          position: 'relative', // Adiciona posição relativa ao container do avatar
        }}
      >
        <Avatar
          shape="square"
          size={props.avatarSize}
          // src="/img/avatars/thumb-15.jpg"
        />
        <AvatarInitialName />
        {/* Ícones sobre a foto */}
        {AvatarActionsTool}
      </div>
    </div>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
const ProfileHeader = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables I ---------------------------------------------------------
  const showAvatar = true;

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Card>
      <Row justify={showAvatar ? 'center' : 'start'}>
        <Col sm={24} md={23}>
          <div className="d-md-flex">
            {showAvatar ? (
              <AvatarWithActions {...props} />
            ) : // <div
            //   className="rounded p-2 bg-white shadow-sm mx-auto"
            //   style={{
            //     marginTop: '-3.5rem',
            //     maxWidth: `${props.avatarSize + 16}px`,
            //   }}
            // >
            //   <Avatar
            //     shape="square"
            //     size={props.avatarSize}
            //     src="/img/avatars/thumb-15.jpg"
            //   />
            // </div>
            null}

            <div className={`w-100 ${showAvatar ? 'ml-md-4' : ''}`}>
              {/* NANE */}
              <Flex align="center" className="mb-3 text-md-left text-center">
                <h2 className="mb-0 mt-md-0 mt-2">{document?.name}</h2>
              </Flex>
              {/* NAME*/}

              <Row gutter="16">
                {/* COLUMN 1 */}
                <Col xs={24} md={12}>
                  {/* EMAIL */}
                  <Row className="mb-2">
                    <Col span={24}>
                      <Icon
                        type={MailOutlined}
                        className="text-primary font-size-md"
                      />
                      <span className="text-muted ml-2">{document?.email}</span>
                    </Col>
                  </Row>
                  {/* EMAIL */}

                  {/* PHONE */}
                  <Row>
                    <Col span={24}>
                      <Icon
                        type={PhoneOutlined}
                        className="text-primary font-size-md"
                      />
                      <span className="text-muted ml-2">
                        {parsePhoneToDisplay(document?.phoneNumber)}
                      </span>
                    </Col>
                  </Row>
                  {/* PHONE */}
                </Col>
                {/* COLUMN 1 */}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
const ProfileForm = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { setSelectedProfile, selectedProfile, loading } = props;

  // local variables I ---------------------------------------------------------
  // useTabChild('profile', ['cpf'], props);

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Loading
        isFetching={loading}
        // data={list}
        marginTop={-400}
        // fullscreen={fullscreen}
        // ignoreNoData={ignoreNoData}
      />

      <JarvislyEntityForm
        {...props}
        entityPersonType="person"
        additionalInfo="required"
        setSelectedEntity={setSelectedProfile}
        selectedEntity={selectedProfile}
      />
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
const ProfileTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const avatarSize = 150;
  const { selectedUser: document } = useSelector(s => s.authSlice);
  const { selectedCompany } = useSelector(s => s.authSlice);

  // component states ----------------------------------------------------------
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [entityType, setEntityType] = useState('person');
  const [requiredFields, setRequiredFields] = useState();
  const [loading, setLoading] = useState(false);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedProfile,
    setSelectedProfile,
    selectedCompany,
    entityType,
    setEntityType,
    setRequiredFields,
    requiredFields,
    document,
    setLoading,
    loading,
  };
  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onSave = async ({ body }) => {
    if (!body) return;

    setLoading(true);
    if (!body.phone) body.phone = body.phoneNumber.replace('+55', '');

    const axiosOptionsAuth = {
      url: '/v1/auth/',
      _id: document?._id,
      data: body,
    };
    await axiosService.put(axiosOptionsAuth);
    await authService.refreshAccount();
    // setLoading(false);
    return body;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <JarvislyFormProvider
      // document={document}
      requiredFields={[
        'cpf',
        'name',
        'fullName',
        'birthdate',
        'gender',
        'zip',
        'number',
      ]}
      disabledFields={['phone', 'email']}
      onSave={onSave}
      showSubmitButton={true}
      abortComponentSave
    >
      <div className="container my-5">
        <ProfileHeader {...childProps} avatarSize={avatarSize} />
        <Card
          title={
            <div className="text-muted">{translateX('personal_data')}</div>
          }
        >
          <ProfileForm {...childProps} />
        </Card>
      </div>
    </JarvislyFormProvider>
  );
};

export default ProfileTab;
