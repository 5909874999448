import { EditOutlined } from '@ant-design/icons';
import { Button, Flex, Table, Tooltip } from 'antd';
import { parsePhoneToDisplay, translateX } from 'utils/helpers';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import Icon from 'components/util-components/Icon';
import { FaCar, FaFacebook, FaTruck, FaWhatsapp } from 'react-icons/fa';
import {
  MdBoy,
  MdChildFriendly,
  MdDirectionsWalk,
  MdOutlineElderly,
} from 'react-icons/md';
import dayjs from 'dayjs';
import { LuTruck } from 'react-icons/lu';
import { IoIosCar } from 'react-icons/io';
import { PiMotorcycleFill } from 'react-icons/pi';
import { TfiWorld } from 'react-icons/tfi';

const AppvaliantLeadsList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  // const destinyFilter = buildDestinyFilter();
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        key={tableKey}
        size="small"
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        hidden: !showNumberOfLine,
        title: translateX('line'),
        width: 40,
        align: 'center',
        render: (item, record, index) => {
          if (!pagination) return;

          const { current, pageSize } = pagination;

          return (
            <div className="text-center">
              {current * pageSize - pageSize + index + 1 || index}
            </div>
          );
        },
      },

      {
        title: translateX('name'),
        dataIndex: 'name',
        ...columnSorter('name'),
        ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div style={{ width: 200 }}>
              <div className="font-weight-bold">{record?.name}</div>
              <div>{record?.email}</div>
              <div>{parsePhoneToDisplay(record?.phone)}</div>
            </div>
          );
        },
      },

      // {
      //   title: translateX('phone'),
      //   dataIndex: 'phone',
      //   width: 180,
      //   render: (_, record) => parsePhoneToDisplay(record?.phone),
      // },

      {
        title: translateX('quotation'),
        dataIndex: 'quotation',
        // ...columnFilters('destiny', destinyFilter),
        // ...columnSorter('quotation'),
        // width: 200,
        render: (_, record) => (
          <>
            <ImageDescription
              size="large"
              icon={
                record?.quotations?.[0]?.interest === 'vehicle' ? (
                  <FaCar />
                ) : record?.quotations?.[0]?.interest === 'motorcycle' ? (
                  <PiMotorcycleFill />
                ) : record?.quotations?.[0]?.interest === 'truck' ? (
                  <LuTruck />
                ) : (
                  <FaWhatsapp />
                )
              }
              title={record?.quotations?.[0]?.model}
              subtitle={record?.quotations?.[0]?.brand}
              // subtitle2={parsePhoneToDisplay(record?.phone)}
              foreColor="#003265"
              // foreColor='#FBB03A'
              backColor="#FBB03A"
              // backColor='#FFFFFF'
            />
          </>
        ),
      },

      {
        title: translateX('year'),
        dataIndex: 'year',
        // ...columnFilters('destiny', destinyFilter),
        ...columnSorter('year'),
        render: (_, record) => record?.quotations?.[0]?.year,
      },

      {
        title: translateX('location'),
        dataIndex: 'location',
        // ...columnFilters('destiny', destinyFilter),
        ...columnSorter('location'),
        render: (_, record) => record.location,
      },

      // {
      //   title: translateX('status'),
      //   dataIndex: 'status',
      //   ...columnFilters('status', statusFilter),
      //   ...columnSorter('status'),
      //   // width: 100,
      //   render: (_, record) => <DocumentStatusTag record={record} />,
      // },

      {
        title: translateX('provider'),
        dataIndex: 'provider',
        ...columnSorter('provider'),
        // ...columnSearch('provider'),
        classProvider: 'text-truncate',
        render: (_, record) => {
          return (
            <div>
              <div className="font-weight-bold text-uppercase">
                {record?.provider}
              </div>
              <Flex align="center">
                {record?.quotations?.[0]?.campaign?.origin?.toLowerCase() ===
                'facebook' ? (
                  <FaFacebook className="mr-1" style={{ color: '#0866FF' }} />
                ) : (
                  <TfiWorld className="mr-1" style={{ color: '#FBB03A' }} />
                )}
                {record?.quotations?.[0]?.campaign?.name}
              </Flex>
              <div>{record?.quotations?.[0]?.campaign?.url}</div>
            </div>
          );
        },
      },

      {
        title: translateX('last_update'),
        dataIndex: '_metadata.audit.updatedAt',
        ...columnSorter('_metadata.audit.updatedAt'),
        // width: 180,
        hidden: false,
        render: (_, record) => (
          <>
            {record?._metadata?.audit?.updatedAt &&
              dayjs(record?._metadata.audit?.updatedAt).fromNow()}
          </>
        ),
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={translateX('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            {/* <Tooltip title={translateX('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>*/}
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }

  function buildDestinyFilter() {
    const destinations = appappvaliantService.getDestinations();

    return destinations?.map(d => {
      return {
        value: d.value,
        text: d.label,
      };
    });
  }
};

export default AppvaliantLeadsList;
