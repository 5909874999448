import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import { useEffect, useState } from 'react';
import { translateX } from 'utils/helpers';
import { Tabs } from 'antd';
import IotDevicesDeviceTab from './device/deviceTab';
import useTabParent from 'components/jarvisly-components/hooks/useTabParentHook';
import IotDevicesCustomerTab from './customerTab/IotDevicesCustomerTab';
import InstallationTab from './installation/installationTab';
import entityService from 'services/entityService';
import PartnerTab from './PartnerTab/PartnerTab';
import { useSelector } from 'react-redux';
import IotDevicesConfigurationTab from './configurationTab/iotDevicesConfigurationTab';

const IotDevicesForm = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, selectedModule, mode } = props;

  // local variables I ---------------------------------------------------------
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'configuration', 'customer', 'installation'];
  const { selectedSubscription } = useSelector(s => s.authSlice);
  const subscriptionLevel = selectedSubscription?._metadata?.level;

  if (subscriptionLevel <= 1) availableTabs.push('partner');

  // component states ----------------------------------------------------------
  const [tab, setTab] = useTabParent(availableTabs, 'channel');
  const [showContactsModal, setShowContactsModal] = useState(false);

  const [selectedEntityCustomer, setSelectedEntityCustomer] = useState(null);
  const [selectedEntityPartner, setSelectedEntityPartner] = useState(null);
  const [entityTypeCustomer, setEntityTypeCustomer] = useState('company');
  const [entityTypePartner, setEntityTypePartner] = useState('company');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,

    // VERIFICAR SE HÁ NECESSIDADE DE TODAS ESSAS TABS
    activeTab: tab,
    selectedEntityCustomer,
    setSelectedEntityCustomer,
    selectedEntityPartner,
    setSelectedEntityPartner,
    entityTypeCustomer,
    setEntityTypeCustomer,
    entityTypePartner,
    setEntityTypePartner,
    showContactsModal,
    setShowContactsModal,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (document?.__customer?._id !== selectedEntityCustomer?._id) {
      setSelectedEntityCustomer(document?.__customer || null);
    } else if (document?.__partner?._id !== selectedEntityPartner?._id) {
      setSelectedEntityPartner(document?.__partner || null);
    }
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    // dataSet to post/put
    const dataSet = { ...body };

    // color
    if (body?.color) {
      // Convert the color object into a string (hex or rgba) depending on the schema expectation
      if (typeof body.color === 'object' && body.color.metaColor) {
        dataSet.color = `rgba(${body.color.metaColor.r}, ${body.color.metaColor.g}, ${body.color.metaColor.b}, ${body.color.metaColor.a})`;
      }
    }

    // ENTITY -> create or update entity
    if (body?.__customer?.name) {
      const entityCustomer = await entityService.persistEntity(
        body,
        '__customer',
        selectedEntityCustomer,
        entityTypeCustomer,
        'customer',
        document,
      );

      dataSet.customerId = entityCustomer?._id;
    } else if (body?.__partner?.name) {
      const entityPartner = await entityService.persistEntity(
        body,
        '__partner',
        selectedEntityPartner,
        entityTypePartner,
        'partner',
        document,
      );

      dataSet.partnerId = entityPartner?._id;
    }

    return dataSet;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="parameters-form"
      document={document}
      initialValues={{ service: 'veertical' }}
      requiredFields={['channel', 'type', 'model', 'color', 'title']}
      setTab={setTab}
      onSave={onSave}
      showSubmitButton={true}
    >
      <Tabs
        type="line"
        tabPosition="top"
        activeKey={tab}
        rootClassName="tab-in-form"
        items={tabItems.filter(x => !x.hide)}
        onTabClick={onTabClick}
      />
    </JarvislyFormProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('device'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <IotDevicesDeviceTab {...childProps} />
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('configuration'),
        key: 'configuration',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab={tab}>
            <IotDevicesConfigurationTab {...childProps} />
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('installation'),
        key: 'installation',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab={tab}>
            <InstallationTab {...childProps} />
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('customer'),
        key: 'customer',
        hide: false,
        disabled: mode === 'add',
        // children: <h1>CUSTOMER</h1>
        // children:             <IotDevicesCustomerTab {...childProps} activeTab={tab} />

        children: (
          <JarvislyTabProvider tab={'customer'}>
            <IotDevicesCustomerTab {...childProps} />
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('sales_representative'),
        key: 'partner',
        hide: subscriptionLevel > 1,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab={tab}>
            <PartnerTab {...childProps} />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
};

export default IotDevicesForm;
