import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { translateX } from 'utils/helpers';
import {
  Button,
  Col,
  Descriptions,
  Form,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import financialService from 'services/financialService';
import { useDispatch } from 'react-redux';
import { rdxSetShowPaymentModal } from '../../../../../store/slices/authSlice';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { setShowModal, showModal, updating, selectedSubscription } = props;

  // local variables I ----------------------------------------------------------
  const dispatch = useDispatch();

  // component states -----------------------------------------------------------
  const [selectedPlan, setSelectedPlan] = useState('pp');
  const [planInfo, setPlanInfo] = useState({
    message: 'ncmp_plan',
    description: 'ncmp_plan_ended_warning',
  });
  const [selectedPackage, setSelectedPackage] = useState('100');

  // local variables II ---------------------------------------------------------
  const priceTables = selectedSubscription?.creditsJarvis?.priceTable || {};
  const c100 =
    priceTables[selectedPlan]?.prices.find(i => i.credits === 100) || {};
  const c500 =
    priceTables[selectedPlan]?.prices.find(i => i.credits === 500) || {};
  const c1000 =
    priceTables[selectedPlan]?.prices.find(i => i.credits === 1000) || {};
  const c5000 =
    priceTables[selectedPlan]?.prices.find(i => i.credits === 5000) || {};
  const c10000 =
    priceTables[selectedPlan]?.prices.find(i => i.credits === 10000) || {};

  const planOptions = buildPlanOptions();
  const packagesOptions = buildPackagesOptions();
  const paymentSummary = buildPaymentSummary();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    switch (selectedPlan) {
      case 'pp':
        setPlanInfo({
          message: 'pp_plan',
          description: 'pp_plan_ended_warning',
        });

        break;

      case 'cmp':
        setPlanInfo({
          message: 'cmp_plan',
          description: 'cmp_plan_ended_warning',
        });
        break;

      case 'ncmp':
        setPlanInfo({
          message: 'ncmp_plan',
          description: 'ncmp_plan_ended_warning',
        });
        break;
    }
  }, [selectedPlan]);

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowModal(false);
  };

  const onBuy = () => {
    console.log('formulário confirmado!');

    let description, totalToPay;

    switch (selectedPackage) {
      case '100':
        description = translateX('package_100_credits');
        totalToPay = c100.price;
        break;
      case '500':
        description = translateX('package_500_credits');
        totalToPay = c500.price;
        break;
      case '1000':
        description = translateX('package_1000_credits');
        totalToPay = c1000.price;
        break;
      case '5000':
        description = translateX('package_5000_credits');
        totalToPay = c5000.price;
        break;
      case '10000':
        description = translateX('package_10000_credits');
        totalToPay = c10000.price;
        break;

      default:
        description = translateX('n_a');
        totalToPay = 0;
        break;
    }

    dispatch(
      rdxSetShowPaymentModal({
        showModal: true,
        paymentDescription: description,
        totalToPay,
      }),
    );
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18"
        title={
          <>
            <ShoppingCartOutlined />
            <span className="ml-2">{translateX('buy_jarvis_credit')}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        getContainer={false}
        onCancel={onClose}
        onOk={onBuy}
        okText={translateX('pay')}
        okButtonProps={{
          icon: <ShoppingCartOutlined />,
          style: { width: 100 },
        }}
        cancelButtonProps={{ style: { width: 100 } }}
      >
        <Form layout="vertical" onFinish={onBuy}>
          <Row gutter={ROW_GUTTER} justify="center" className="mb-5 mt-5">
            {/*
              <Col span={24}>
                <Form.Item label={translateX('choose_a_plan')}>
                  <Segmented
                    size="large"
                    options={planOptions}
                    value={selectedPlan}
                    onChange={setSelectedPlan}
                    block
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Alert
                  message={translateX(planInfo.message)}
                  description={translateX(planInfo.description)}
                  type="info"
                  style={{ marginBottom: 24 }}
                  showIcon
                />
              </Col>
              */}

            <Col span={24}>
              <Form.Item label={translateX('choose_a_credit_package')}>
                <Select
                  value={selectedPackage}
                  onChange={setSelectedPackage}
                  // placeholder="Selecione uma opção"
                  options={packagesOptions}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Descriptions
                size="small"
                items={paymentSummary}
                bordered
                column={1}
              />
            </Col>

            {/*<Col span={24} className="text-center mt-4">*/}
            {/*  <Button type="primary" style={{ width: 160 }}>*/}
            {/*    {translateX('buy')}*/}
            {/*  </Button>*/}
            {/*</Col>*/}
          </Row>
        </Form>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildPlanOptions() {
    return [
      {
        label: translateX('pp'),
        value: 'pp',
        disabled: false,
      },
      {
        label: translateX('cmp'),
        value: 'cmp',
        disabled: false,
      },
      {
        label: translateX('ncmp'),
        value: 'ncmp',
        disabled: false,
      },
    ];
  }

  function buildPackagesOptions() {
    return [
      {
        value: '100',
        label: `${translateX('package_100_credits')} - ${financialService.formatMoney(
          c100.fullPrice,
          2,
          'brl',
          true,
        )} (${translateX('no_discount')})`,
      },
      {
        value: '500',
        label: `${translateX('package_500_credits')} - ${financialService.formatMoney(
          c500.fullPrice,
          2,
          'brl',
          true,
        )} (+${translateX('x_discount', ['%DISCOUNT%'], ['10'])})`,
      },
      {
        value: '1000',
        label: `${translateX('package_1000_credits')} - ${financialService.formatMoney(
          c1000.fullPrice,
          2,
          'brl',
          true,
        )} (+${translateX('x_discount', ['%DISCOUNT%'], ['20'])})`,
      },
      {
        value: '5000',
        label: `${translateX('package_5000_credits')} - ${financialService.formatMoney(
          c5000.fullPrice,
          2,
          'brl',
          true,
        )} (+${translateX('x_discount', ['%DISCOUNT%'], ['30'])})`,
      },
      {
        value: '10000',
        label: `${translateX('package_10000_credits')} - ${financialService.formatMoney(
          c10000.fullPrice,
          2,
          'brl',
          true,
        )} (+${translateX('x_discount', ['%DISCOUNT%'], ['40'])})`,
      },
    ];
  }

  function buildPaymentSummary() {
    let pkg;
    switch (selectedPackage) {
      case '100':
        pkg = c100;
        break;

      case '500':
        pkg = c500;
        break;

      case '1000':
        pkg = c1000;
        break;

      case '5000':
        pkg = c5000;
        break;

      case '10000':
        pkg = c10000;
        break;

      default:
        pkg = {};
    }

    const summary = [
      {
        key: 'credits',
        label: translateX('credits'),
        children: (
          <div className="font-weight-bold text-right">{pkg.credits}</div>
        ),
      },
    ];

    const infoFullPrice = {
      key: 'fullPrice',
      label: translateX('price'),
      children: (
        <div className="text-right">
          {financialService.formatMoney(pkg.fullPrice, 2, 'brl', true)}
        </div>
      ),
    };

    const infoDiscount = {
      key: 'discount',
      label: translateX('discount'),
      children: (
        <div className="text-right">
          {financialService.formatNumber(pkg.discount, 0, 'brl', true)}%
        </div>
      ),
    };

    const infoTotalToPay = {
      key: 'totalToPay',
      label: translateX('total_to_pay'),
      children: (
        <div className="text-right">
          {financialService.formatMoney(pkg.price, 2, 'brl', true)}
        </div>
      ),
    };

    if (pkg.discount) {
      summary.push(infoFullPrice, infoDiscount, infoTotalToPay);
    } else {
      summary.push(infoTotalToPay);
    }

    return summary;
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyAddModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [showModal, setShowModal] = useState(false);
  const [updating, setUpdating] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
  }));

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------
  const modalProps = {
    ...props,
    showModal,
    setShowModal,
    updating,
  };

  // UI COMPONENT --------------------------------------------------------------
  return <ModalComponent {...modalProps} />;
});

export default JarvislyAddModal;
