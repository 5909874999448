import { getFromLocalStorage } from 'utils/helpers';
import ProductsList from './List/List';
import ProductsForm from './Form/Form';
import { ProductsFormHeaderTitle } from './Components';
import { DefaultFormHeaderColumn1 } from 'components/jarvisly-module/Form/FormHeader';
import ProductsParametersModal from './parameters/ParametersModal';
import DataImportModal from './DataImport/DataImportModal';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MODULE_ID = 'applekkusProducts';
const MODULE_API = '/v1/products';

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const PRODUCTS_MODULE = {
  id: MODULE_ID,
  noBlockScreenWithAppNotConfigured: false,
  api: MODULE_API,
  urlCustomForm: null,
  defaultUrlQueryString: null,

  components: {
    // list
    dashboard: null,
    list: props => <ProductsList {...props} />,
    grid: null,
    kanban: null,
    filter: null,

    // form
    form: props => <ProductsForm {...props} />,
    formHeaderTitle: props => <ProductsFormHeaderTitle {...props} />,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: null,
    formHeaderColumn3: null,
    formHeaderTags: null,
    formHeaderCustomIcons: null,

    // module parameters
    parameters: props => <ProductsParametersModal {...props} />,

    // module data import
    dataImport: props => <DataImportModal {...props} />,

    // data preview
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
    // canEditDocument
    // decorateParameters,
  },

  initialRequest: {
    folder: 'documents',

    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},

    filterOptions: {},

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },

    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },

    sorterOptions: ['_metadata.audit.updatedAt'],

    search: {},

    textSearch: {},
  },

  listState: {
    override: false,
    hideBreadcrumb: false,

    showParameters: true,
    showDataImport: true,

    initTab: null,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
    fieldsForRegex: [],
    textSearchInDrawer: true, // inform that the textSearch field is into Drawer Filter to mark the filter button as danger (red)
    refreshDataOfList: 0,
  },

  documentState: {
    showParameters: true,

    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    newDocumentTitle: 'new_product',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
    ],
    refreshParametersOnSave: true,
    indexes: [],

    refreshDataOfDocument: 0,
    mockDocument: {},
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default PRODUCTS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  // console.log('1 decorateDocument', doc?.price);
  // if (doc?.price) doc.price =  Number(doc.price).toFixed(2);
  // console.log('2 decorateDocument', Number(doc?.price));

  return doc;
}
