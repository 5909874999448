import {
  DeploymentUnitOutlined,
  EditOutlined,
  LineChartOutlined,
  TagsOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { MdOutlineLuggage } from 'react-icons/md';
import { BsCashCoin } from 'react-icons/bs';
import { TbFileInvoice, TbMoneybag } from 'react-icons/tb';
import { FiShoppingBag } from 'react-icons/fi';
import { TfiWorld } from 'react-icons/tfi';
import { RiDiscountPercentLine } from 'react-icons/ri';
import MultiQuotationsIndex from '../../modules/app-views/appmulti/quotations/List/ListIndex';

const APP_MULTI = {
  ID: 'appmulti',
  NAME: 'Multi Seguro Viagem',
  THEME_COLOR: 'CUSTOM_MULTI_APP',
  THEME: 'light',
  FINTECH_SERVICES: true,
  DOMAINS: ['sistema.multiseguroviagem.com.br'],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'MultiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: false,
  COPY_RIGHT: 'Multi Seguro Viagem',
  '//API_DEV_HOST': ['localhost', 'vps'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8001,
  API_PORT_PRODUCTION: 8001,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'vps.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  WHATSAPP_INTEGRATED: false,
  API_INTEGRATED: true,
  TOKEN: process.env.REACT_APP_APPMULTI_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/appmulti/logo-app.png',
  LOGO_APP_WHITE: '/apps/appmulti/logo-app-white.png',

  LOGO_APP_ICON: '/apps/appmulti/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/appmulti/logo-app-icon-white.png',

  LOGO: '/apps/appmulti/logo.png',
  LOGO_WHITE: '/apps/appmulti/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/appmulti/logo-app-side.png',

  TITLE: 'Multi Seguro Viagem',
  '//SLOGAN': 'Sistema de Gerenciamento de Vendas de Seguro Viagem.',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: false,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: false,
  },
  ENABLE_MARKETPLACE: false,
  SHOW_ALL_MENUS: true,
  MENU_NAVIGATION: [
    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      icon: EditOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'records_operators',
          path: '/app/records/appmulti-operators',
          title: 'operators',
          icon: TfiWorld,
          moduleId: 'appmultiOperators',
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'records_plans',
          path: '/app/records/appmulti-plans',
          title: 'plans',
          icon: MdOutlineLuggage,
          moduleId: 'appmultiPlans',
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'records_customers',
          path: '/app/records/customers',
          title: 'customers',
          icon: TeamOutlined,
          moduleId: 'applekkusCustomers',
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
      ],
    },

    {
      key: 'commercial',
      path: '/app/commercial',
      title: 'commercial',
      icon: TeamOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'commercial_leads',
          path: '/app/commercial/travel-insurance-leads',
          title: 'leads',
          icon: DeploymentUnitOutlined,
          moduleId: 'appmultiLeads',
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'commercial_quotations',
          path: '/app/commercial/travel-insurance-quotations',
          title: 'quotations',
          icon: TagsOutlined,
          moduleId: 'appmultiQuotations',
          component: MultiQuotationsIndex,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'commercial_orders',
          path: '/app/commercial/travel-insurance-orders',
          title: 'orders',
          icon: FiShoppingBag,
          moduleId: 'appmultiOrders',
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
      ],
    },

    {
      key: 'financial',
      path: '/app/financial',
      title: 'financial',
      icon: LineChartOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'financial_receivable',
          path: '/app/financial/receivables',
          title: 'charges',
          icon: BsCashCoin,
          moduleId: 'applekkusReceivables',
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'financial_payable',
          path: '/app/financial/payable',
          title: 'payments',
          icon: TbMoneybag,
          moduleId: null,
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'financial_invoices',
          path: '/app/financial/invoices',
          title: 'invoices',
          icon: TbFileInvoice,
          moduleId: null,
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'financial_coupon',
          path: '/app/financial/discount_coupons',
          title: 'discount_coupons',
          icon: RiDiscountPercentLine,
          moduleId: null,
          component: null,
          marketplace: false,
          subscriptionLevel: [0, 1],
        },
      ],
    },
  ],
  FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_APPMULTI_FIREBASE_CONFIG),
};

export default APP_MULTI;
