import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { setLocale, translateWord, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import Icon from 'components/util-components/Icon';
import { FaPlaneArrival, FaPlaneDeparture } from 'react-icons/fa';
import React from 'react';
import financialService from 'services/financialService';
import MULTI_ORDERS_MODULE from '../orders';
import { MultiOrdersStatusTag } from '../Components';

const MultiOrdersList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
    columnSearch,
    columnFilters,

    numberLineColumn,
    documentCodeColumn,
    lastUpdatedColumn,
    actionsButtonColumn,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const statusFilter = buildDocumentStatusFilter();
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
        expandable={{
          expandedRowRender: buildTableItemsColumns,
          defaultExpandedRowKeys: ['0'],
        }}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDocumentStatusFilter() {
    const options = [
      'waiting_for_payment',
      // 'late_payment',
      'waiting_for_voucher',
      'done',
      'cancelled',
      'cancelled_by_customer',
    ];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildTableColumns() {
    return [
      numberLineColumn(),
      documentCodeColumn('order'),

      {
        title: translateWord('customer'),
        dataIndex: '_indice',
        ...columnSearch('_indice', 'customers_or_travellers'),
        ...columnSorter('_indice'),
        // dataIndex: ['__buyer', 'fullName'],
        // ...columnSearch(['__buyer', 'fullName'], 'customers_or_travellers'),
        className: 'text-truncate',
        render: (_, record) => <span>{record?.__buyer?.fullName}</span>,
      },

      {
        title: translateWord('value'),
        dataIndex: 'value',
        className: 'text-truncate',
        align: 'right',
        render: (_, record) => (
          <span>
            {financialService.formatMoney(
              record?.totalInOrderDate?.totalToPay,
              2,
              'brl',
              true,
            )}
          </span>
        ),
      },

      {
        title: translateWord('status'),
        dataIndex: 'status',
        ...columnFilters('status', statusFilter),
        ...columnSorter('status'),
        // width: 100,
        render: (_, record) => <MultiOrdersStatusTag record={record} />,
      },

      /*
      {
        title: translateWord('status'),
        dataIndex: 'status',
        width: 100,
        render: (_, record) => (
          // const receivable = record?.__receivables?.[0];
          // return receivable ? (
          //   <ReceivablesStatusTag record={receivable} />
          // ) : (
          <MultiOrdersStatusTag record={record} />
        ),
        // );
      },
*/

      {
        title: translateWord('order_date'),
        dataIndex: '_metadata.audit.createdAt',
        ...columnSorter('_metadata.audit.createdAt'),
        className: 'text-truncate',
        width: 160,
        render: (_, record) => (
          <>
            {record?._metadata?.audit?.createdAt &&
              dayjs(record._metadata.audit.createdAt).format('L LT')}
          </>
        ),
      },

      lastUpdatedColumn(),
      actionsButtonColumn(),
    ].filter(x => !x.hidden);
  }

  function buildTableItemsColumns(record) {
    const dataItem = dataList.filter(i => i?._id === record?._id);

    const columns = [
      {
        title: translateX('code'),
        dataIndex: 'code',
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {record?.__plan?._metadata?.__documentCode}
          </span>
        ),
      },

      {
        title: translateWord('plan'),
        dataIndex: 'plan',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <>
              <div>{record?.__plan?.name}</div>
              <div className="text-muted">{record?.__operator?.name}</div>
            </>
          );
        },
      },

      {
        title: setLocale('destiny'),
        dataIndex: 'arrival',
        render: (_, record) => <span>{translateX(record?.destiny)}</span>,
      },

      {
        title: setLocale('dates'),
        dataIndex: 'date',
        render: (_, record) => (
          <>
            <div style={{ display: 'flex' }}>
              <div>
                {record?.departure && dayjs(record.departure).format('L')}
              </div>
              <div style={{ marginTop: 2 }}>
                <Icon type={FaPlaneDeparture} className="ml-2" />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div>{record?.arrival && dayjs(record.arrival).format('L')}</div>
              <div style={{ marginTop: 2 }}>
                <Icon type={FaPlaneArrival} className="ml-2" />
              </div>
            </div>
          </>
        ),
      },

      {
        title: setLocale('passengers'),
        dataIndex: 'passengers',
        render: (_, record) =>
          record?.travellers.map(t => (
            <div key={t._id}>
              {t.name}, {t.ageInTravelDate}
            </div>
          )),
        // render: (_, record) => (
        //   <>
        //
        //     <div>Fulano, 78</div>
        //     <div>Ciclano, 22</div>
        //   </>
        // ),
      },
    ].filter(x => !x.hidden);

    return (
      <Table
        rowKey="_id"
        size="small"
        className="no-table-overflow"
        columns={columns}
        dataSource={dataItem || []}
        pagination={false}
      />
    );
  }
};

export default MultiOrdersList;
