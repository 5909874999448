import { useContext, useEffect } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import LiquidFillChart from 'components/jarvisly-components/dashboards/LiquidFillChart/LiquidFillChart';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React, { useState } from 'react';
import Loading from 'components/jarvisly-components/others-old/Loading';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import ChartWidget from 'components/shared-components/ChartWidget';
import { useSelector } from 'react-redux';
import { translateX } from 'utils/helpers';
import { IoAnalyticsSharp } from 'react-icons/io5';
import { Col, Row, Segmented, Tooltip } from 'antd';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
//*****************************************************************************
const IotDevicesDashboardWaterLevel = props => {
  // props deconstruction ------------------------------------------------------
  const { dataDashboard, isWorking, isDisabled } = props;

  // local variables I -----------------------------------------------------------
  const t = $buildTranslations();

  // component states ----------------------------------------------------------
  const [viewMode, setViewMode] = useState('clock'); // Estado para controlar o modo de visualização
  const [series, setSeries] = useState([]);
  const [colors, setColors] = useState([]);
  const [categories, setCategories] = useState([]);
  const { direction } = useSelector(state => state.theme);
  const [isFlipped, setIsFlipped] = useState({});

  // local variables II --------------------------------------------------------
  const Last24hChartOptions = $buildLast24hChartOptions(colors);
  const segmentViewsOptions = $buildSegmentViewsOptions();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (dataDashboard?.water_level_sensor?.length) {
      const sensors = dataDashboard.water_level_sensor;

      const extractedSeries = sensors.map(sensor => ({
        name: sensor.title,
        data: sensor.last24Utc.map(entry =>
          parseFloat(entry.percentage.toFixed(2)),
        ),
      }));

      const extractedColors = sensors.map(sensor => sensor.color);
      const extractedCategories =
        sensors[0]?.last24Utc.map(entry => entry.label) || [];

      setSeries(extractedSeries);
      setColors(extractedColors);
      setCategories(extractedCategories);
    }
  }, [dataDashboard]);

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Loading
        isFetching={isWorking}
        data={dataDashboard}
        showNoDataIcon
        marginTop={200}
      />

      {dataDashboard?.water_level_sensor?.length > 4 ? (
        <Row gutter={ROW_GUTTER} style={{ flexDirection: 'column' }}>
          <Col style={{ marginBottom: '20px' }}>
            <Tooltip placement="top">
              <Segmented
                className="ml-2"
                disabled={isDisabled}
                onChange={setViewMode}
                value={viewMode}
                options={segmentViewsOptions}
              />
            </Tooltip>
          </Col>
        </Row>
      ) : null}

      {dataDashboard?.water_level_sensor?.length <= 4 ||
      viewMode === 'grafic' ? (
        <Row gutter={ROW_GUTTER}>
          <Col xs={24}>
            <ChartWidget
              title={t.water_level_last_24h}
              series={series}
              xAxis={categories}
              height="300px"
              direction={direction}
              customOptions={Last24hChartOptions}
            />
          </Col>
        </Row>
      ) : null}

      {viewMode === 'clock' ||
      dataDashboard?.water_level_sensor?.length <= 4 ? (
        <Row gutter={ROW_GUTTER}>
          {dataDashboard?.water_level_sensor?.map(w => (
            <Col key={w._id} xs={24} sm={12} md={6}>
              <LiquidFillChart
                record={w}
                onClick={e => {
                  e.preventDefault();
                  // setIsFlipped({ ...isFlipped, [w._id]: true });
                }}
              />
            </Col>
          ))}
        </Row>
      ) : null}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function $buildSegmentViewsOptions() {
    return [
      { value: 'clock', icon: <ClockCircleOutlined /> },
      { value: 'grafic', icon: <IoAnalyticsSharp /> },
    ];
  }

  function $buildTranslations() {
    return {
      water_level_last_24h: translateX('water_level_last_24h'),
    };
  }

  function $buildLast24hChartOptions(colors) {
    return {
      legend: {
        show: true,
        markers: {
          fillColors: colors,
        },
      },
      series: [],
      chart: {
        toolbar: {
          show: false,
        },
        type: 'area',
        height: 300,
        events: {
          dataPointMouseEnter: function (event) {
            event.path[0].style.cursor = 'pointer';
          },
        },
      },
      fill: {
        type: 'gradient', // 'solid' / 'pattern' / 'image'

        gradient: {
          enabled: true,
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.3,
          stops: [0, 100],
          type: 'vertical',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors,
        curve: 'smooth',
        width: 3,
      },
      xaxis: {
        range: 23,
        labels: {
          formatter: function (value) {
            return value + 'h';
          },
        },
        categories: [],
      },
      yaxis: {
        tickAmount: 10,
        min: 0,
        max: 110,
        labels: {
          formatter: function (value) {
            return value + '%';
          },
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    };
  }
};

export default IotDevicesDashboardWaterLevel;
