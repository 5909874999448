import React, { useContext, useEffect } from 'react';
import { Badge, Card, Col, Flex, Row } from 'antd';
import {
  JarvislyFormContext,
  JarvislyNumber,
  JarvislySelect,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { translateX } from 'utils/helpers';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import { sort, normalize } from 'utils/helpers';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiPlansParametersIndex = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    selectedOperator,
    setSelectedOperator,
    document,
    selectedPlanoId,
    setSelectedPlanoId,
  } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const so1 = form?.getFieldValue('selectedOperator');
    const so2 = document?.__operator;
    const so = so1?._id ? so1 : so2?._id ? so2 : undefined;

    if (!selectedOperator && so) setSelectedOperator(so);
    if (document?._integrations?.planoId) {
      setSelectedPlanoId(document?._integrations?.planoId);
    }
  }, [form, document]); // eslint-disable-line react-hooks/exhaustive-deps

  // local variables II --------------------------------------------------------
  const operatorApiPlan = $buildOperatorApiPlanOptions();

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* ************************************************************
        PROFITS
        ************************************************************ */}
      <Card title={translateX('values')}>
        <Row gutter={ROW_GUTTER}>
          {/* MARKDOWN */}
          <Col span={12}>
            <JarvislyNumber
              name="markdown"
              decimalSeparator=","
              addonBefore={null}
              addonAfter="%"
              suffixStyle={{ width: 60 }}
              min={0}
              max={100}
            />
          </Col>
          {/* MARKDOWN */}

          {/* DISCOUNT */}
          <Col span={12}>
            <JarvislyNumber
              name="discount"
              decimalSeparator=","
              addonBefore={null}
              addonAfter="%"
              suffixStyle={{ width: 60 }}
              min={0}
              max={100}
            />
          </Col>
          {/* DISCOUNT */}
        </Row>
      </Card>

      {/* ************************************************************
        INTEGRATION
        ************************************************************ */}
      <Card
        title={
          <Row justify="space-between" align="start">
            <div style={{ fontSize: 17 }}>
              {translateX('webservice_integration')}
            </div>

            {/*            <JarvislySwitch
              name="integrated"
              className="form-item-no-margin"
              x-no-label
            />*/}
          </Row>
        }
      >
        <Row gutter={ROW_GUTTER}>
          {/* API PLAN INTEGRATION */}
          <Col xs={24}>
            <JarvislySelect
              name={['_integrations', 'planoId']}
              label="operator_api_plan"
              // placeholder="Please select store"
              optionLabelProp="selected" // Exibe o valor no campo após a seleção
              options={operatorApiPlan} // Passa as opções no novo formato
              style={{ width: '100%' }}
              onSelect={setSelectedPlanoId}
              onClear={() => setSelectedPlanoId(null)}
            />
          </Col>
          {/* API PLAN INTEGRATION */}
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function $buildOperatorApiPlanOptions() {
    const plans = selectedOperator?._integrations?.operatorPlans || [];

    return plans
      ?.filter(p => p.planoId)
      ?.map(p => {
        return {
          value: p.planoId,
          text: normalize(
            `${p?.planoNome} | ${p.planoId} | ${p?.planoCodTar}`,
            'lower',
          ),
          selected: (
            <Flex justify="space-between" align="center">
              <span style={{ height: 46 }}>
                <Badge
                  dot
                  status="processing"
                  style={{
                    marginRight: 8,
                    // color: p?.foreColor,
                    // backgroundColor: p?.backColor,
                    marginTop: 4,
                  }}
                />
                <span className="text-uppercase font-weight-bold">
                  {p?.planoNome}
                </span>
              </span>

              <span className="text-muted text-uppercase ml-3">
                ID: {p.planoId} CÓDIGO TARIFA: {p?.planoCodTar}
              </span>
            </Flex>
          ),
          label: (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className="text-uppercase font-weight-bold">
                  <Badge
                    dot
                    status={
                      selectedPlanoId === p.planoId ? 'processing' : 'default'
                    }
                    style={{
                      marginRight: 8,
                      // color: pipeline?.foreColor,
                      // backgroundColor: pipeline?.backColor,
                      marginTop: 4,
                    }}
                  />

                  {p?.planoNome?.toUpperCase()}
                </span>
              </div>

              <div>
                <span className="text-muted text-uppercase">
                  ID: {p.planoId}
                </span>
                <span className="mr-2 ml-2 text-muted text-uppercase">|</span>
                <span className="text-muted text-uppercase">
                  CÓDIGO TARIFA: {p?.planoCodTar}
                </span>
              </div>
            </div>
          ),
          disabled: false,
        };
      })
      .sort((a, b) => sort(a, b, 'planoId'));
  }
};

export default MultiPlansParametersIndex;
