import { Button, Col, Input, Row, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  clone,
  getObjectByField,
  translateWord,
  translateX,
} from 'utils/helpers';
import { NumericFormat } from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModuleContext } from 'components/jarvisly-module/ModuleContext';
import ListPreview from './ListPreview';
import ListFilter from './ListFilter';
import appService from 'services/appService';
import { buildUrlQueryString } from '../module-methods';
import dayjs from 'dayjs';
import NoRouteSet from '../../jarvisly-components/others-old/NoRouteSet';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
let loading = false;

// COMPONENT *******************************************************************
// *****************************************************************************

const ListIndex = props => {
  // providers context ---------------------------------------------------------
  const {
    refreshList,
    refreshDataOfList,
    refreshDashboard,
    dataKanban,
    dataList,
    dataGrid,
    dataDashboard,
    isWorking,
    pagination,
    searchText,
    sortOrder,
    setSearchText,
    showFilter,
    setShowFilter,
    subscriptionId,
    setIsWorking,
    showDataImportModal,
    setShowDataImportModal,
    showParametersModal,
    setShowParametersModal,
  } = useModuleContext();

  // props deconstruction ------------------------------------------------------

  // local variables -----------------------------------------------------------
  const navigate = useNavigate();
  const searchInput = useRef(null);
  const location = useLocation();

  const { listState, components, apiRequest, apiResponse, selectedModule } =
    useSelector(s => s.moduleSlice);

  // const viewMode = listState?.viewMode || 'list'
  const defaultPageSize = apiResponse?.summary?.pagination?.pageSize;

  // component states ----------------------------------------------------------
  const [selectedItem, setSelectedItem] = useState();
  const [showPreviewDrawer, setShowPreviewDrawer] = useState(false);
  const [buildRowSelection] = useState(false);
  const [tableKey, setTableKey] = useState(0);
  // const [searchVisible] = useState({});
  // const [initSearch, setInitSearch] = useState(false);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    appService.initRoute({
      moduleType: 'list',
      showPageHeader: true,
      showBreadcrumb: true,
      disableSubscriptionCombo: false,
    });

    // setSelectedModule(location.pathname);

    if (
      subscriptionId &&
      selectedModule?.url === location?.pathname &&
      !loading
    ) {
      loading = true;

      const qs = location.state?.qsObj || '';

      if (qs === 'noRefresh' && dataList?.length > 0) {
        const qsStr = buildUrlQueryString(apiRequest.currentQs);
        navigate(`?${qsStr}`);
        return;
      }

      (async () => {
        await refreshList(qs || 'url');
        loading = false;
      })();
      if (location?.state?.qsObj) delete location.state.qsObj;
    }
  }, [selectedModule?.url, subscriptionId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!components?.dashboard) return;

    let isMounted = true;
    const intervalId = setInterval(() => {
      (async () => {
        if (isMounted) await refreshDashboard();
      })();
    }, 60000); // Atualizando a cada 1 minuto

    // Função de limpeza que cancela o setInterval quando o componente é desmontado
    return () => {
      clearInterval(intervalId);
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const refreshInterval = listState?.refreshDataOfList;

    if (isNaN(refreshInterval) || refreshInterval < 10000) return;

    let isMounted = true;
    const intervalId = setInterval(() => {
      (async () => {
        if (isMounted) await refreshDataOfList('url');
      })();
    }, refreshInterval); // Atualizando a cada 1 minuto

    // Função de limpeza que cancela o setInterval quando o componente é desmontado
    return () => {
      clearInterval(intervalId);
      isMounted = false;
    };
  }, [listState]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onClickOpen = record => {
    setIsWorking(true);
    navigate(`${selectedModule.url}/${record._id}`);
  };

  const onClickPreview = record => {
    setSelectedItem(record);
    setShowPreviewDrawer(true);
  };

  const onTableChanges = async (p, f, s, e) => {
    const tableOptions = clone(apiRequest?.currentQs || {});

    // pagination
    const pagination = p && {
      pageNumber: p?.current || 1,
      pageSize: p.pageSize || defaultPageSize,
    };
    if (pagination) {
      tableOptions.pagination = pagination;
      localStorage.setItem('pageSize', pagination?.pageSize);
    }

    // sorter
    let tableSort = {};

    if (s?.field && Array.isArray(s)) {
      s.map(i => {
        tableSort[i.field] =
          i?.order === 'ascend' ? 1 : i?.order === 'descend' ? -1 : null;
      });
    } else if (s?.field && typeof s === 'object') {
      tableSort[s.field] =
        s?.order === 'ascend' ? 1 : s?.order === 'descend' ? -1 : null;
    }
    tableOptions.sorter = tableSort || {};

    // filter (search)
    const search = {};
    f &&
      Object.entries(f).map(([k, v]) => {
        if (Array.isArray(v)) {
          if (v.length > 0) {
            const value = v.join(',');
            search[k] = !isNaN(value) ? Number(value) : value;
          }
        } else if (v) {
          search[k] = v;
        }

        return [k, v];
      });

    tableOptions.search = search;

    if (!e) {
      await refreshList();
    } else {
      await refreshList(tableOptions);
    }
  };

  const columnSorter = fieldName => {
    const idx =
      listState?.sorterOrderPrecedence?.findIndex(f => f === fieldName) || 0;

    // if (idx === -1) {
    //   // console.error(
    //   //   `missing sorterOptions parameter in the module.json (initialRequest property) for ${fieldName} field!`,
    //   // );
    //   return null;
    // }

    const precedenceOrder = idx + 1;

    return {
      sortOrder: sortOrder?.[fieldName] || null,
      sorter: {
        multiple: precedenceOrder,
      },
    };
  };

  const columnFilters = (dataIndex, filters) => {
    const filteredValue = searchText?.[dataIndex]
      ? searchText?.[dataIndex]?.split(',')
      : [];

    return {
      filters: filters,
      filteredValue: filteredValue,
    };
  };

  const resetTableSearch = async clearFilters => {
    if (clearFilters) {
      clearFilters();
    } else {
      setTableKey(p => p + 1);
      await onTableChanges();
    }

    setSearchText({});
    if (searchInput?.current?.input?.value)
      searchInput.current.input.value = '';
  };

  const handleSearch = (selectedKeys, confirm, fieldName) => {
    // setInitSearch(false);

    setSearchText({
      ...searchText,
      [fieldName]: selectedKeys[0],
    });

    confirm();
  };

  const columnSearch = (fieldName, placeholder) => {
    return {
      filteredValue: searchText?.[fieldName] ? [searchText?.[fieldName]] : null,

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        // clearFilters,
        // close,
      }) => {
        // if (
        //   !selectedKeys[0] &&
        //   searchText?.[fieldName] &&
        //   !searchVisible?.[fieldName]
        //   && initSearch
        // ) {
        //   setSelectedKeys([searchText[fieldName]]);
        // }

        return (
          <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={`${translateWord('search')} ${
                placeholder ? translateX(placeholder) : translateX(fieldName)
              }`}
              value={selectedKeys[0]}
              onChange={e =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, fieldName)
              }
              style={{ display: 'block' }}
            />
          </div>
        );
      },

      filterIcon: (
        filtered, // <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>
      ) => (
        <SearchOutlined
          style={{
            color: searchText[fieldName] || filtered ? '#1890ff' : undefined,
          }}
        />
      ),

      onFilter: (value, record) => getObjectByField(record, fieldName),

      filterDropdownProps: {
        onOpenChange: () => {
          setTimeout(() => searchInput.current?.select(), 100);
        },
      },
    };
  };

  const close = () => {
    setShowPreviewDrawer(false);
  };

  // local variables III -------------------------------------------------------

  const numberLineColumn = (label = 'line') => {
    return {
      hidden: !listState?.showNumberOfLine,
      title: translateWord(label),
      width: 80,
      render: (item, record, index) => {
        if (!pagination) return;

        const { current, pageSize } = pagination;

        return (
          <div className="text-left">
            {current * pageSize - pageSize + index + 1 || index}
          </div>
        );
      },
    };
  };

  const documentCodeColumn = (
    title = 'document',
    dataIndex = '_metadata.counter',
  ) => {
    return {
      title: translateX(title),
      dataIndex,
      ...columnSorter(dataIndex),
      ...columnSearch(dataIndex),
      width: 140,
      render: (_, record) => (
        <span className="text-uppercase text-muted">
          {record?._metadata?.__documentCode}
        </span>
      ),
    };
  };

  const actionsButtonColumn = (
    title = 'actions',
    showEdit = true,
    showView = false,
  ) => {
    return {
      title: translateX(title),
      align: 'center',
      width: 50,
      render: (_, record) => (
        <div className="text-right d-flex justify-content-end">
          {showEdit ? (
            <Tooltip title={translateX('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>
          ) : null}

          {showView ? (
            <Tooltip title={translateX('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>
          ) : null}
        </div>
      ),
    };
  };

  const lastUpdatedColumn = (
    label = 'last_update',
    dataIndex = '_metadata.audit.updatedAt',
  ) => {
    return {
      title: translateWord(label),
      dataIndex: dataIndex,
      ...columnSorter(dataIndex),
      className: 'text-truncate',
      width: 160,
      hidden: false,
      render: (_, record) => (
        <>
          {record?._metadata?.audit?.updatedAt &&
            // moment(record._metadata.audit.updatedAt).format('L LT')}
            dayjs(record._metadata.audit.updatedAt).fromNow()}
        </>
      ),
    };
  };

  const createdColumn = (
    label = 'created',
    dataIndex = '_metadata.audit.updatedAt',
  ) => {
    return {
      title: translateWord(label),
      dataIndex: dataIndex,
      ...columnSorter(dataIndex),
      className: 'text-truncate',
      width: 160,
      hidden: false,
      render: (_, record) =>
        record?._metadata?.audit?.createdAt ? (
          <span>{dayjs(record._metadata.audit.createdAt).fromNow()}</span>
        ) : null,
    };
  };
  // UI COMPONENTS -------------------------------------------------------------

  const childProps = {
    ...props,
    isWorking,
    pagination,
    buildRowSelection,
    dataList,
    dataKanban,
    dataGrid,
    dataDashboard,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    resetTableSearch,
    tableKey,
    listState,
    showNumberOfLine: listState?.showNumberOfLine,
    sortOrder,
    onClickOpen,
    onClickPreview,

    numberLineColumn,
    documentCodeColumn,
    actionsButtonColumn,
    createdColumn,
    lastUpdatedColumn,

    selectedModule,
    showDataImportModal,
    setShowDataImportModal,
    showParametersModal,
    setShowParametersModal,
  };

  const TableTitle = () => (
    <Row className="mt-2 m-2" justify="space-between" align={'bottom'}>
      <Col>
        {apiRequest?.currentQs?.folder === 'documents' && (
          <Tooltip placement="right" title={translateX('documents')}>
            <FolderOpenOutlined className="mr-2" />
            {translateX('documents')}
          </Tooltip>
        )}

        {apiRequest?.currentQs?.folder === 'recycle' && (
          <Tooltip placement="right" title={translateX('recycle')}>
            <DeleteOutlined className="mr-2" />
            {translateX('recycle')}
          </Tooltip>
        )}

        {apiRequest?.currentQs?.folder === 'filed' && (
          <Tooltip placement="right" title={translateX('archive')}>
            <FolderOutlined className="mr-2" />
            {translateX('archive')}
          </Tooltip>
        )}
      </Col>

      <Col>
        <Tooltip placement="left" title={translateX('viewing_documents')}>
          {apiResponse?.summary?.pagination?.pages > 1 && (
            <>
              <NumericFormat
                value={apiResponse?.summary?.pagination?.recordsOfFrom}
                thousandSeparator="."
                decimalSeparator=","
                displayType="text"
              ></NumericFormat>
              &nbsp;-&nbsp;
              <NumericFormat
                value={apiResponse?.summary?.pagination?.recordsOfTo}
                thousandSeparator="."
                decimalSeparator=","
                displayType="text"
              ></NumericFormat>
              &nbsp;{translateX('of')}&nbsp;
            </>
          )}
          <NumericFormat
            value={apiResponse?.summary?.pagination?.records || 0}
            thousandSeparator="."
            decimalSeparator=","
            displayType="text"
          ></NumericFormat>
          &nbsp;
        </Tooltip>
      </Col>
    </Row>
  );

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <>
      {/* FILTER RIGHT DRAWER */}
      <ListFilter
        showModal={showFilter}
        setShowModal={setShowFilter}
        apiRequest={apiRequest}
        // refreshData={refreshData}
      />
      {/* FILTER RIGHT DRAWER */}

      {/* PREVIEW LEFT DRAWER */}
      <ListPreview
        close={close}
        visible={showPreviewDrawer}
        record={selectedItem}

        // showDrawer={showPreviewDrawer}
        // setShowDrawer={setShowPreviewDrawer}
        // _module={_module}
        // rdxModuleSettings={rdxModuleSettings}
      />
      {/* PREVIEW LEFT DRAWER */}

      {/* WRAPPER PARAMETERS MODAL */}
      {selectedModule?.components?.parameters
        ? selectedModule.components.parameters(childProps)
        : null}
      {/* WRAPPER PARAMETERS MODAL */}

      {/* WRAPPER DATA IMPORT MODAL */}
      {selectedModule?.components?.dataImport
        ? selectedModule.components.dataImport(childProps)
        : null}
      {/* WRAPPER DATA IMPORT MODAL */}

      {listState?.viewMode === 'list' && !listState?.override ? (
        <TableTitle />
      ) : null}

      {listState?.viewMode === 'list' ? (
        <div className={!listState?.override ? 'table-with-border' : ''}>
          {components?.list ? components.list(childProps) : null}
        </div>
      ) : listState?.viewMode === 'cards' ? (
        <div>CARDS</div>
      ) : listState?.viewMode === 'kanban' ? (
        <div>{components?.kanban ? components.kanban(childProps) : null}</div>
      ) : listState?.viewMode === 'grid' ? (
        <div>{components?.grid ? components.grid(childProps) : null}</div>
      ) : listState?.viewMode === 'dashboard' ? (
        <div>
          {components?.dashboard ? components.dashboard(childProps) : null}
        </div>
      ) : null}

      {/*{!selectedModule ? <NoRouteSet /> : null}*/}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT ====================================================================
// ===========================================================================

export default ListIndex;
