import CustomersList from './List/List';
import CustomersForm from './Form/Form';
import {
  CustomersFormHeaderColumn2,
  CustomersFormHeaderColumn3,
  CustomersFormHeaderSubtitle,
} from 'modules/app-views/applekkus/customers/Components';
import { getFromLocalStorage } from 'utils/helpers';
import {
  DefaultFormHeaderColumn1,
  DefaultFormHeaderCustomIcons,
} from 'components/jarvisly-module/Form/FormHeader';
import { API_ENTITIES } from 'constants/ApiConstant';
import dayjs from 'dayjs';
import CustomersParametersModal from './Parameters/ParametersModal';
// import { FORCE_APP } from '../../../../configs/InitializeApp';
// import app from 'configs/ConfigIndex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MODULE_ID = 'applekkusCustomers';

/*
let dataProfile, dataType;

switch (app.ID) {
  case 'appmulti':
    dataProfile = ['customer', 'traveller'];
    dataType = ['person', 'company'];
    break;

  default:
    dataProfile = ['customer', 'contact'];
    dataType = ['company', 'person'];
}
*/

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************
const CUSTOMERS_MODULE = {
  id: MODULE_ID,
  noBlockScreenWithAppNotConfigured: false,
  api: API_ENTITIES,
  urlCustomForm: null,
  defaultUrlQueryString: null,

  components: {
    // list
    dashboard: null,
    list: props => <CustomersList {...props} />,
    grid: null,
    kanban: null,
    filter: null,

    // form
    form: (props, ref) => <CustomersForm {...props} ref={ref} />,
    formHeaderTitle: null,
    formHeaderSubtitle: props => <CustomersFormHeaderSubtitle {...props} />,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: props => <CustomersFormHeaderColumn2 {...props} />,
    formHeaderColumn3: props => <CustomersFormHeaderColumn3 {...props} />,
    formHeaderTags: null,
    formHeaderCustomIcons: props => (
      <DefaultFormHeaderCustomIcons {...props} showStatusIcon />
    ),

    // module parameters
    parameters: props => <CustomersParametersModal {...props} />,

    // module data import
    dataImport: null,

    // data preview
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
    // canEditDocument
    // decorateParameters,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {
      dataProfile: ['operator'],
      dataType: ['person', 'company'],
    },

    /*
        filter: {
          dataProfile: dataProfile,
          dataType: ['person', 'company'],
        },
    */

    /*
            filterOptions: {
              dataProfile: ['customer', 'associate', 'traveller'],
              dataType: ['person', 'company'],
            },*/

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },

    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    /*
        sorter: {
          '_metadata.audit.updatedAt': -1,
        },

        sorterOptions: [
          '_metadata.documentStatus',
          'name',
          '_metadata.audit.updatedAt',
          '_metadata.counter',
          'status',
        ],
    */

    /*
        search: {},

        textSearch: {},
    */
  },

  listState: {
    override: false,
    hideBreadcrumb: false,

    showParameters: false,
    showDataImport: false,

    initTab: null,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: false, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
    fieldsForRegex: [],
    textSearchInDrawer: true, // inform that the textSearch field is into Drawer Filter to mark the filter button as danger (red)
    refreshDataOfList: 0,

    // filter options
    // filterInitialize: {
    //   dataProfile: dataProfile,
    //   dataType: dataType,
    // },

    filterInitialize: {
      // dataProfile: ['customer'],
      // dataType: ['person', 'company'],
    },

    // sort options
    sorterInitialize: {
      '_metadata.audit.updatedAt': -1,
    },

    sorterOrderPrecedence: [
      '_metadata.counter',
      'name',
      'status',
      '_metadata.audit.updatedAt',
    ],
  },

  documentState: {
    showParameters: true,

    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    newDocumentTitle: 'new_customer',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: true,
    indexes: [],

    refreshDataOfDocument: 0,
    mockDocument: {
      name: 'Infodesk',
      cnpj: '02934311000153',
      fullName: 'Infodesk Technologies Ltda',
      phone: '19982320000',
      email: 'biruel@infodesk.com.br',
      zip: '13070173',
      address1: 'Avenida Marechal Rondon',
      number: '700',
      address2: 'Sala 307',
      neighborhood: 'Jardim Chapadão',
      __city: 'Campinas - SP',
      parameters: { __exchangeRate: 5.21 },
    },
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default CUSTOMERS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  if (doc?.birthdate) doc.birthdate = dayjs(doc.birthdate);

  return doc;
}

export function setCustomerDataTypeProfile(appId) {
  let dataProfile, dataType;

  switch (appId) {
    case 'appmulti':
      dataProfile = ['customer', 'traveller'];
      dataType = ['person', 'company'];
      break;

    default:
      dataProfile = ['customer', 'contact'];
      dataType = ['company', 'person'];
  }

  CUSTOMERS_MODULE.listState.filterInitialize = {
    dataProfile,
    dataType,
  };
}
