import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'antd';
import { MessageFilled, PlusOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { parsePhoneToDisplay, translateX } from 'utils/helpers';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { io } from 'socket.io-client';
import AddNewWppSession from '../NewSessionModal/AddNewWppSession';
import whatsappService from 'services/whatsappService';
import { useSelector } from 'react-redux';

import Meta from 'antd/es/card/Meta';
import appService from 'services/appService';
import TestWppModal from '../TestMessageModal/TestWppModal';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import app from 'configs/ConfigIndex';
import EditWppModal from '../EditSessionModal/EditWppModal';

// --- Status guide ---
// WAITING (Waiting to read qrcode)
// FAILED_SESSION (Did not read qrcode in time)
// WAITING_SESSION (After click reload qrcode, goes to waiting to generate a new session, here does not exist a session yet)
// SUCESS_SESSION (Sucess connection with the device)
// CONNECTED (The device is connected)
// UNPAIRED (The user has been disconnected of his device)

let interval;

// COMPONENT *******************************************************************
// *****************************************************************************
const AccountWhatsAppList = props => {
  // props deconstruction ------------------------------------------------------
  const { activeTab } = props;
  const { selectedSubscription } = useSelector(s => s?.authSlice);

  // variables -----------------------------------------------------------------

  // component states ----------------------------------------------------------
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [sessionNameMeta, setSessionNameMeta] = useState('');
  const [list, setList] = useState([]);
  const [showQrCode, setShowQrCode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showReloadButton, setShowReloadButton] = useState(false);
  const [ressetQrLoading, setRessetQrLoading] = useState(false);
  const [sessionStatus, setSessionStatus] = useState('STANDBY');
  const [showTestModal, setTestShowModal] = useState(false);
  const [currentSession, setCurrentSession] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');

  // local variables -----------------------------------------------------------

  const childProps = {
    ...props,
    socket,
    showModal,
    setShowModal,
    sessionNameMeta,
    setSessionNameMeta,
    showQrCode,
    setShowQrCode,
    qrCodeData,
    setQrCodeData,
    showReloadButton,
    setShowReloadButton,
    ressetQrLoading,
    setRessetQrLoading,
    sessionStatus,
    setSessionStatus,
    list,
    showTestModal,
    setTestShowModal,
    currentSession,
    setCurrentSession,
    showEditModal,
    setShowEditModal,
    currentId,
    setCurrentId,
    currentTitle,
    setCurrentTitle,
    currentDescription,
    setCurrentDescription,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!app.WHATSAPP_INTEGRATED || activeTab !== 'whatsapp') {
      return;
    }

    const newSocket = io('http://localhost:8077', {
      withCredentials: true,
      transports: ['websocket', 'polling'],
    });
    setSocket(newSocket);

    newSocket.on('connect', () => {
      setIsConnected(true);
    });

    newSocket.on('disconnect', () => {
      setIsConnected(false);
    });

    newSocket.on('current_status', status => {
      setSessionStatus(status);
    });

    newSocket.on('qrcode', data => {
      if (sessionNameMeta === data.name) {
        if (data && data.data) {
          setQrCodeData(`data:image/png;base64,${data.data}`);
          setShowQrCode(true);
          setRessetQrLoading(false);
          setShowReloadButton(false);
        } else {
          setShowQrCode(false);
        }
      }
    });

    return () => {
      newSocket.disconnect();
    };
  }, [activeTab, sessionNameMeta]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      await getWppList(selectedSubscription._id);
      if (socket) {
        socket.emit('list-sessions');
      }
    };

    if (app.WHATSAPP_INTEGRATED && activeTab === 'whatsapp') {
      fetchData().then();
      interval = setInterval(fetchData, 5000);
    } else {
      interval && clearInterval(interval);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [activeTab, selectedSubscription?._id, socket, showModal, showEditModal]);

  // methods -------------------------------------------------------------------

  const askDelete = async (_id, session) => {
    const options = {
      Content: 'remove_whatsapp_ask',
      okText: 'yes',
      cancelText: 'no',
      onOk: async () => {
        await deleteSession(_id, session);
      },
    };

    appService.modal('c', 'remove_whatsapp_title', options);
  };

  //CORS ERROR
  const deleteSession = async (_id, session) => {
    try {
      await whatsappService.deleteWhatsapp(_id);
      socket.emit('delete-instance', { name: session });
      setList(prevList => prevList.filter(item => item._id !== _id));
    } catch (error) {
      console.error('delete-instance error! FunnelIndex.jsx:deleteSession()');
    }
  };

  const getWppList = async id => {
    try {
      const response = await whatsappService.getWhatsAppList(id);
      setList(response[0]);
    } catch (error) {
      console.error('Error getting list! FunnelIndex.jsx:getWppList()');
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <AddNewWppSession {...childProps} />
      <TestWppModal {...childProps} />
      <EditWppModal {...childProps} />
      <Row justify="end">
        <Button
          type="primary"
          className="ml-2 mt-2"
          onClick={() => setShowModal(true)}
          disabled={!isConnected}
        >
          <PlusOutlined />
          <span>{translateX('new_session')}</span>
        </Button>
      </Row>

      <div className={`my-4 container-fluid`}>
        {list?.map(record => (
          <Row gutter={ROW_GUTTER} key={record._id}>
            {record?.status !== 'removed' && (
              <Col xs={24} md={12} xl={8}>
                <Card
                  title={record?.title}
                  className="strong-border"
                  actions={[
                    <div
                      key="delete"
                      onClick={() => askDelete(record?._id, record?.session)}
                      style={{
                        cursor: 'pointer',
                        display: isConnected ? 'block' : 'none',
                      }}
                    >
                      <BsTrashFill size={21} />
                    </div>,
                    <div
                      key="testchat"
                      onClick={() => {
                        setTestShowModal(true);
                        setCurrentSession(record?.session);
                      }}
                      style={{
                        cursor: 'pointer',
                        display: isConnected ? 'block' : 'none',
                      }}
                    >
                      <MessageFilled style={{ fontSize: '18px' }} />
                    </div>,
                    <div
                      key="edit"
                      onClick={() => {
                        setShowEditModal(true);
                        setCurrentId(record?._id);
                        setCurrentTitle(record?.title);
                        setCurrentDescription(record?.description);
                      }}
                      style={{
                        cursor: 'pointer',
                        display: isConnected ? 'block' : 'none',
                      }}
                    >
                      <BsPencilFill style={{ fontSize: '18px' }} />
                    </div>,
                  ]}
                >
                  <Meta
                    avatar={<WhatsAppOutlined />}
                    title={`${record?.mobileDialCode} ${parsePhoneToDisplay(
                      record?.mobile,
                    )}`}
                    description={record?.description}
                  />
                </Card>
              </Col>
            )}
          </Row>
        ))}
      </div>
    </>
  );
};

// EXPORT **********************************************************************
// *****************************************************************************

export default AccountWhatsAppList;
