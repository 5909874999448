import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import { Button, Row, Table, Tag, Tooltip } from 'antd';
import React from 'react';
import { toCapitalCase, translateX } from 'utils/helpers';
import {
  LockTwoTone,
  PlusOutlined,
  SafetyCertificateTwoTone,
  ShopOutlined,
  UnlockTwoTone,
  UserOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import RoundedAvatar from 'components/jarvisly-components/others-old/RoundedAvatar';
import ActionButton from 'antd/es/_util/ActionButton';
import { useSelector } from 'react-redux';

// COMPONENT *******************************************************************
// *****************************************************************************
const TeamTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  useTabChild('team', null, props);
  const { selectedSubscription } = useSelector(s => s?.authSlice);
  const organization = `${translateX('users_from')} ${selectedSubscription?.label?.toUpperCase()}`;
  const columns = buildColumns();

  console.log('selectedSubscription', selectedSubscription);

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <div className="mt-3">
      <Row justify="space-between" align="middle">
        <p className="font-weight-bold">{organization}</p>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="mb-3"
          // onClick={() => setShowUserModal(true)}
        >
          &nbsp;{translateX('new_user')}
        </Button>
      </Row>

      <Table
        // key={tableKey}
        rowKey="_id"
        size="small"
        // columns={tableColumns.filter(x => !x.hidden)}
        columns={columns}
        dataSource={selectedSubscription?.team || []}
        // loading={loading}
        pagination={false}
        // onChange={onTableChanges}
        // rowSelection={buildRowSelection}
        // className="with-checkbox"
        // resetTableSearch={resetTableSearch}
        // loading={isWorking}
        // expandable={{
        //   expandedRowRender: buildTableItemsColumns,
        //   defaultExpandedRowKeys: ['0'],
        // }}
      />
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildColumns() {
    return [
      {
        title: translateX('line_#'),
        width: 80,
        render: (item, record, index) => (
          <div className="text-left">{index + 1}</div>
        ),
      },

      {
        title: translateX('name'),
        dataIndex: 'name',
        render: (_, record) => (
          <RoundedAvatar user={record} showEmail showName />
        ),
      },

      {
        title: translateX('profile'),
        dataIndex: 'name',
        render: (_, record) => (
          <>
            {record?.profile === 'owner' && (
              <Tooltip title={translateX('owner')}>
                <SafetyCertificateTwoTone className="font-size-lg mr-2 font-weight-bold" />
              </Tooltip>
            )}

            {record?.profile === 'keyUser' && (
              <Tooltip title={translateX('key_user_title')}>
                <UnlockTwoTone className="font-size-lg mr-2 font-weight-bold" />
              </Tooltip>
            )}

            {record?.profile === 'guest' && (
              <Tooltip title={translateX('common_user_title')}>
                <LockTwoTone
                  twoToneColor="red"
                  className="font-size-lg mr-2 font-weight-bold"
                />
              </Tooltip>
            )}
          </>
        ),
      },

      {
        title: translateX('status'),
        dataIndex: 'status',
        render: (_, record) => (
          <span>
            {record.__status && (
              <Tooltip
                placement="top"
                title={translateStatusDescription(record)}
              >
                <Tag
                  className="text-capitalize"
                  style={{ margin: 4 }}
                  color={record.__statusColor}
                >
                  {translateX(record.__status)}
                </Tag>
              </Tooltip>
            )}
          </span>
        ),
      },

      {
        title: translateX('actions'),
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            {/* ACTIONS BUTTON */}
            <ActionButton
              {...props}
              record={record}
              // onRefreshTeam={onRefreshTeam}
            />
            {/* ACTIONS BUTTON */}
          </div>
        ),
      },
    ];

    return [
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('cpf_cnpj')}
          </span>
        ),
        dataIndex: 'cpfCnpj',
        key: 'cover.cpfCnpj',
        render: (_, record) => (
          <div>
            <Tooltip
              placement="left"
              title={
                record.type === 'person'
                  ? translateX('individual_person')
                  : translateX('legal_person')
              }
            >
              <span className="mr-2">
                {record.type === 'person' ? <UserOutlined /> : <ShopOutlined />}
              </span>
            </Tooltip>

            <a>{record?.cover?.cpfCnpj}</a>
          </div>
        ),
      },
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('name_trade_name')}
          </span>
        ),
        dataIndex: 'name',
        key: 'name',
        render: text => <span>{toCapitalCase(text)}</span>,
      },
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('mother_company_name')}
          </span>
        ),
        dataIndex: 'cover.parent',
        key: 'parent',
        render: (_, record) => (
          <span>{toCapitalCase(record?.cover?.parent)}</span>
        ),
      },
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('age_since')}
          </span>
        ),
        dataIndex: 'age',
        key: 'age',
        render: text => <span>{text} ano(s)</span>,
      },
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('location')}
          </span>
        ),
        dataIndex: 'cover.location',
        key: 'location',
        render: (_, record) => (
          <span>{toCapitalCase(record?.cover?.location)}</span>
        ),
      },
      {
        title: (
          <span className="text-uppercase text-muted">
            {translateX('last_consultation')}
          </span>
        ),
        dataIndex: 'consultedAt',
        key: 'consultedAt',
        align: 'left',
        render: (_, record) =>
          record?.consultedAt ? (
            <span>{dayjs(record.consultedAt).fromNow()}</span>
          ) : (
            'n/a'
          ),
      },
    ];
  }

  function translateStatusDescription(record) {
    if (!record?.__statusDescription || !record?.invitedAt) return '';

    const dateFrom = dayjs(record?.invitedAt).fromNow();
    const date = dayjs(record?.invitedAt).format('L LT');

    return translateX(
      record?.__statusDescription,
      ['%DATE_AGO%', '%DATE%'],
      [dateFrom, date],
    );
  }
};

export default TeamTab;
