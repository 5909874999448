import {
  AppstoreOutlined,
  EditOutlined,
  SisternodeOutlined,
} from '@ant-design/icons';
import { BiWater } from 'react-icons/bi';

const APP_SANASA = {
  ID: 'appsanasa',
  NAME: 'Sanasa IoT',
  THEME_COLOR: 'BLUE',
  THEME: 'light',
  HIDE_COMPANY_BANKS_TAB: true,
  FINTECH_SERVICES: false,
  DOMAINS: ['iot.sanasa.com.br', 'sanasa.jarvisly.com'],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'multiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: true,
  COPY_RIGHT: 'Sanasa IoT',
  '//API_DEV_HOST': ['localhost', 'vps', 'heroku'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8008,
  API_PORT_PRODUCTION: 8008,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'vps.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  API_HOST_DEV_HEROKU: 'appsanasa.herokuapp.com',
  WHATSAPP_INTEGRATED: false,
  TOKEN: process.env.REACT_APP_APPSANASA_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/appsanasa/logo-app.png',
  LOGO_APP_WHITE: '/apps/appsanasa/logo-app-white.png',

  LOGO_APP_ICON: '/apps/appsanasa/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/appsanasa/logo-app-icon-white.png',

  LOGO: '/apps/appsanasa/logo.png',
  LOGO_WHITE: '/apps/appsanasa/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/appsanasa/logo-app-side.png',

  TITLE: 'Sanasa IoT',
  '//SLOGAN': 'Condomínios Inteligentes',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: true,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: false,
  },
  ENABLE_MARKETPLACE: false,
  MODULES: ['accounts'],
  SHOW_ALL_MENUS: !!process.env?.REACT_APP_APPSANASA_SHOW_ALL_MENUS,
  MENU_NAVIGATION: [
    // ------------------
    // MONITORING
    // ------------------
    {
      key: 'monitoring',
      path: '/app/monitoring',
      title: 'monitoring',
      icon: AppstoreOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'monitoring_water_level',
          path: '/app/monitoring/water_level',
          title: 'water_level',
          icon: BiWater,
          subscriptionLevel: [0, 1],
        },
      ],
    },

    // ------------------
    // RECORDS
    // ------------------
    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      className: '',
      icon: EditOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'records_iot_devices',
          path: '/app/records/iot_devices',
          title: 'iot_devices',
          icon: SisternodeOutlined,
          subscriptionLevel: [0, 1],
        },
      ],
    },
  ],
  FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_APPSANASA_FIREBASE_CONFIG),
};

export default APP_SANASA;
