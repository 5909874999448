import { clone, onProduction, sort } from 'utils/helpers';
import store from '../store';
import {
  DEFAULT_HOME_MODULE,
  rdxSetModule,
  rdxSetShowMyStates,
} from 'store/slices/moduleSlice';
import {
  APPLICATIONS,
  baseTheme,
  FORCE_APP,
  MODULES,
  PROFILES,
} from './InitializeApp';
import relativeTime from 'dayjs/plugin/relativeTime'; // Importe o plugin para lidar com tempos relativos
import {
  DIR_LTR,
  NAV_TYPE_SIDE,
  NAV_TYPE_TILE,
  NAV_TYPE_TOP,
  SIDE_NAV_LIGHT,
  THEME_COLOR,
} from '../constants/ThemeConstant';
import jarvisly from './applications/jarvisly.json';
import countriesProfiles from 'assets/data/countriesProfiles.json';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import moduleService from '../components/jarvisly-module/ModuleService';
import ACCOUNTS_MODULE from '../modules/app-views/shared-modules/account/account';
import ListIndex from '../components/jarvisly-module/List/ListIndex';
import FormIndex from '../components/jarvisly-module/Form/FormIndex';

// ************************************************************************** //
// *************************** APP CONFIGURATION **************************** //
// ************************************************************************** //

// desativa online os aplicativos listados no array
const inMaintenance = []; // 'sindico', 'meucondondominio', 'fisiodata', 'muilti', etc...

// extend the plugin alternative for 'moment(dt).fromNow()'
dayjs.locale('pt-br'); // Defina o locale desejado
dayjs.extend(localeData); // Estende com os dados do locale
dayjs.extend(isBetween); // Adiciona suporte para comparações entre datas
dayjs.extend(localizedFormat); // Adicione o plugin de formatação localizada
dayjs.extend(relativeTime); // Adicione o plugin de tempo relativo
dayjs.extend(weekday);

const APP_PREFIX_PATH = '/app';
const AUTH_PREFIX_PATH = '/auth';
const REDIRECT_URL_KEY = 'redirect';

const app = getJarvislyApp(FORCE_APP);

app.IN_MAINTENANCE = inMaintenance.includes(app.ID);

// app.appModules = [];
// app.MODULES.map(m => app.appModules.push(MODULES[m]));

const initialNavType =
  app?.NAVIGATION?.TYPE === 'TILE'
    ? NAV_TYPE_TILE
    : (app?.NAVIGATION?.TYPE === 'TILE') === 'TOP'
      ? NAV_TYPE_TOP
      : NAV_TYPE_SIDE;

app.navCollapsed = false;
app.sideNavTheme = SIDE_NAV_LIGHT;
app.navType = initialNavType;
app.topNavColor = '#3e82f7';
app.headerNavColor = '';
app.mobileNav = false;
app.currentTheme = app.THEME || 'light';
app.direction = DIR_LTR;
app.blankLayout = false;
app.selectedRoute = null;
app.backgroundColor = app.currentTheme === 'dark' ? '#283142' : '#ffffff';
app.baseColor = THEME_COLOR[app.THEME_COLOR];

app.appRoutes = {};
app.appModules = [];
getRoutes(app.MENU_NAVIGATION);

function getRoutes(menuArr) {
  menuArr.map(m => {
    if (m.submenu?.length > 0) {
      getRoutes(m.submenu);
    } else {
      if (m.moduleId && MODULES[m.moduleId]) {
        MODULES[m.moduleId].url = m.path;
        MODULES[m.moduleId].title = m.title;
        MODULES[m.moduleId].icon = m.icon;

        app.appModules.push(MODULES[m.moduleId]);

        if (m?.component) {
          app.appRoutes[m.path] = m?.component;

          MODULES[m.moduleId].routes = [m.path];
        } else {
          app.appRoutes[m.path] = ListIndex;
          app.appRoutes[`${m.path}/:_id`] = FormIndex;

          MODULES[m.moduleId].routes = [m.path, `${m.path}/:_id`];
        }
      } else {
        // app.appRoutes[m.path] = 'fallback';
        app.appRoutes[m.path] = null;
      }
    }
  });
}

// app.APP = app;
app.JARVISLY_APP = jarvisly;

const locale = (localStorage.getItem('locale') || app.LOCALE).toLowerCase();
const selectedLanguage = countriesProfiles.find(l => l.langId === locale);

if (selectedLanguage) {
  // app.LOCALE = selectedLanguage?.langId || 'pt';
  app.locale = selectedLanguage?.langId || 'pt';
} else {
  app.locale = app.LOCALE || 'pt';
}

app.APP_NAME = app.NAME;
app.APP_PREFIX_PATH = APP_PREFIX_PATH;
app.AUTH_PREFIX_PATH = AUTH_PREFIX_PATH;
app.REDIRECT_URL_KEY = REDIRECT_URL_KEY;

app.AUTHENTICATED_ENTRY =
  initialNavType === 'TILE'
    ? // ? `${APP_PREFIX_PATH}/home`
      // : `${APP_PREFIX_PATH}/dashboards/default`
      `${APP_PREFIX_PATH}/home`
    : `${APP_PREFIX_PATH}/home/welcome`;

app.UNAUTHENTICATED_ENTRY = '/signing';

export default app;

// ************************************************************************** //
// internal functions
// ************************************************************************** //

function getJarvislyApp(forcedApp) {
  const url = new URL(window.location.href);
  const domain = url.hostname; // return subdomain + domain

  if (!onProduction() && forcedApp) return $decorateApp(forcedApp);

  for (const app of APPLICATIONS) {
    if (app.DOMAINS.includes(domain)) return $decorateApp(app);
  }

  console.error(`*** NO JARVISLY APPLICATION DEFINED ***`);

  return {
    DEV_MODE: true,
    LOCALE: 'pt',
    PROFILE: PROFILES.singleCompany,
  };

  // decorate the application configurationTab
  function $decorateApp(app) {
    app.DEV_MODE = !onProduction();
    app.BASE_THEME = baseTheme;

    // build profile
    if (typeof app.PROFILE === 'string') {
      if (!PROFILES[app.PROFILE]) {
        console.error(
          `*** UNKNOWN PROFILE '${app.PROFILE}' IN APPLICATION .JSON CONFIGURATION FILE ***`,
        );
      }
      app.PROFILE = PROFILES[app.PROFILE];
    }

    return app;
  }
}

// ************************************************************************** //
// exports functions
// ************************************************************************** //

export const checkUserGranted = (level, moduleName, subscription) => {
  if (!moduleName) return true;

  return subscription?.granted[moduleName];
};

export const isActiveModule = mpModule => {
  // -4 - pagamento vencido (exibe modulo normalmente/info: aguardando pagamento, não perca o acesso! | 10 DIAS, apos 10 dias, status=0)
  // -3 - venceu demo e se encontra no carrinho de compras
  // -2 - demo vencido
  // -----
  //  0 - nao contratado (demo avaliado ou não)
  // -----
  //  1 - Adicionado no carrinho de compras
  //  2 - Em demo (venceu: status = -2)
  //  3 - Em demonstração e adicionado no carrinho de compras (venceu: status = -3)
  //  4 - Ativo (pago, dentro do período vigente)

  const isActiveAccount =
    !mpModule || [-4, -3, -2, 2, 3, 4].includes(mpModule?.status);

  const inDemoPeriod = !mpModule || mpModule?.__leftDemoDays > 0;
  const isPaid = true; // VERIFICAR SE ESTÁ PAGO CASO NAO SEJA MAIS DEMO!

  return isActiveAccount && inDemoPeriod && isPaid;
};

export const isBlockedModule = mpModule => {
  // acessa o módulo, mas fica travado com informação de DEMO VENCIDA
  return [-3, -2].includes(mpModule?.status); // demo expired
};

export const getActiveModules = subscription => {
  // const nativeModules = app?.MODULES?.filter(m => !m.marketplace)?.map(
  //   m => m && m?.name,
  // );
  const nativeModules = app?.appModules
    ?.filter(m => !m.marketplace)
    ?.map(m => m && m?.id);
  const contractedModules = getContractedModules(subscription);

  return [...nativeModules, ...contractedModules];
};

export const getContractedModules = subscription => {
  return (
    subscription?.marketplace
      ?.filter(m => {
        return isActiveModule(m, subscription);
      })
      .map(m => m.id) || []
  );
};

const buildModules = subscription => {
  if (!subscription) return [];

  const MKT_MODULES = subscription?.marketplace || [];

  return app.appModules
    .map(m => {
      const mktModule = MKT_MODULES.find(i => i.id === m);
      return mktModule ? { ...m, ...mktModule } : { ...m };
    })
    .filter(m => m);
};

const buildMarketplace = availableModules => {
  availableModules?.map(m => {
    if (m?.routes) {
      const r = clone(m.routes);
      m.routes = r.sort((a, b) => sort(b, a, 'path'));
    }

    if (!m.marketplace) return m;

    // subscription module index
    // const smIdx = availableModules.marketplace.findIndex(sm => sm?.name === m?.name)
    // const module = smIdx >= 0 ? availableModules.marketplace[smIdx] : {}

    // decorate subscription module
    // const marketplace = m.marketplace
    //
    // module.name = m?.name
    //
    // module.icon = marketplace?.icon
    // module.title = marketplace?.title
    // module.subtitle = marketplace?.subtitle
    // module.demoDays = marketplace?.demoDays
    // module.price = marketplace?.priceByCurrency?.brl
    //
    // // stored fields
    // if (!module.status) module.status = 0
    // if (!module.demoActivatedAt) module.demoActivatedAt = null
    // if (!module.addedToCartAt) module.addedToCartAt = null
    // if (!module.activatedAt) module.activatedAt = null
    // if (!module.deactivatedAt) module.deactivatedAt = null
    // if (!module.expirationDate) module.expirationDate = null
    //
    // module.__activeDays = 0
    // module.__progression = 0
    // module.__leftDays = 0
    // module.__leftDemoDays = module.demoDays

    // -------------------------------------------------------------------- //
    // computed fields
    // -------------------------------------------------------------------- //

    // const status = module.status

    if (m?._id) {
      // is persisted in database

      if (!m.activatedAt) {
        // not activated (paid once)

        if (m.demoActivatedAt) {
          // demo available

          const demoDays = m.demoDays;
          const demoActivatedAt = dayjs(m.demoActivatedAt);
          const demoActiveDays = dayjs().diff(demoActivatedAt, 'days');

          const leftDemoDays =
            demoDays - demoActiveDays > 0 ? demoDays - demoActiveDays : 0;

          const progression =
            demoActiveDays > demoDays
              ? 100
              : Math.round((demoActiveDays * 100) / demoDays);

          m.__leftDemoDays = leftDemoDays;
          m.__progression = progression;

          if (leftDemoDays === 0) {
            if (m.status === 2) {
              m.status = -2;
            } else if (m.status === 3) {
              m.status = -3;
            }
          }
        } else {
        }
      }
    }

    /*

                        const status = module.status;
                  const demoDays = module.demoDays;


                  if (module.activatedAtAA) {

                    const activatedAd = moment(module.activatedAt);
                    const activeDays = moment().diff(activatedAd, 'days');

                    // set module properties
                    module.__activeDays = activeDays;

                    // ================================================================== //
                    // DEMO MODE (AVAILABLE OR IN DEMO PERIOD)
                    // ================================================================== //

                    const remainingDays = (demoDays - activeDays) > 0
                        ? (demoDays - activeDays)
                        : 0;

                    if (demoDays) {

                      const progression = activeDays > demoDays
                          ? 100
                          : Math.round((activeDays * 100) / demoDays);

                      // status definition
                      if (remainingDays === 0 && module.status === 1) {
                        module.status = -1;

                      } else if (remainingDays > 0 && module.status === -2) {
                        module.status = 0;
                      }

                      // set module properties
                      module.__leftDemoDays = remainingDays;
                      module.__progression = progression;
                      //
                      module.__leftDays = 0;
                    }

                    // ================================================================== //
                    // CONTRACTED MODE (NO DEMO AVAILABLE OR DEMO PERIOD EXPIRED)
                    // ================================================================== //

                    if (!remainingDays) {

                      const expirationDate = module.expirationDate &&
                          moment(module.expirationDate);

                      const leftDays = expirationDate
                          ? moment(expirationDate).diff(moment(), 'days')
                          : 0;

                      // set module properties
                      module.__leftDemoDays = 0;
                      module.__progression = 0;
                      //
                      module.__leftDays = leftDays;
                    }

                  }*/

    // __status and __statusColor
    switch (m.status) {
      case 0: {
        m.__status = 'not_contracted';
        m.__statusColor = 'gold';
        break;
      }

      case 1: {
        m.__status = 'waiting_for_payment';
        m.__statusColor = 'purple';
        break;
      }

      case 2:
      case 3: {
        m.__status = 'evaluation_period';
        m.__statusColor = 'cyan';
        break;
      }

      case -2:
      case -3: {
        m.__status = 'evaluation_period_expired';
        m.__statusColor = 'volcano';
        break;
      }

      // -----

      case -4: {
        if (m.__leftDays > 0) {
          m.__status = 'active_until';
          m.__statusColor = 'lime';
        } else {
          m.__status = 'not_contracted';
          m.__statusColor = 'gold';
        }

        break;
      }

      default: {
        m.__status = 'not_contracted';
        m.__statusColor = 'volcano';
        break;
      }
    }
    // -------------------------------------------------------------------- //

    // if (smIdx >= 0) {
    //   availableModules.marketplace[smIdx] = m
    // } else {
    //   availableModules.marketplace.push(m)
    // }

    // if (availableModules.marketplace) {
    //   availableModules.marketplace = availableModules.marketplace.sort((a, b) => sort(a, b, 'name'))
    // }

    return m;
  });
};

const buildRoutes = availableModules => {
  let routes = [];

  availableModules?.map(m => {
    m?.routes?.map(r => {
      if (routes.findIndex(i => i?.path === r) === -1) routes.push(r);
      return r;
    });

    return m;
  });

  return routes;
};

export const buildAppConfigurations = subscription => {
  const availableModules = buildModules(subscription);
  const navigateMenu = app?.MENU_NAVIGATION; //buildMenu(availableModules);
  const routes = buildRoutes(availableModules);

  app.routes = routes;

  buildMarketplace(availableModules);

  return {
    availableModules,
    navigateMenu,
    routes,
  };
};

export const setSelectedModule = (currentPath, setDone, navigate) => {
  const { selectedModule, availableModules } = store.getState().moduleSlice;

  let currentModule;
  const appModules = app.appModules;

  // Object.keys(MODULES).map(m => m && appModules.push(MODULES[m]));

  const coreModules = [DEFAULT_HOME_MODULE, ACCOUNTS_MODULE];
  const allModules = appModules
    ? [...coreModules, ...appModules]
    : [...coreModules];

  allModules?.map(m => {
    if (m?.url === currentPath) currentModule = m;
    return m;
  });

  if (!currentModule) {
    allModules?.map(m => {
      if (m?.routes?.find(r => currentPath.includes(r))) {
        currentModule = m;
      }
      return m;
    });
  }

  const authorized =
    !availableModules ||
    !currentModule ||
    coreModules?.findIndex(
      am => am?.id && currentModule?.id && am.id === currentModule.id,
    ) > -1 ||
    availableModules?.findIndex(
      am => am?.id && currentModule?.id && am.id === currentModule.id,
    ) > -1;

  if (!authorized) navigate(app.AUTHENTICATED_ENTRY);

  if (currentModule && currentModule.id !== selectedModule?.id) {
    store.dispatch(rdxSetModule(currentModule));
  } else if (!currentModule) {
    store.dispatch(rdxSetModule(null));
  }

  setDone &&
    setTimeout(() => {
      moduleService.loadingModule(false);
      setDone(true);
    }, 1000);
};

export const printGlobalStateToConsole = () => {
  // if (onProduction()) return

  const globalState = store.getState();
  console.log('');
  console.log('*********************');
  console.log('*** GLOBAL STATE ***');
  console.log('*********************');
  console.log('authSlice:', globalState?.authSlice);
  console.log('');
  console.log('moduleSlice:', globalState?.moduleSlice);
  console.log('');
  console.log('themeSlice:', globalState?.theme);
  console.log('');
  console.log('*********************');
  console.log('***** PROVIDERS *****');
  console.log('*********************');

  store.dispatch(rdxSetShowMyStates(+new Date()));
};

export function applyCustomStyle() {
  const style = document.createElement('style');
  style.type = 'text/css';
  const css = `
  
    .avatar-select.board-card-modal .ant-select-selector:hover {
      border-color: ${app.BASE_THEME.colorPrimary} !important;
    }
    
    .text-primary {
      color: ${
        app.BASE_THEME.colorPrimary
      } !important; /* Alterar a cor para vermelho */
    }
    // .ant-layout {
    //   background-color: ${app.BASE_THEME.colorPrimary}03 !important;
    // }
    .ant-card-bordered:not(.no-change-border-color) {
      border-color: ${app.BASE_THEME.colorPrimary}70 !important;
    }
    ${
      app.currentTheme === 'dark'
        ? `
    body.body-dark-theme {
      background: #283142 !important;
    }
    li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
      background-color: ${app.BASE_THEME.colorPrimary}80 !important;
    }    
    `
        : ``
    }
  `;
  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  document.head.appendChild(style);
}
