import { forwardRef, useContext, useEffect } from 'react';
import { generateHexColor, translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import appService from 'services/appService';
import { Col, ColorPicker, Form, Modal, Row } from 'antd';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { TagOutlined } from '@ant-design/icons';

const modalName = 'UnitsModal';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    setSelectedRecord,
    selectedRecord,
    isUpdating,
    setIsUpdating,

    showModalLevel,
    setShowModalLevel,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = !selectedRecord?._id
    ? translateX('new_unit_of_measurement')
    : translateX('edit_unit_of_measurement');

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    // if (selectedRecord && showUnitsModal()) {
    if (selectedRecord && showModalLevel(modalName)) {
      form?.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord, showModalLevel(modalName), form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowModalLevel(modalName, false);
  };

  const afterClose = () => {
    form.resetFields();
    setSelectedRecord(null);
    setIsUpdating(false);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showModalLevel(modalName)}
        // width={640}
        okText={translateX('save')}
        onCancel={onClose}
        cancelText={translateX('close')}
        // okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => form.submit()}
        getContainer={false}
        okType={'primary'}
        confirmLoading={isUpdating}
        afterClose={afterClose}
      >
        <Row gutter={ROW_GUTTER} justify={'space-between'}>
          {/* LABEL */}
          <Col span={20}>
            <JarvislyInput name="label" disabled={false} />
          </Col>
          {/* LABEL */}

          {/* COLOR PICKER */}
          <Col span={4}>
            <Form.Item
              name="backColor"
              // style={{ width: '0%', marginTop: '25px' }}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '25px',
              }}
              required={true}
            >
              <ColorPicker
                value={form?.getFieldValue('backColor')}
                onChange={backColor => form.setFieldsValue({ backColor })}
                defaultFormat="hex"
                size="small"
              />
            </Form.Item>
          </Col>
          {/* COLOR PICKER */}
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/* DESCRIPTION */}
          <Col span={24}>
            <JarvislyInput name="description" disabled={false} />
          </Col>
          {/* DESCRIPTION */}
        </Row>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const UnitsModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    parameters,
    selectedRecord,
    refreshParameters,
    setIsUpdating,
    setShowModalLevel,
    setIsUpdated,
  } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onConfirm = async ({ body }) => {
    setIsUpdating(true);

    // color
    if (body?.backColor?.toHexString) {
      body.backColor = body.backColor?.toHexString();
    }
    if (!body.backColor) body.backColor = '#b9bcc4';
    body.foreColor = generateHexColor(body.backColor, true);
    body._id = selectedRecord?._id;

    const axiosOptions = {
      url: `/v1/products-parameters/add-to-set/${parameters?.productsParameters?._id}/$push/units`,
      data: body,
    };

    appService.message('s', 'saved_parameters', 'onSave');
    await axiosService.put(axiosOptions);

    setIsUpdated(true);
    setShowModalLevel(modalName, false);
    refreshParameters(true);

    return body;
  };

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        document={selectedRecord}
        requiredFields={['label']}
        showSubmitButton={false}
        onConfirm={onConfirm}
        autoFocus="label"
        abortComponentSave={true}
      >
        <ModalComponent {...props} />
      </JarvislyFormProvider>
    </>
  );
});

export default UnitsModal;
