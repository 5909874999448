import {
  EditOutlined,
} from '@ant-design/icons';
import { BiSolidSchool, BiWorld } from 'react-icons/bi';
import { FaBullhorn } from 'react-icons/fa';
import {
  IoCalendarOutline,
  IoChatboxEllipsesOutline,
  IoExtensionPuzzleOutline,
  IoGameControllerOutline,
  IoSchoolOutline,
} from 'react-icons/io5';
import { PiChalkboardTeacher } from 'react-icons/pi';
import { MdOutlineEmail, MdOutlineManageHistory } from 'react-icons/md';
import { TbApps, TbReportAnalytics } from 'react-icons/tb';
import { GrStorage } from 'react-icons/gr';
import { RiBankLine } from 'react-icons/ri';
import { VscOrganization } from 'react-icons/vsc';
import { SiAwsorganizations } from 'react-icons/si';

const APP_MUNDOSABER = {
  ID: 'appmundosaber',
  NAME: 'Mundo do Saber',
  THEME_COLOR: 'CUSTOM_MUNDOSABER_APP',
  THEME: 'light',
  HIDE_COMPANY_BANKS_TAB: true,
  FINTECH_SERVICES: false,
  DOMAINS: [
    'sistema.mundosaber.com.br',
    'mundosaber.jarvisly.com',
  ],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'multiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: true,
  COPY_RIGHT: 'Mundo do Saber',
  '//API_DEV_HOST': ['localhost', 'vps', 'heroku'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8010,
  API_PORT_PRODUCTION: 8010,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'vps.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  API_HOST_DEV_HEROKU: 'appmundosaber.herokuapp.com',
  WHATSAPP_INTEGRATED: false,
  TOKEN: process.env.REACT_APP_APPMUNDOSABER_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/appmundosaber/logo-app.png',
  LOGO_APP_WHITE: '/apps/appmundosaber/logo-app-white.png',

  LOGO_APP_ICON: '/apps/appmundosaber/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/appmundosaber/logo-app-icon-white.png',

  LOGO: '/apps/appmundosaber/logo.png',
  LOGO_WHITE: '/apps/appmundosaber/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/appmundosaber/logo-app-side.png',

  TITLE: 'Mundo do Saber',
  '//SLOGAN': 'Condomínios Inteligentes',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: true,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: false,
  },
  ENABLE_MARKETPLACE: false,
  MODULES: ['accounts'],

  SHOW_ALL_MENUS: !!process.env?.REACT_APP_APPMUNDOSABER_SHOW_ALL_MENUS,
  MENU_NAVIGATION: [
    // ------------------
    // HOME
    // ------------------
    {
      key: 'home',
      path: `/app/home`,
      title: 'menu',
      icon: FaBullhorn,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: '/app/home/welcome',
          path: `/app/home/welcome`,
          title: 'news',
          className: '',
          icon: FaBullhorn,
          submenu: [],
          subscriptionLevel: [0, 1],
        },
      ],
    },

    // ------------------
    // RECORDS
    // ------------------
    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      className: '',
      icon: EditOutlined,
      isGroupTitle: false,
      subscriptionLevel: [0, 1],
      submenu: [
        // entities
        {
          key: 'records_schools',
          path: '/app/records/schools',
          title: 'schools',
          icon: BiSolidSchool,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'records_teachers',
          path: '/app/records/teachers',
          title: 'teachers',
          icon: PiChalkboardTeacher,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'records_students',
          path: '/app/records/students',
          title: 'students',
          icon: IoSchoolOutline,
          subscriptionLevel: [0, 1],
        },
      ],
    },

    // ------------------
    // APPS
    // ------------------
    {
      key: 'apps',
      path: '/app/apps',
      title: 'applications',
      className: '',
      icon: TbApps,
      isGroupTitle: false,
      subscriptionLevel: [0, 1],
      submenu: [
        // entities
        {
          key: 'tools_messages',
          path: '/app/apps/messages',
          title: 'messages',
          icon: MdOutlineEmail,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'tools_chat',
          path: '/app/apps/chat',
          title: 'chat',
          icon: IoChatboxEllipsesOutline,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'tools_calendar',
          path: '/app/apps/calendar',
          title: 'calendar',
          icon: IoCalendarOutline,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'tools_disk',
          path: '/app/apps/disk',
          title: 'personal_disk',
          icon: GrStorage,
          subscriptionLevel: [0, 1],
        },
      ],
    },

    // ------------------
    // SCHOOL MANAGEMENT
    // ------------------
    {
      key: 'management',
      path: '/app/management',
      title: 'school_management',
      className: '',
      icon: MdOutlineManageHistory,
      isGroupTitle: false,
      submenu: [
        {
          key: 'management_structure',
          path: '/app/management/structure',
          title: 'educational_structure',
          icon: SiAwsorganizations,
        },
        {
          key: 'management_board',
          path: '/app/management/board',
          title: 'people_board',
          icon: VscOrganization,
        },
        {
          key: 'management_organizational',
          path: '/app/management/organizational',
          title: 'organizational_unit',
          icon: RiBankLine,
        },
        {
          key: 'management_evaluation',
          path: '/app/management/evaluation',
          title: 'evaluation_report',
          icon: TbReportAnalytics,
        },
      ],
    },

    // ------------------
    // KNOWLEDGE BASE
    // ------------------
    {
      key: 'knowledge',
      path: '/app/knowledge',
      title: 'knowledge_connection',
      className: '',
      icon: BiWorld,
      isGroupTitle: false,
      submenu: [
        {
          key: 'knowledge_modules',
          path: '/app/knowledge/modules',
          title: 'educational_modules',
          icon: IoExtensionPuzzleOutline,
        },
        {
          key: 'knowledge_games',
          path: '/app/knowledge/games',
          title: 'educational_games',
          icon: IoGameControllerOutline,
        },
      ],
    },
  ],

  FIREBASE_CONFIG: JSON.parse(
    process.env.REACT_APP_APPMUNDOSABER_FIREBASE_CONFIG,
  ),
};

export default APP_MUNDOSABER;
