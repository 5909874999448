import { FloatButton, Tabs } from 'antd';
import { translateWord, translateX } from 'utils/helpers';
import React, { forwardRef, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MultiOrdersVoucher from './VoucherTab/VoucherTabIndex';
import { SyncOutlined, TagOutlined, ToTopOutlined } from '@ant-design/icons';
import axiosService from 'services/axiosService';
import appService from 'services/appService';
import { JarvislyTabProvider } from 'components/jarvisly-components/others/FormComponents';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import SummaryTabIndex from './SummaryTab/SummaryTabIndex';
import MultiOrdersIndex from './OrderTab/OrderTabIndex';

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiOrdersForm = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const {
    _id,
    document,
    selectedModule,
    refreshDocument,
    setModuleFocus,
    mode,
  } = props;

  const statusIdx = getIndexByStatus();

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'voucher', 'summary'];

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);

  // local variables II --------------------------------------------------------
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onClickIssueVoucher = async () => {
    const axiosOptions = {
      _id,
      url: `${selectedModule.api}`,
      data: { status: 'done' },
    };

    try {
      await axiosService.put(axiosOptions);
      appService.message('i', translateWord('document_updated'), 'restore');
      refreshDocument(_id);
    } catch (error) {}
  };

  const onClickResetOrderStatus = async () => {
    const axiosOptions = {
      _id,
      url: `${selectedModule.api}`,
      data: { status: 'waiting_for_payment' },
    };

    try {
      await axiosService.put(axiosOptions);
      appService.message('i', translateWord('document_updated'), 'restore');
      refreshDocument(_id);
    } catch (error) {}
  };

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      {document?.paymentUrl ? (
        <FloatButton.Group
          icon={<ToTopOutlined />}
          shape="circle"
          trigger="click"
          type="primary"
          style={{
            right: 24,
          }}
        >
          {statusIdx === 0 ? (
            <>
              <FloatButton
                icon={<TagOutlined />}
                tooltip={translateX('issue_voucher')}
                onClick={onClickIssueVoucher}
              />
              {/*<FloatButton*/}
              {/*  icon={<SyncOutlined />}*/}
              {/*  tooltip={translateX('reset_status')}*/}
              {/*  onClick={onClickResetOrderStatus}*/}
              {/*/>*/}
            </>
          ) : (
            <FloatButton
              icon={<SyncOutlined />}
              tooltip={translateX('reset_status')}
              onClick={onClickResetOrderStatus}
            />
          )}
        </FloatButton.Group>
      ) : null}

      {/*<JarvislyFormProvider*/}
      {/*  document={document}*/}
      {/*  autoFocus="name"*/}
      {/*  RequiredFields={[]}*/}
      {/*  showSubmitButton={false}*/}
      {/*  setTab={setTab}*/}
      {/*  // onSave={onSave}*/}
      {/*>*/}
      <Tabs
        type="line"
        tabPosition="top"
        activeKey={tab}
        rootClassName="tab-in-form"
        items={tabItems.filter(x => !x.hide)}
        onTabClick={onTabClick}
      />
      {/*</JarvislyFormProvider>*/}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function getIndexByStatus() {
    switch (document?.status) {
      case 'waiting_for_voucher':
        return 1;

      case 'done':
        return 2;

      case 'cancelled':
      case 'cancelled_by_customer':
        return -9;

      case 'waiting_for_payment':
      case 'overdue':
      default:
        return 0;
    }
  }

  function buildTabItens() {
    return [
      {
        label: translateWord('order'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <MultiOrdersIndex {...props} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('vouchers'),
        key: 'voucher',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="contacts">
            <MultiOrdersVoucher {...props} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('values_summary'),
        key: 'summary',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="summary">
            <SummaryTabIndex {...props} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
      // {
      //   label: translateWord('follow_up'),
      //   key: 'followup',
      //   hide: false,
      //   children: <FollowUpIndex {...props} activeTab={activeTab} />,
      // },
      // {
      //   label: translateWord('historic'),
      //   key: 'historic',
      //   hide: false,
      //   children: <div>HISTÓRICO</div>,
      // },
      // {
      //   label: translateWord('customer'),
      //   key: 'customer',
      // hide: false,
      //   children: <div>CUSTOMER</div>,
      // },
    ];
  }
});

export default MultiOrdersForm;
