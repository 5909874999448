import { Table } from 'antd';
import { parseCpfToDisplay, translateWord } from 'utils/helpers';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';

const EventsList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,

    numberLineColumn,
    documentCodeColumn,
    lastUpdatedColumn,
    actionsButtonColumn,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================


  function buildTableColumns() {
    return [
      numberLineColumn,
      documentCodeColumn,

      {
        title: translateWord('name'),
        dataIndex: 'name',
        // ...columnSorter('name'),
        // ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                title={record?.name}
                subtitle={parseCpfToDisplay(record?.cpf)}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },

      lastUpdatedColumn,
      actionsButtonColumn,
    ].filter(x => !x.hidden);
  }
};

export default EventsList;
